/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { SkeletonCard } from '@noths/polaris-client-ribbons-design-system';
import dynamic from 'next/dynamic';
import * as R from 'ramda';

import { ModalLoader } from 'src/components/molecules/ModalLoader/ModalLoader';
import * as styles from 'src/components/organisms/PartnerStorefront/styles/PartnerStorefront.styles';
import type { PartnerStorefrontDispatchProps, PartnerStorefrontStateProps } from '../types';
import { PartnerCard } from './PartnerCard';
import { PartnerProductCard } from './PartnerProductCard';
import { VisitShopButton } from './VisitShopButton';

export const RELATED_PRICE = 'recommended-price';
export const RELATED_PREVIOUS_PRICE = 'recommended-previous-price';

const NUMBER_OF_PRODUCTS_TO_DISPLAY = 4;
const NUMBER_OF_PRODUCTS_PER_ROW = 2;

const ContactPartnerModal = dynamic(
  async () =>
    (
      await import(
        /* webpackChunkName: "ContactPartnerModal" */ '../components/ContactPartnerModal'
      )
    ).ContactPartnerModal,
  { loading: () => <ModalLoader /> },
);

type PartnerStorefrontProps = PartnerStorefrontDispatchProps & PartnerStorefrontStateProps;

export const PartnerStorefront = ({
  closeContactPartnerModal,
  contactFormServerErrors,
  currency,
  isContactPartnerModalOpen,
  messageSentSuccessfully,
  onContactPartnerSubmit,
  onContactPartnerSubmitError,
  onContactPartnerVisible,
  onPartnerLinkClick,
  onPartnerProductCardVisible,
  onRelatedProductClick,
  onVisible,
  openContactPartnerModal,
  partnerHref,
  product,
  recommendedProducts,
}: PartnerStorefrontProps) => {
  const partnerStorefrontRef = useImpressionRef<HTMLDivElement>(() => onVisible());
  const partnerProductCard = useImpressionRef<HTMLDivElement>(() => setProductCardsViewed(true));
  const products = recommendedProducts?.slice(0, NUMBER_OF_PRODUCTS_TO_DISPLAY) ?? [];
  const loading = recommendedProducts !== null && products.length === 0;
  const [productCardsViewed, setProductCardsViewed] = useState(false);

  useEffect(() => {
    if (productCardsViewed && !loading) {
      onPartnerProductCardVisible({ products });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, productCardsViewed]);

  return (
    <>
      <div css={[grid, maxWidthContainer, styles.storefrontGrid]} ref={partnerStorefrontRef}>
        <div css={styles.partner} data-is-multi-row={products.length > NUMBER_OF_PRODUCTS_PER_ROW}>
          <PartnerCard
            onContactSellerClick={openContactPartnerModal}
            onLogoClick={(ev) => onPartnerLinkClick(ev, 'Partner logo')}
            onPartnerNameClick={(ev) => onPartnerLinkClick(ev, 'Partner name')}
            partner={product.partner}
            partnerLinkHref={partnerHref}
          />
          <div css={styles.visitShopButton1}>
            <VisitShopButton
              href={partnerHref}
              onLinkClick={(ev) => onPartnerLinkClick(ev, 'Visit shop CTA')}
            />
          </div>
        </div>
        <div
          css={styles.products}
          data-is-multi-row={loading || products.length > NUMBER_OF_PRODUCTS_PER_ROW}
          data-is-single-product={!loading && products.length === 1}
          ref={partnerProductCard}
        >
          {loading
            ? R.range(0, NUMBER_OF_PRODUCTS_TO_DISPLAY).map((i) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonCard
                  css={styles.skeletonLoaderWrapper}
                  imageStyle={styles.imageWrapper}
                  key={`skeleton-${i}`}
                />
              ))
            : products.map((relatedProduct) => (
                <PartnerProductCard
                  currency={currency}
                  key={product.code}
                  onRelatedProductClick={onRelatedProductClick}
                  product={relatedProduct}
                />
              ))}
        </div>
        <div css={styles.visitShopButton2}>
          <VisitShopButton
            href={partnerHref}
            onLinkClick={(ev) => onPartnerLinkClick(ev, 'Visit shop CTA')}
          />
        </div>
      </div>
      {isContactPartnerModalOpen && (
        <ContactPartnerModal
          isModalOpen={isContactPartnerModalOpen}
          messageSentSuccessfully={messageSentSuccessfully}
          onModalClose={closeContactPartnerModal}
          onSubmit={onContactPartnerSubmit}
          onSubmitError={onContactPartnerSubmitError}
          onVisible={onContactPartnerVisible}
          product={product}
          serverErrors={contactFormServerErrors}
        />
      )}
    </>
  );
};
