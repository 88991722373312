/* istanbul ignore file */

export const APPLY_COPY = 'Apply';
export const REQUIRED_COPY = 'Required';
export const SELECT_COPY = 'Select option';
export const UPPERCASE_NOTICE_COPY = 'All letters will be uppercase on finished item';
export const LOWERCASE_NOTICE_COPY = 'All letters will be lowercase on finished item';

export const CONTENT_LABEL = 'Product personalisation options';
export const AFTER_APPLYING_LABEL = 'After applying item will be';

export const REQUIRED_SELECTION_COPY = "You haven't made a selection";
export const REQUIRED_INPUT_COPY = "You haven't entered any text";
export const MAX_CHARACTERS_COPY = "You're over the character count limit";
export const TOGGLE_BUTTON_GROUP_DESCRIPTION = 'Only one option can be selected';
export const NO_EMOJIS_ALLOWED_COPY = 'Sorry, emojis are not supported';
