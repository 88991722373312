import type { Ref } from 'react';
import React from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';

import { Quantity } from 'src/components/atoms/Quantity/Quantity';
import { PriceBreakdownContainer } from 'src/components/molecules/PriceBreakdown/PriceBreakdownContainer';
import { PersonalisationContainer } from 'src/components/organisms/Personalisation/containers/PersonalisationContainer';

export interface ProductOptionsDispatchProps {
  onChangeQuantity: (payload: { quantity: number; valid: boolean }) => void;
  onVisible: () => void;
}

export interface ProductOptionsStateProps {
  inStock: boolean;
  purchasable: boolean;
  quantity: number;
  showPriceBreakdown: boolean;
  showValidationErrors: boolean;
}

interface ProductOptionsOwnProps {
  ref: Ref<HTMLFormElement>;
}

type ProductOptionsProps = ProductOptionsDispatchProps &
  ProductOptionsStateProps &
  ProductOptionsOwnProps;

export const ProductOptions = React.forwardRef(
  (
    {
      inStock,
      onChangeQuantity,
      onVisible,
      purchasable,
      quantity,
      showPriceBreakdown,
      showValidationErrors,
    }: ProductOptionsProps,
    ref: Ref<HTMLFormElement>,
  ) => {
    const productPersonalisationsRef = useImpressionRef<HTMLDivElement>(() => onVisible());

    return (
      <form noValidate ref={ref}>
        {purchasable && (
          <div ref={productPersonalisationsRef}>
            <PersonalisationContainer displayValidation={showValidationErrors} />
          </div>
        )}
        {inStock && (
          <>
            {showPriceBreakdown && <PriceBreakdownContainer />}
            <Quantity
              defaultValue={quantity}
              onChange={(quantity, valid) => {
                onChangeQuantity({ quantity, valid });
              }}
            />
          </>
        )}
      </form>
    );
  },
);
