import type { RecommendedProduct } from '@noths/polaris-dev-ts-types';
import type { SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PlacementType } from 'src/constants/recommendations';
import { Placement } from 'src/constants/recommendations';
import { logger } from 'src/utils/serverLogger';
import { recommendedProductsRequestComplete } from './actions';
import { requestRecommendedProducts } from './thunks';

const defaultPlacement = { products: [] as RecommendedProduct[] };

type Placements =
  | Placement.ActiveProducts
  | Placement.AddToBasket
  | Placement.OutOfStock
  | Placement.RecentlyViewed
  | Placement.SameSeller
  | Placement.SimilarProducts;

const placementList: Placements[] = [
  Placement.ActiveProducts,
  Placement.AddToBasket,
  Placement.OutOfStock,
  Placement.RecentlyViewed,
  Placement.SameSeller,
  Placement.SimilarProducts,
];

export type RichRelevancePlacements = Pick<PlacementType<RecommendedProduct>, Placements>;

export interface RichRelevanceState extends RichRelevancePlacements {
  errors: SerializedError[] | null;
}

export const initialState: Readonly<RichRelevanceState> = {
  errors: null,
  [Placement.ActiveProducts]: defaultPlacement,
  [Placement.AddToBasket]: defaultPlacement,
  [Placement.OutOfStock]: defaultPlacement,
  [Placement.RecentlyViewed]: defaultPlacement,
  [Placement.SameSeller]: defaultPlacement,
  [Placement.SimilarProducts]: defaultPlacement,
};

export const { actions, reducer } = createSlice({
  name: 'richRelevance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestRecommendedProducts.fulfilled, (state, { payload }) => {
        if (payload && payload.placementType) {
          state[payload.placementType] = {
            placementTitle: payload.placementTitle,
            placementStrategy: payload.placementStrategy,
            products: payload.products.length > 0 ? payload.products : null,
          };
        }
      })
      .addCase(requestRecommendedProducts.rejected, (state, action) => {
        if (action.payload?.error) {
          logger.error('Error requesting similar products', action.payload.error);

          state.errors = state.errors
            ? state.errors.concat([action.payload.error])
            : [action.payload.error];
        }

        if (action.payload?.placementType) {
          state[action.payload.placementType] = {
            products: null,
          };
        }
      })
      .addCase(recommendedProductsRequestComplete, (state) => {
        placementList.forEach((placement) => {
          if (state[placement]?.products?.length === 0) {
            state[placement].products = null;
          }
        });
      });
  },
});
