import React from 'react';
import { HyperButton, IconHelp, Tooltip } from '@noths/polaris-client-ribbons-design-system';

import { MADE_TO_ORDER_COPY, MADE_TO_ORDER_INFO, MADE_TO_ORDER_LABEL } from './copy';
import * as styles from './MadeToOrder.styles';

export const MadeToOrder = () => (
  <div css={styles.madeToOrder}>
    <p css={styles.madeToOrderLabel}>{MADE_TO_ORDER_LABEL}</p>
    <Tooltip alignment="right" content={MADE_TO_ORDER_COPY}>
      {({ onClick }) => (
        <HyperButton onClick={onClick}>
          <span css={styles.tooltipInfo}>
            {MADE_TO_ORDER_INFO}
            <IconHelp css={styles.tooltipInfoIcon} height={16} width={16} />
          </span>
        </HyperButton>
      )}
    </Tooltip>
  </div>
);
