import type { StrictExtract } from '@noths/polaris-dev-ts-recipes';

import type { ProgressMilestones, TrackedEvents } from 'src/redux/personalisation';
import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

const MILESTONE_50: StrictExtract<ProgressMilestones, 'Progress - 50%'> = 'Progress - 50%';
const MILESTONE_100: StrictExtract<ProgressMilestones, 'Progress - 100%'> = 'Progress - 100%';
interface GetMilestonesFnParam extends Pick<GetProgressMilestoneFnParam, 'trackedEvents'> {
  progress: number;
}

const getMilestones = ({ progress, trackedEvents }: GetMilestonesFnParam) => {
  const is50Milestone = progress >= 50 && progress <= 99 && !trackedEvents.progress[MILESTONE_50];

  const is100Milestone = progress === 100 && !trackedEvents.progress[MILESTONE_100];

  return { is50Milestone, is100Milestone };
};

interface GetProgressMilestoneFnParam {
  fieldName: string;
  isRequiredPersonalisation: boolean;
  totalOutstandingSelections: number;
  totalRequiredPersonalisations: number;
  trackedEvents: TrackedEvents;
}

export const getProgressMilestone = ({
  fieldName,
  isRequiredPersonalisation,
  totalOutstandingSelections,
  totalRequiredPersonalisations,
  trackedEvents,
}: GetProgressMilestoneFnParam) => {
  if (!isRequiredPersonalisation) {
    return null;
  }

  const outstandingPersonalisationsTotal =
    // `- 1` modifier accommodates new selections, which Store state's outstandingTotal doesn't reflect.
    trackedEvents.progress[fieldName] == null
      ? totalOutstandingSelections - 1
      : totalOutstandingSelections;

  const progress = Math.floor(
    (100 / totalRequiredPersonalisations) *
      (totalRequiredPersonalisations - outstandingPersonalisationsTotal),
  );

  const { is50Milestone, is100Milestone } = getMilestones({ trackedEvents, progress });

  if (is50Milestone) {
    return MILESTONE_50;
  } else if (is100Milestone) {
    return MILESTONE_100;
  }

  return null;
};

export const getFieldPosition = (
  options: CatServiceAPIProductOption[],
  option: Pick<CatServiceAPIProductOption, 'id'>,
) => options.findIndex((opt) => opt.id === option.id) + 1;
