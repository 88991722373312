import { selectUserCurrencyCode } from '@noths/polaris-client-user-configuration';
import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { DeliveryOptionInfo } from 'src/types/deliveryZoneOptions';
import { getMinMaxDeliveryEstimate } from './utils';

export const selectSelectedZone = ({ deliveryZoneOptions }: ReduxApplicationState) =>
  deliveryZoneOptions.selectedZone;

export const selectSelectedCountry = ({ deliveryZoneOptions }: ReduxApplicationState) =>
  deliveryZoneOptions.selectedCountry;

export const selectDeliveryZoneOptions = ({ deliveryZoneOptions }: ReduxApplicationState) =>
  deliveryZoneOptions.options;

export const selectDeliveryZoneOptionsIsLoading = ({
  deliveryZoneOptions,
}: ReduxApplicationState) => deliveryZoneOptions.isLoading;

export const selectDeliveryOptionsForZone = createSelector(
  [selectSelectedZone, selectDeliveryZoneOptions],
  (selectedZone, options) =>
    options.find(({ zoneCode }) => zoneCode === selectedZone)?.deliveryOptions,
);

export const selectDecoratedDeliveryOptions = createSelector(
  [selectDeliveryOptionsForZone, selectUserCurrencyCode],
  (deliveryOptionsForZone, userCurrency = 'GBP'): DeliveryOptionInfo[] =>
    deliveryOptionsForZone?.map(
      ({ date, deliveryProvider, exactDate, max, min, optionCode, prices }) => ({
        date,
        exactDate,
        max,
        min,
        optionCode,
        price: prices.find(({ currencyCode }) => currencyCode === userCurrency)!,
        providerName: deliveryProvider?.name,
      }),
    ) ?? [],
);

export const selectMinMaxDeliveryEstimates = createSelector(
  [selectDecoratedDeliveryOptions],
  (countryDeliveryOptions) => getMinMaxDeliveryEstimate(countryDeliveryOptions),
);
