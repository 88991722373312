import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions as trackingActions } from 'src/redux/reviews';
import type {
  ProductReviewsExpandReadMore,
  ProductReviewsNextPage,
  ProductReviewsPagination,
  ProductReviewsProductRatingSystem,
} from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [trackingActions.onProductReviewsExpandReadMore.type]: (_, __, action) => {
    const { payload: isOpen } = action as ReturnType<
      typeof trackingActions.onProductReviewsExpandReadMore
    >;

    if (isOpen) {
      sendGAEvent<ProductReviewsExpandReadMore>({
        event_action: 'Expand review click',
        event_category: 'Product reviews',
        event_label: 'not set',
        event: 'custom_event',
      });
    }
  },
  [trackingActions.onProductReviewsNextPage.type]: (state) => {
    const pageNumber = state.reviews.pagination!.currentPage + 1;

    if (!state.reviews.trackedPageNumberPaginations[pageNumber]) {
      sendGAEvent<ProductReviewsNextPage>({
        event_action: 'Next page click',
        event_category: 'Product reviews',
        event_label: pageNumber,
        event: 'custom_event',
      });
    }
  },
  [trackingActions.onProductReviewsPagination.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof trackingActions.onProductReviewsPagination>;

    sendGAEvent<ProductReviewsPagination>({
      event_action: 'Pagination click',
      event_category: 'Product reviews',
      event_label: payload,
      event: 'custom_event',
    });
  },
  [trackingActions.onProductReviewsProductRatingSystem.type]: () => {
    sendGAEvent<ProductReviewsProductRatingSystem>({
      event_action: 'Product rating system click',
      event_category: 'Product reviews',
      event_label: 'not set',
      event: 'custom_event',
    });
  },
};
