/* istanbul ignore file */

import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

const ACTIVE_THUMBNAIL_BORDER_PX = 1;
const ACTIVE_THUMBNAIL_SPACING_PX = spacing.base;

const activeThumbnailBorderAndSpacingPx = ACTIVE_THUMBNAIL_SPACING_PX + ACTIVE_THUMBNAIL_BORDER_PX;
const activeThumbnailBorderAndSpacingRem = pxToRem(activeThumbnailBorderAndSpacingPx);

const thumbnailsHorizontal = css`
  background: var(--neutral-white-base);
  margin: -${activeThumbnailBorderAndSpacingRem} 0 -${activeThumbnailBorderAndSpacingRem} -${activeThumbnailBorderAndSpacingRem};
  overflow-x: auto;
  overflow-y: hidden;
`;

const thumbnailsVertical = css`
  background: var(--neutral-white-base);
  height: calc(100% + ${activeThumbnailBorderAndSpacingRem});
  margin: -${activeThumbnailBorderAndSpacingRem} -${activeThumbnailBorderAndSpacingRem} 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

export const thumbnailsWithKeyboardFocus = css`
  border-radius: ${pxToRem(14)};
  box-shadow: 0 0 0 ${pxToRem(3)} var(--neutral-white-base),
    0 0 0 ${pxToRem(5)} var(--brand-purple-base);
`;

const thumbnailList = css`
  display: flex;
  list-style-type: none;
  margin: 0;
`;

const thumbnailListHorizontal = css`
  ${thumbnailList}

  padding: ${`${activeThumbnailBorderAndSpacingRem} ${pxToRem(
    activeThumbnailBorderAndSpacingPx * 2,
  )} ${activeThumbnailBorderAndSpacingRem} ${activeThumbnailBorderAndSpacingRem}`};
`;

const thumbnailListVertical = css`
  ${thumbnailList}

  flex-direction: column;
  left: 0;
  height: 100%;
  padding: ${`${activeThumbnailBorderAndSpacingRem} ${activeThumbnailBorderAndSpacingRem} ${pxToRem(
    activeThumbnailBorderAndSpacingPx * 2,
  )}`};
  position: absolute;
  top: 0;
`;

const thumbnailListItemHorizontal = css`
  flex-shrink: 0;
  flex-basis: ${pxToRem(90)};
  margin: 0 ${pxToRem(spacing.sm)} 0 0;

  &:last-child {
    padding-right: ${activeThumbnailBorderAndSpacingRem};
  }
`;

const thumbnailListItemVertical = css`
  margin: 0 0 ${pxToRem(spacing.sm)};

  &:last-child {
    padding-bottom: ${activeThumbnailBorderAndSpacingRem};
  }
`;

const thumbnailImageHorizontal = css`
  border-radius: ${pxToRem(10)};
`;

const thumbnailImageVertical = css`
  border-radius: ${pxToRem(10)};
  width: 100%;
`;

export const thumbnailImageLink = css`
  display: block;
  opacity: 0.4;
  position: relative;
  line-height: 0;
  height: 0;
  padding-bottom: 100%;

  .js-focus-visible &.focus-visible {
    box-shadow: 0 0 0 ${pxToRem(ACTIVE_THUMBNAIL_SPACING_PX)} var(--neutral-white-base),
      0 0 0 ${activeThumbnailBorderAndSpacingRem} var(--brand-purple-base);
  }

  &[aria-selected='true'] {
    opacity: 1;
    box-shadow: 0 0 0 ${pxToRem(ACTIVE_THUMBNAIL_SPACING_PX)} var(--neutral-white-base),
      0 0 0 ${activeThumbnailBorderAndSpacingRem} var(--brand-purple-base);
    border-radius: ${pxToRem(10)};
  }
`;

export const activeThumbnail = css`
  box-shadow: 0 0 0 ${pxToRem(ACTIVE_THUMBNAIL_SPACING_PX)} var(--neutral-white-base),
    0 0 0 ${activeThumbnailBorderAndSpacingRem} var(--brand-purple-base);
  border-radius: ${pxToRem(10)};
`;

export const horizontal = {
  thumbnails: thumbnailsHorizontal,
  thumbnailList: thumbnailListHorizontal,
  thumbnailListItem: thumbnailListItemHorizontal,
  thumbnailImage: thumbnailImageHorizontal,
};

export const vertical = {
  thumbnails: thumbnailsVertical,
  thumbnailList: thumbnailListVertical,
  thumbnailListItem: thumbnailListItemVertical,
  thumbnailImage: thumbnailImageVertical,
};
