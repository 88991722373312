import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import { StatusCode } from '@noths/polaris-dev-ts-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import getConfig from 'next/config';

import type { UserMarketingPreferenceResult } from 'src/components/organisms/PartnerStorefront/hooks';
import type {
  EnquiriesAPIPostData,
  PartnerStorefrontState,
} from 'src/components/organisms/PartnerStorefront/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { NextConfig } from 'src/types/nextConfig';

const { publicRuntimeConfig } = getConfig() as NextConfig;

export interface ContactPartnerSubmitPayload {
  email: string;
  fullName: string;
  marketingOptIn?: boolean;
  message: string;
  userAttributes: UserMarketingPreferenceResult['userAttributes'] | null;
}

export type MarketingPreferencesSubmitPayload = Pick<ContactPartnerSubmitPayload, 'email'>;

export const contactPartnerSubmit = createAsyncThunk<
  null,
  ContactPartnerSubmitPayload,
  { rejectValue: PartnerStorefrontState['error'] }
>(
  'partnerStorefront/contactPartnerSubmit',
  async (
    { email, fullName, marketingOptIn, message, userAttributes },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { product } = getState() as ReduxApplicationState;
    const enquiriesUrl = `${publicRuntimeConfig.endpoints.enquiries}${product.code}/web_enquiries`;

    const postData: EnquiriesAPIPostData = {
      name: fullName,
      email,
      message,
    };

    if (userAttributes !== null) {
      const { email: emailFromAccount, family_name, name } = userAttributes;

      if (emailFromAccount) {
        postData.email = emailFromAccount!;
      }

      if (name && family_name) {
        postData.name = `${name} ${family_name}`;
      }
    }

    const response = await fetchJSONWithTimeout(enquiriesUrl, {
      method: 'POST',
      body: postData as unknown as Record<string, unknown>,
      timeout: 2000,
    });

    if ([StatusCode.UnprocessableEntity, StatusCode.TooManyRequests].includes(response.status)) {
      const { errors } = await response.json();

      return rejectWithValue(errors);
    }

    if (marketingOptIn) {
      dispatch(submitMarketingPreferences({ email: postData.email }));
    }

    return null;
  },
);

export const submitMarketingPreferences = createAsyncThunk<null, MarketingPreferencesSubmitPayload>(
  'partnerStorefront/submitMarketingPreferences',
  async ({ email }) => {
    const marketingPreferencesAnonymousUrl = `${publicRuntimeConfig.hosts.communicationPreferences}/anonymous`;

    return await fetchJSONWithTimeout(marketingPreferencesAnonymousUrl, {
      method: 'POST',
      body: {
        address: email,
        channel: 'email',
        status: 'ENABLED',
        source: 'ASK_SELLER',
      },
      timeout: 3000,
    }).then((response) => response.json());
  },
);
