import type { NavigateCarouselPayload } from '@noths/polaris-client-ribbons-design-system';
import { createAction } from '@reduxjs/toolkit';

import type { Placement } from 'src/constants/recommendations';

interface RecommendedProductsShelfNavigateCarouselPayload extends NavigateCarouselPayload {
  placementType: Placement;
}

interface CarouselVisibleTrackingData {
  placementType: Placement;
}

export const recommendedProductsShelfVisible = createAction<CarouselVisibleTrackingData>(
  'recommendedProductsShelf/onVisible',
);

export interface CarouselProductClickTrackingData {
  placementType: Placement;
  productIndex: number;
  shouldUseEventCallback: boolean;
}

export const recommendedProductsShelfProductClick = createAction<CarouselProductClickTrackingData>(
  'recommendedProductsShelf/productClick',
);

export const recommendedProductsShelfNavigateCarousel =
  createAction<RecommendedProductsShelfNavigateCarouselPayload>(
    'recommendedProductsShelf/navigateCarousel',
  );
