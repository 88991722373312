import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';
import { selectIsFavourite, updateFavourite } from '@noths/polaris-client-user-favourites';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { generateProductFieldObject } from 'src/utils/googleAnalytics';
import type { FavouriteEventLabel, FavouriteProductEvent } from './events';

const getEventLabel = (
  isOpeningModal: boolean,
  isExistingFavourite: boolean,
): FavouriteEventLabel => {
  if (isOpeningModal) {
    return 'folder options opened';
  }

  if (isExistingFavourite) {
    return 'removed';
  }

  return 'added';
};

type Action = ReturnType<(typeof updateFavourite)['fulfilled']>;

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [updateFavourite.fulfilled.type]: (state, _, action) => {
    const { payload: productCode } = action as Action;
    const { isModalOpen, summary } = state.favourites;
    const isFavourite = selectIsFavourite(summary, productCode);
    const eventLabel = getEventLabel(isModalOpen, isFavourite);

    sendGAEvent<FavouriteProductEvent>({
      event: 'custom_event',
      event_category: 'Favourites',
      event_action: 'heart icon clicked',
      event_label: eventLabel,
      favourites: generateProductFieldObject(state),
    });
  },
};
