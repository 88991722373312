import { maxBreakpoints, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { MODAL_CONTENT_CLASS_NAMES } from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { createGlobalStyle, css } from 'styled-components';

import { BASKET_MODAL_CONTENT_LABEL } from '../copy';

const { fonts, spacing, text } = theme;

export const BasketModalGlobalStyles = createGlobalStyle`
  .${MODAL_CONTENT_CLASS_NAMES.base} {
    ${mediaQuery(
      { min: minBreakpoints.L },
      css`
        &[aria-label='${BASKET_MODAL_CONTENT_LABEL}'] {
          grid-column: 3 / -3;
        }
      `,
    )}
  }
`;

export const section = css`
  display: flex;
  align-items: center;
  margin: ${pxToRem(spacing.xl)};
  font-size: ${pxToRem(text.BODY.XS.fontSize)};

  p,
  a,
  span {
    font-size: ${pxToRem(text.BODY.XS.fontSize)};
    line-height: 1.67;
  }
`;

export const headerSection = css`
  margin: 0;
`;

export const productSection = css`
  border-radius: ${pxToRem(20)};
  border: solid ${pxToRem(1)} var(--neutral-black-30);
  padding: ${pxToRem(spacing.xl)};
  gap: ${pxToRem(80)};
  flex-wrap: wrap;
`;

export const buttonSection = css`
  flex-wrap: wrap;
`;

export const header = css`
  margin: 0 ${pxToRem(spacing.xs)};
  font-size: ${pxToRem(text.BODY.MD.fontSize)};
`;

export const buttonContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      flex-basis: ${pxToRem(500)};
      flex-direction: row;
    `,
  )}
`;

export const carouselContainer = css`
  display: grid;
  gap: ${pxToRem(32)} ${pxToRem(12)};
  grid-template-columns: repeat(12, 1fr);
  margin: 0 ${pxToRem(spacing.xl)};
`;

export const carouselSection = css`
  grid-column: 1 / span 12;
  border-top: ${pxToRem(1)} solid var(--neutral-black-30);
  margin-bottom: ${pxToRem(spacing.xl)};

  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      margin-right: 0;
    `,
  )}
`;

export const goToBasketButton = css`
  margin-left: ${pxToRem(spacing.xxl)};

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      margin-top: ${pxToRem(spacing.sm)};
      margin-left: 0;
    `,
  )}
`;

export const info = css`
  flex-grow: 1;
  font-size: ${pxToRem(text.BODY.XS.fontSize)};
  display: flex;

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      justify-content: center;
    `,
  )}
`;

export const infoItems = css`
  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      margin-top: 0;
    `,
  )}
`;

export const infoTotal = css`
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  margin-right: ${pxToRem(spacing.sm)};

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      margin-top: 0;
    `,
  )}
`;

export const breakdownWrapper = css`
  flex: 1;
  align-self: flex-start;
  font-size: ${pxToRem(text.BODY.XS.fontSize)};
  flex-basis: ${pxToRem(200)};
`;

export const partnerInfo = css`
  display: flex;
  flex: 1;
  flex-basis: 50%;
  align-self: flex-start;
`;

export const partnerImage = css`
  margin-right: ${pxToRem(spacing.md)};
  border-radius: ${pxToRem(10)};
`;

export const productQuantity = css`
  margin: ${pxToRem(spacing.base)} 0;
`;

export const productDescription = css`
  margin: 0;
`;

export const footerItems = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
