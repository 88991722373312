import { connect } from 'react-redux';

import { OverallRating } from 'src/components/organisms/OverallRating/components/OverallRating';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

const mapStateToProps = ({ product }: ReduxApplicationState) => ({
  productRating: product.productRating,
  numberOfReviews: product.numberOfReviews,
});

export const OverallRatingContainer = connect(mapStateToProps)(OverallRating);
