import { connect } from 'react-redux';

import type {
  ContainerProps,
  DispatchProps,
  OwnProps,
} from 'src/components/organisms/Personalisation/components/MultipleOptionSelection/MultipleOptionSelection';
import { MultipleOptionSelection } from 'src/components/organisms/Personalisation/components/MultipleOptionSelection/MultipleOptionSelection';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  actions as personalisationActions,
  selectOutstandingPersonalisations,
  selectSelectedOption,
} from 'src/redux/personalisation';

const mapStateToProps = (state: ReduxApplicationState, props: OwnProps): ContainerProps => ({
  selectedOption: selectSelectedOption(state, props.id),
  currency: state.product.price.currency,
  error:
    Boolean(props.displayValidation) &&
    selectOutstandingPersonalisations(state).some((opt) => opt.id === props.id),
});

const mapDispatchToProps: DispatchProps = {
  selectPersonalisation: personalisationActions.selectPersonalisation,
  deselectPersonalisation: personalisationActions.deselectPersonalisation,
};

export const MultipleOptionSelectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleOptionSelection);
