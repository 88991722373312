import { selectLoop54UserId } from '@noths/polaris-client-user-configuration';
import { getProductIdFromProductCode } from '@noths/polaris-client-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { CreateEventResponse } from 'loop54-js-connector';
import getClient from 'loop54-js-connector';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProduct } from 'src/redux/selectors';
import { logger } from 'src/utils/serverLogger';
import { selectLoop54EndpointFromReferrer } from './selectors';

const handleResponse = (response: CreateEventResponse) => {
  /* eslint-disable no-unsafe-optional-chaining */
  const { error } = response?.data;

  if (error) {
    logger.error({
      location: 'trackLoop54Event',
      extra: { description: error.title, error: error },
    });
  }
};

interface ThunkArgs {
  eventType: 'click' | 'addtocart';
}

export const trackLoop54Event = createAsyncThunk<void, ThunkArgs>(
  'loop54/trackLoop54Event',
  async ({ eventType }, { getState }) => {
    const state = getState() as ReduxApplicationState;
    const endpoint = selectLoop54EndpointFromReferrer(state);

    if (endpoint) {
      const userId = selectLoop54UserId(state) ?? 'unknown';
      const { code, quantity } = selectProduct(state);
      const entity = { type: 'Product', id: String(getProductIdFromProductCode(code)) };
      const itemQuantity = eventType === 'addtocart' ? quantity : null;
      const client = getClient(endpoint, userId);

      const response = await client.createEvent(eventType, entity, null, itemQuantity);

      handleResponse(response);
    }
  },
);
