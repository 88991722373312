import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { IconChevronDown, IconChevronUp } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './ExpandablePanel.styles';

export const EXPANDABLE_PANEL_TEST_ID = 'expandable-panel';

interface ExpandablePanelProps {
  children: ReactNode;
  collapseButtonText: string;
  expandButtonText: string;
  gradientColor?: string;
  onToggle?: (expanded: boolean) => void;
  open?: boolean;
}

export const ExpandablePanel = ({
  children,
  collapseButtonText,
  expandButtonText,
  gradientColor = '255,255,255',
  onToggle,
  open = false,
}: ExpandablePanelProps) => {
  const [expanded, setExpanded] = useState(open);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => {
      const nextExpanded = !prevExpanded;
      onToggle?.(nextExpanded);
      return nextExpanded;
    });
  };

  const scrollContentToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleExpanded();
      scrollContentToTop();
    }
  };

  const handleContentInteraction = () => {
    if (!expanded) {
      setExpanded(true);
      onToggle?.(true);
      scrollContentToTop();
    }
  };

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  const contentStyle: styles.ContentCSSProperties = {
    '--height': expanded ? 'auto' : '200px',
    '--overflow': expanded ? 'none' : 'hidden',
  };

  const buttonStyle: styles.ButtonCSSProperties = {
    '--gradient': expanded ? 'transparent' : gradientColor,
  };

  return (
    <>
      {/* eslint-disable-next-line
      jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        aria-expanded={expanded}
        css={styles.content}
        data-testid={EXPANDABLE_PANEL_TEST_ID}
        onClick={handleContentInteraction}
        onFocus={handleContentInteraction}
        ref={contentRef}
        style={contentStyle}
      >
        {children}
      </div>
      <button
        aria-expanded={expanded}
        aria-label={expanded ? collapseButtonText : expandButtonText}
        css={styles.button}
        onClick={toggleExpanded}
        onKeyDown={handleKeyDown}
        style={buttonStyle}
        tabIndex={0}
        type="button"
      >
        {expanded ? (
          <span>
            {collapseButtonText}
            <IconChevronUp color="currentColor" height={16} width={16} />
          </span>
        ) : (
          <span>
            {expandButtonText}
            <IconChevronDown color="currentColor" height={16} width={16} />
          </span>
        )}
      </button>
    </>
  );
};
