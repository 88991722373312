import React from 'react';

import type {
  NextOrPreviousPayload,
  OpenModalPayload,
  ProductImagesState,
  SetCurrentImagePayload,
} from 'src/components/organisms/ProductImagesCarousel/types';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { MultipleProductImages } from './MultipleProductImages';
import { ProductImageModal } from './ProductImageModal';
import { SingleProductImage } from './SingleProductImage';

export interface ProductImagesCarouselProps {
  currentImage: ProductImagesState['currentImage'];
  isModalOpen: ProductImagesState['isModalOpen'];
  navigatedBy: ProductImagesState['navigatedBy'];
  onCloseModal: VoidFunction;
  onNextImage: (payload: NextOrPreviousPayload) => void;
  onOpenModal: (payload: OpenModalPayload) => void;
  onPrevImage: (payload: NextOrPreviousPayload) => void;
  onUpdateFavourite: () => void;
  product: CatServiceAPIProduct;
  productIsFavourited: boolean;
  setCurrentImage: (payload: SetCurrentImagePayload) => void;
}

export const ProductImagesCarousel = ({
  currentImage,
  isModalOpen,
  navigatedBy,
  onCloseModal,
  onNextImage,
  onOpenModal,
  onPrevImage,
  onUpdateFavourite,
  product,
  productIsFavourited,
  setCurrentImage,
}: ProductImagesCarouselProps) => (
  <>
    <ProductImageModal
      closeModal={onCloseModal}
      currentImage={currentImage}
      images={product.images}
      isModalOpen={isModalOpen}
      onNextImage={onNextImage}
      onPrevImage={onPrevImage}
      product={product}
      setCurrentImage={setCurrentImage}
    />
    {product.images.length > 1 ? (
      <MultipleProductImages
        currentImage={currentImage}
        location="Main image"
        navigatedBy={navigatedBy}
        onNextImage={onNextImage}
        onOpenModal={onOpenModal}
        onPrevImage={onPrevImage}
        onUpdateFavourite={onUpdateFavourite}
        product={product}
        productIsFavourited={productIsFavourited}
        setCurrentImage={setCurrentImage}
      />
    ) : (
      <SingleProductImage
        onOpenModal={onOpenModal}
        onUpdateFavourite={onUpdateFavourite}
        product={product}
        productIsFavourited={productIsFavourited}
      />
    )}
  </>
);
