import { BASKET, BASKET_WITH_OPTIONS } from './graphql/queries';
import type {
  BasketAPIBasketQuery,
  BasketAPIBasketWithItemsQuery,
} from './types/BasketAPIResponseBody';
import { graphqlRequest } from './utils/graphqlRequest';

export interface BasketAPIItemsQueryResponseBody {
  data: {
    basket: BasketAPIBasketQuery;
  };
}

export interface BasketAPIItemsWithOptionsQueryResponseBody {
  data: {
    basket: BasketAPIBasketWithItemsQuery;
  };
}

export const getBasket = async (): Promise<BasketAPIBasketQuery> => {
  const response = await graphqlRequest<BasketAPIItemsQueryResponseBody>(BASKET);

  return response.data.basket;
};

export const getBasketWithItemOptions = async (): Promise<BasketAPIBasketWithItemsQuery> => {
  const response = await graphqlRequest<BasketAPIItemsWithOptionsQueryResponseBody>(
    BASKET_WITH_OPTIONS,
  );

  return response.data.basket;
};
