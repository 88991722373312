import type { Navigation } from '@noths/polaris-dev-ts-types';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { buildCatServiceAPINavigation } from 'src/services/cat-service-api/builders/buildCatServiceAPINavigation';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { logger } from 'src/utils/serverLogger';
import { requestProductDetails } from './thunks';

export interface ProductState extends CatServiceAPIProduct {
  error: SerializedError | null;
  isQuantityValid: boolean;
  metaDescription: string;
  metaRobotsIndexable: boolean;
  navigation: Navigation[];
  quantity: number;
  redirectUrl?: string;
}

export const initialState: Readonly<ProductState> = {
  actualCommissionRate: 0,
  allowsGiftMessage: false,
  allowsGiftReceipt: false,
  allowsGiftWrap: false,
  availability: 'available',
  available: true,
  backInStockAt: null,
  code: 0,
  deliveryOptions: [],
  deliveryByEvent: null,
  description: '',
  dimensions: '',
  freeDomesticDelivery: false,
  freeGiftWrap: false,
  images: [],
  inventoryStatus: 'in_stock',
  isNew: false,
  links: [],
  lowCommitmentProductFlag: null,
  lowCommitmentProductType: null,
  materials: '',
  breadcrumbCategoryId: null,
  numberOfReviews: 0,
  onSale: false,
  options: [],
  ingredients: null,
  otherInformation: '',
  partner: {
    id: 0,
    links: [],
    metaTagDescription: null,
    name: '',
    logo: '',
    shortcode: '',
    location: '',
  },
  preSalePrices: [],
  price: { amount: 0, currency: 'GBP' },
  prices: [{ amount: 0, currency: 'GBP' }],
  productRating: null,
  purchasable: true,
  returnsInformation: '',
  salePercentage: 0,
  specialFeatures: {
    ecoFriendly: false,
    exclusive: false,
    madeInBritain: false,
    personalisable: false,
    requiresPhotoUpload: false,
  },
  title: '',
  variationsAvailable: '',
  shippingInfo: null,
  error: null,
  metaDescription: '',
  metaRobotsIndexable: true,
  navigation: [buildCatServiceAPINavigation()],
  quantity: 1,
  isQuantityValid: true,
};

export const { actions, reducer } = createSlice({
  name: 'product',
  initialState,
  reducers: {
    changeQuantity: (state, action: PayloadAction<{ isValid: boolean; quantity: number }>) => {
      state.quantity = action.payload.quantity;
      state.isQuantityValid = action.payload.isValid;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestProductDetails.fulfilled, (state, { payload }) => {
        if (payload.redirectUrl) {
          state.redirectUrl = payload.redirectUrl;
          return;
        }

        if (payload.data) {
          const { metaDescription, metaRobotsIndexable, navigation, product } = payload.data;

          return {
            ...initialState,
            ...product,
            navigation,
            metaDescription,
            metaRobotsIndexable,
          };
        }

        return initialState;
      })
      .addCase(requestProductDetails.rejected, (state, action) => {
        logger.error('Error requesting product data', action.error);

        state.error = action.error;
      });
  },
});
