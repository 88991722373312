import {
  LOWERCASE_NOTICE_COPY,
  UPPERCASE_NOTICE_COPY,
} from 'src/components/organisms/Personalisation/constants/copy';
import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

export const getTextCaseNotice = (allowedCase: CatServiceAPIProductOption['allowedCase']) => {
  const cases: Record<NonNullable<CatServiceAPIProductOption['allowedCase']>, string> = {
    upper: UPPERCASE_NOTICE_COPY,
    lower: LOWERCASE_NOTICE_COPY,
    mixed: '',
    'not applicable': '',
  };

  return allowedCase ? cases[allowedCase] : '';
};
