import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, responsiveText, spacing, text } = theme;

export const root = css`
  grid-column: span 12;
  border-bottom: 1px solid var(--neutral-black-30);
  border-top: 1px solid var(--neutral-black-30);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const title = css`
  margin: 0;
  padding: ${spacing.xxl}px 0;
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveText.HEADING.XS};
`;

export const section = (twoColumnLayout: boolean) => css`
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span ${twoColumnLayout ? 6 : 12};
    `,
  )};
`;

export const heading = css`
  ${fontSize(text.BODY.MD)};
  margin-bottom: ${spacing.lg}px;
`;
