import React from 'react';
import {
  ExpandableCardPersonalisation,
  IconPhoto,
} from '@noths/polaris-client-ribbons-design-system';

import * as styles from './PhotoPersonalisationMessage.styles';

export const PhotoPersonalisationMessage = () => {
  return (
    <div css={styles.root}>
      <ExpandableCardPersonalisation TitleIcon={IconPhoto} title="Photo personalisation">
        <p css={styles.content}>
          Please upload your photos on the same day of purchase. Delays may affect the estimated
          delivery date.
        </p>
        <p css={styles.content}>
          <a
            css={styles.accentLink}
            href="https://info.notonthehighstreet.com/hc/en-us/articles/360002304834-How-do-I-upload-photos-for-my-order"
            rel="noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </p>
      </ExpandableCardPersonalisation>
    </div>
  );
};
