import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const divider = css`
  margin: ${pxToRem(spacing.xxl)} 0 ${pxToRem(spacing.md)};
  border: none;
  border-bottom: ${pxToRem(1)} solid var(--neutral-black-30);
`;

export const reviewsList = css`
  list-style-type: none;
  padding: 0;
  margin: ${stack(spacing.xxxl)};
`;

export const paginationList = css`
  display: flex;
  list-style: none;
  padding: 0;
`;

export const paginationListItem = css`
  ${fontSize(text.BODY.SM)};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  border-radius: 50%;
  border: none;
  margin-right: ${pxToRem(spacing.xs)};
  height: ${pxToRem(36)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(36)};
`;

export const feefoLogo = css`
  display: block;
  width: 120px;
  margin: ${stack(spacing.md)};
`;

export const tooltipWrapper = css`
  ${fontSize(text.BODY.XS)}
  margin: ${stack(spacing.xxxl)};
`;

export const tooltipInfo = css`
  cursor: help;
  display: flex;
  align-items: center;
  ${fontSize(text.BODY.XS)}
`;

export const tooltipInfoIcon = css`
  display: block;
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
  margin: 0 0 ${pxToRem(2)} ${pxToRem(4)};
`;

export const noReviews = css`
  margin: 0 0 ${pxToRem(40)} 0;
`;
