import React from 'react';
import { LoadingSpinner } from '@noths/polaris-client-ribbons-design-system';
import dynamic from 'next/dynamic';

import { ModalLoader } from 'src/components/molecules/ModalLoader/ModalLoader';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/ZoomAndPanWrapper.styles';

interface DecorateWithZoomAndPanWrapperProps {
  children: JSX.Element;
  enabled: boolean;
  loadingSpinner: boolean;
  shouldDisplay: boolean;
}

const ZoomAndPanWrapper = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ZoomAndPanWrapper" */ './ZoomAndPanWrapper'))
      .ZoomAndPanWrapper,
  { loading: () => <ModalLoader /> },
);

export const DecorateWithZoomAndPanWrapper = ({
  children,
  enabled,
  loadingSpinner,
  shouldDisplay,
}: DecorateWithZoomAndPanWrapperProps) =>
  enabled ? (
    <ZoomAndPanWrapper shouldDisplay={shouldDisplay}>
      {loadingSpinner && <LoadingSpinner css={styles.loadingSpinner} />}
      {children}
    </ZoomAndPanWrapper>
  ) : (
    children
  );
