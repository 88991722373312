import * as R from 'ramda';

import type {
  SelectedPersonalisationsRecord,
  SelectionPayload,
} from 'src/redux/personalisation/types';
import type { CatServiceAPIProductOptionValue } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

export enum UpdateExisting {
  Dedupe,
  ChangeInPlace,
}

type UtilsFn<T> = (
  selectedPersonalisations: SelectedPersonalisationsRecord,
  { optionId }: SelectionPayload,
  updateType?: UpdateExisting,
) => T;

const hasExistingOption: UtilsFn<boolean> = (selectedPersonalisations, { optionId }) =>
  selectedPersonalisations[optionId] != null;

const hasExistingOptionValue: UtilsFn<boolean> = (
  selectedPersonalisations,
  { optionId, optionValue },
) => selectedPersonalisations[optionId]?.some((opt) => opt.id === optionValue.id) ?? false;

const getUpdatedExistingSelection: UtilsFn<SelectedPersonalisationsRecord> = (
  selectedPersonalisations,
  { optionId, optionValue },
  updateType,
) => {
  let filteredSelection: CatServiceAPIProductOptionValue[] = [];

  if (updateType === UpdateExisting.Dedupe) {
    filteredSelection = selectedPersonalisations[optionId]!.filter(
      (opt) => opt.id !== optionValue.id,
    );
  }

  if (updateType === UpdateExisting.ChangeInPlace) {
    filteredSelection = selectedPersonalisations[optionId]!.map((opt) =>
      opt.id === optionValue.id ? optionValue : opt,
    );
  }

  if (filteredSelection.length === 0) {
    return R.omit([optionId.toString()], selectedPersonalisations);
  }

  return {
    ...selectedPersonalisations,
    [optionId]: filteredSelection,
  };
};

export const getUpdatedSelectedOptionValues = (
  selectedPersonalisations: SelectedPersonalisationsRecord,
  selection: SelectionPayload,
  updateType: UpdateExisting,
): SelectedPersonalisationsRecord => {
  const { optionId, optionValue, type } = selection;
  const hasExistingSelection = hasExistingOptionValue(selectedPersonalisations, selection);

  if (hasExistingSelection) {
    return getUpdatedExistingSelection(selectedPersonalisations, selection, updateType);
  }

  if (type === 'single-selection') {
    return {
      ...selectedPersonalisations,
      [optionId]: [optionValue],
    };
  }

  return {
    ...selectedPersonalisations,
    [optionId]: hasExistingOption(selectedPersonalisations, selection)
      ? selectedPersonalisations[optionId]!.concat(optionValue)
      : [optionValue],
  };
};
