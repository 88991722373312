/* istanbul ignore file */

import * as recommendations from '@noths/polaris-client-recommendations';
import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import getConfig from 'next/config';

import { Placement } from 'src/constants/recommendations';
import { isProdLike } from 'src/environment';
import { recommendedProductsRequestComplete } from 'src/redux/richRelevance/actions';
import { requestRecommendedProducts } from 'src/redux/richRelevance/thunks';
import { getStore } from 'src/redux/store';
import type { NextConfig } from 'src/types/nextConfig';

const { publicRuntimeConfig } = getConfig() as NextConfig;

export const useAddToBasketPlacement = async (
  richRelevanceSessionId: string | undefined,
  productCode: number,
  isModalOpen: boolean,
) => {
  if (!isModalOpen) {
    return;
  }

  const { dispatch } = getStore();
  getUserAttributes()
    .then((attributes) =>
      recommendations.initRecommendations({
        apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
        env: isProdLike() ? 'production' : 'qa',
        sessionId: richRelevanceSessionId,
        userId: attributes?.sub,
      }),
    )
    .then(async () => {
      recommendations.setProductAddedToBasket(productCode);

      const placementData = await recommendations.getRecommendations([Placement.AddToBasket]);
      const addToBasketPlacement = placementData.find(
        (placement) => placement.placement_name === Placement.AddToBasket,
      );

      if (addToBasketPlacement) {
        await dispatch(requestRecommendedProducts(addToBasketPlacement));
      }
    })
    .catch((e) => {
      console.warn(`Could not load addToBasketPlacement recommendations: ${e.message}`);
    })
    .finally(() => {
      dispatch(recommendedProductsRequestComplete());
    });
};
