import React, { useRef } from 'react';

import { Modal } from 'src/components/molecules/Modal/Modal';
import * as modalStyles from 'src/components/molecules/Modal/styles/ModalExternalStyles';
import { ThumbnailsContainer } from 'src/components/organisms/ProductImagesCarousel/containers/ThumbnailsContainer';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/ProductImageModal.styles';
import * as productImagesStyles from 'src/components/organisms/ProductImagesCarousel/styles/ProductImages.styles';
import type {
  ImagesLocation,
  NextOrPreviousPayload,
  ProductImagesState,
  SetCurrentImagePayload,
} from 'src/components/organisms/ProductImagesCarousel/types';
import { ThumbnailOrientation } from 'src/components/organisms/ProductImagesCarousel/types';
import type { CatServiceAPIImage } from 'src/services/cat-service-api/types/CatServiceAPIImage';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { getIconPath } from 'src/utils/assetPaths';
import { ProductImagesModal } from './ProductImagesModal';

export const IMAGES_ID_PREFIX = 'fullscreen-';

const ICON_CLOSE = getIconPath('icons-24-close.svg');
const ICON_ARROW = getIconPath('icons-24-arrow-right.svg');

import { PRODUCT_IMAGES_TABPANEL_CONTAINER_ID } from 'src/components/organisms/ProductImagesCarousel/constants';

export interface ProductImageModalProps {
  closeModal: VoidFunction;
  currentImage: ProductImagesState['currentImage'];
  images: CatServiceAPIImage[];
  isModalOpen: ProductImagesState['isModalOpen'];
  onNextImage: (payload: NextOrPreviousPayload) => void;
  onPrevImage: (payload: NextOrPreviousPayload) => void;
  product: CatServiceAPIProduct;
  setCurrentImage: (payload: SetCurrentImagePayload) => void;
}

export const ProductImageModal = ({
  closeModal,
  currentImage,
  images,
  isModalOpen,
  onNextImage,
  onPrevImage,
  setCurrentImage,
}: ProductImageModalProps) => {
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [loadedImages, setImageLoaded] = React.useState<Record<number, boolean>>({});

  const multipleImagesToDisplay = images.length > 1;
  const totalImages = images.length;
  const location: ImagesLocation = 'Expanded image';

  return (
    <>
      <Modal
        closeModal={() => closeModal()}
        contentLabel="View full screen image"
        isModalOpen={isModalOpen}
      >
        <div css={[modalStyles.modalViewport, styles.modalContentContainer]} ref={innerRef}>
          <div css={styles.fullViewContainer}>
            {multipleImagesToDisplay && (
              <div css={styles.thumbnails}>
                <ThumbnailsContainer
                  idPrefix={IMAGES_ID_PREFIX}
                  location={location}
                  onThumbnailClick={(imageIndex) => {
                    setCurrentImage({
                      imageIndex: imageIndex,
                      navigationType: 'click',
                      location,
                    });
                  }}
                  orientation={ThumbnailOrientation.HORIZONTAL}
                />
              </div>
            )}
            <div css={styles.imagesContainer}>
              <ProductImagesModal
                currentImage={currentImage}
                images={images}
                loadedImages={loadedImages}
                multipleImagesToDisplay={multipleImagesToDisplay}
                setImageLoaded={setImageLoaded}
              />
              {multipleImagesToDisplay && (
                <p aria-hidden="true" css={productImagesStyles.bottomRightProgressIndicator}>
                  {`${currentImage + 1} of ${images.length}`}
                </p>
              )}

              <button
                aria-controls={PRODUCT_IMAGES_TABPANEL_CONTAINER_ID}
                aria-label="Show previous image"
                css={[
                  productImagesStyles.carouselNavigationButton,
                  productImagesStyles.prevNavigationButton,
                ]}
                onClick={() => onPrevImage({ location, totalImages })}
              >
                <img alt="" height={24} src={ICON_ARROW} width={24} />
              </button>
              <button
                aria-controls={PRODUCT_IMAGES_TABPANEL_CONTAINER_ID}
                aria-label="Show next image"
                css={[
                  productImagesStyles.carouselNavigationButton,
                  productImagesStyles.nextNavigationButton,
                ]}
                onClick={() => onNextImage({ location, totalImages })}
              >
                <img alt="" height={24} src={ICON_ARROW} width={24} />
              </button>
            </div>
          </div>

          <button css={styles.modalCloseButton} onClick={() => closeModal()}>
            <img alt="Close" height={24} src={ICON_CLOSE} width={24} />
          </button>
        </div>
      </Modal>
    </>
  );
};
