import type { DeliveryByEvent } from '@noths/polaris-dev-ts-types';
import { deliveryByEventNames } from '@noths/polaris-dev-ts-types';

export const isDeliveryAvailableByEvent = (
  deliveryEventIsAvailableForZone: boolean,
  productDeliveryByEvent: DeliveryByEvent | null,
) =>
  Boolean(
    deliveryEventIsAvailableForZone &&
      productDeliveryByEvent?.arriveByEvent &&
      deliveryByEventNames.includes(productDeliveryByEvent?.eventName),
  );
