import type { BreadcrumbLink } from '@noths/polaris-client-next-components';
import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getBreadcrumbsLinks } from 'src/utils/getBreadcrumbs';

const selectProductState = (state: ReduxApplicationState) => state.product;

export const selectBreadcrumbData = createSelector(
  selectProductState,
  (product): BreadcrumbLink[] => {
    const breadcrumbs = getBreadcrumbsLinks(product);
    return breadcrumbs.map(({ rel, title }) => ({
      href: rel,
      text: title,
    }));
  },
);
