/* istanbul ignore file */

import React from 'react';
import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import type {
  PillBarCarouselProps,
  PillBarItemClickPayload,
} from '@noths/polaris-client-ribbons-design-system';
import { PillBarCarousel } from '@noths/polaris-client-ribbons-design-system';

import { RELATED_CATEGORIES_TITLE } from 'src/components/organisms/LinkCarousel/constants/copy';
import * as styles from 'src/components/organisms/LinkCarousel/styles/LinkCarousel.styles';

export interface LinkCarouselProps extends Omit<PillBarCarouselProps, 'items' | 'title'> {
  links: PillBarCarouselProps['items'];
  onCarouselVisible?: () => void;
}

export const LinkCarousel = ({ links, onCarouselVisible }: LinkCarouselProps) => {
  const handleLinkClick = (
    evt: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    { index }: PillBarItemClickPayload,
  ) => {
    sendGALinkClickEvent(evt, {
      event: 'custom_event',
      event_category: 'Related Category',
      event_action: 'Link Click',
      event_label: links[index].text,
    });
  };

  return links.length > 0 ? (
    <div css={styles.linkCarouselWrapper}>
      <PillBarCarousel
        items={links}
        onItemClick={handleLinkClick}
        onVisible={onCarouselVisible}
        title={RELATED_CATEGORIES_TITLE}
      />
    </div>
  ) : null;
};
