import { createGlobalStyle, css } from 'styled-components';

import { MODAL_HEIGHT_CSS_VAR } from 'src/components/molecules/Modal/constants/cssVariables';

export const modalContent = css`
  position: fixed;
  height: 100vh;
  height: var(${MODAL_HEIGHT_CSS_VAR});
  min-height: 100%;
  width: 100%;
  background: #fff;
  top: 0rem;
`;

export interface ModalGlobalStylesProps {
  contentClassNames: ReactModal.Classes;
  overlayClassNames: ReactModal.Classes;
  tranistionMS: number;
}

export const ModalGlobalStyles = ({
  contentClassNames,
  overlayClassNames,
  tranistionMS,
}: ModalGlobalStylesProps) => createGlobalStyle<{ scrollBarWidth: number }>`
  .ReactModal__Body--open {
    padding-right: ${(props) => props.scrollBarWidth}px;
    overflow: hidden;
  }
  
  .${overlayClassNames.base} {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 600;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ${tranistionMS}ms ease 0s;

    &::before {
      background-color: var(--neutral-black-base);
      opacity: 0.4;
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .${overlayClassNames.afterOpen} {
    opacity: 1;
  }

  .${overlayClassNames.beforeClose} {
    opacity: 0;
  }

  .${contentClassNames.base} {
    left: 0;  
    bottom:0;
    box-sizing: border-box;
    height: 100vh;
    height: var(${MODAL_HEIGHT_CSS_VAR});
    min-height: 100%;
    position: absolute;
    transition: transform ${tranistionMS}ms linear;
    transition: opacity ${tranistionMS}ms;
    width: 100%;
    opacity: 0;
    transform: scale(0.8);
  }

  .${contentClassNames.afterOpen} {
    transform: scale(1);
    opacity: 1;
  }

  .${contentClassNames.beforeClose} {
    transform: scale(1);
    opacity: 0;
  }
`;
