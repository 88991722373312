import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useScrollbarWidth } from 'react-use';

import { MODAL_HEIGHT_CSS_VAR } from 'src/components/molecules/Modal/constants/cssVariables';
import { MODAL_TRASITION_MS } from 'src/components/molecules/Modal/constants/transitions';
import * as styles from 'src/components/molecules/Modal/styles/Modal.styles';

const setModalHeight = () =>
  document.documentElement.style.setProperty(MODAL_HEIGHT_CSS_VAR, `${window.innerHeight}px`);

export type ModalProps = {
  children: ReactNode;
  closeModal: () => void;
  contentLabel: string;
  isModalOpen: boolean;
  onAfterClose?: () => void;
};

const MODAL_CONTENT_CLASS_NAMES: styles.ModalGlobalStylesProps['contentClassNames'] = {
  base: 'modal-content',
  afterOpen: 'modal-content--afterOpen',
  beforeClose: 'modal-content--beforeClose',
};

const MODAL_OVERLAY_CLASS_NAMES: styles.ModalGlobalStylesProps['overlayClassNames'] = {
  base: 'modalOverlay',
  afterOpen: 'modal-overlay--afterOpen',
  beforeClose: 'modal-overlay--beforeClose',
};

const ModalGlobalStyles = styles.ModalGlobalStyles({
  contentClassNames: MODAL_CONTENT_CLASS_NAMES,
  overlayClassNames: MODAL_OVERLAY_CLASS_NAMES,
  tranistionMS: MODAL_TRASITION_MS,
});

export const Modal = ({
  children,
  closeModal,
  contentLabel,
  isModalOpen,
  onAfterClose,
}: ModalProps) => {
  const scrollBarWidth = useScrollbarWidth();
  ReactModal.setAppElement('#NOTHSPageWrapper');

  useEffect(() => {
    const handler = () => {
      // Note: value not always correctly set/updated on Android within Testcafe tests on Browserstack...
      setModalHeight();
    };

    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <>
      <ModalGlobalStyles scrollBarWidth={scrollBarWidth || 0} />
      <ReactModal
        className={MODAL_CONTENT_CLASS_NAMES}
        closeTimeoutMS={MODAL_TRASITION_MS}
        contentLabel={contentLabel}
        isOpen={isModalOpen}
        onAfterClose={onAfterClose}
        onAfterOpen={() => {
          /* Note: The innerHeight value returned within the hook above is not always correct when running the Testcafe
          tests on Android within Browserstack, therefore explicitly redefine it here */
          setModalHeight();
        }}
        onRequestClose={closeModal}
        overlayClassName={MODAL_OVERLAY_CLASS_NAMES}
      >
        <div css={styles.modalContent}>{children}</div>
      </ReactModal>
    </>
  );
};
