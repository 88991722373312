import React from 'react';
import { priceFormatter } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { DeliverySettingsAPIDeliveryOptionCode } from 'src/services/delivery-settings/types/DeliverySettingsAPIDeliveryOptionCode';
import { getFormattedDateString } from 'src/utils/date';
import { FREE_DELIVERY } from '../constants/copy';
import { getDeliveryOptionName } from '../getDeliveryOptionName';
import * as styles from './DeliveryOption.styles';

interface DeliveryOptionProps {
  currency: CurrencyCode;
  date?: string;
  max?: number;
  min?: number;
  optionCode: DeliverySettingsAPIDeliveryOptionCode;
  price: number;
  provider?: string;
}

export const DeliveryOption = ({
  currency,
  date,
  max,
  min,
  optionCode,
  price,
  provider,
}: DeliveryOptionProps) => {
  return (
    <li css={styles.root}>
      <h6>
        <b>{getDeliveryOptionName(optionCode)}</b>
        <span>{price === 0 ? FREE_DELIVERY : priceFormatter(currency, price, false)}</span>
      </h6>
      <p>
        {date ? (
          <>
            Estimated by <time dateTime={date}>{getFormattedDateString(date)}</time>
            {provider && (
              <>
                {' via '}
                <b>{provider}</b>
              </>
            )}
          </>
        ) : (
          `${min} - ${max} days from dispatch`
        )}
      </p>
    </li>
  );
};
