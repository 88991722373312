import { toSentenceCase } from '@noths/polaris-client-utils';
import type { Link, NonNullableLink } from '@noths/polaris-dev-ts-types';

import type { ProductState } from 'src/redux/product';
import type { BreadcrumbsListItem } from 'src/types/productJSONLDSchema';

// the last item in the breadcrumb hierarchy has a null rel and href
// and is also the product itself which we are not showing in the breadcrumbs
// we are also guarding against empty string values
const isNonNullableLink = (link: Link): link is NonNullableLink => {
  return (
    link.href !== null &&
    link.href.trim() !== '' &&
    link.rel !== null &&
    link.rel.trim() !== '' &&
    link.title !== null &&
    link.title.trim() !== ''
  );
};

const getBreadcrumbsHierarchy = (product: ProductState): NonNullableLink[] =>
  (product.navigation.find((n) => n.type === 'breadcrumb')?.options ?? []).filter(
    isNonNullableLink,
  );

export const getBreadcrumbsLinks = (product: ProductState): NonNullableLink[] => {
  const breadcrumbsHierarchy = getBreadcrumbsHierarchy(product);

  return breadcrumbsHierarchy.reduce<NonNullableLink[]>((items, { href, rel, title }) => {
    if (rel?.toLowerCase() === '/home') {
      return items;
    }
    return items.concat({
      rel,
      href,
      title: title && toSentenceCase(title),
    });
  }, []);
};

export const getBreadcrumbsListItems = (product: ProductState): BreadcrumbsListItem[] => {
  const breadcrumbsLinks = getBreadcrumbsLinks(product);

  return breadcrumbsLinks.map(({ href, title }, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: title,
    item: href,
  }));
};
