import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import type { EntryFieldProps } from '@noths/polaris-client-ribbons-design-system';
import { EntryField } from '@noths/polaris-client-ribbons-design-system';
import type { _Omit } from '@noths/polaris-dev-ts-recipes';

import {
  MAX_CHARACTERS_COPY,
  NO_EMOJIS_ALLOWED_COPY,
  REQUIRED_INPUT_COPY,
} from 'src/components/organisms/Personalisation/constants/copy';
import { containsEmoji, getTextCaseNotice } from 'src/components/organisms/Personalisation/utils';
import type { PersonalisationProductOption } from 'src/types/personalisation';

export interface InputOnChangeArgs {
  isValid: boolean;
  value: string;
}

export type OwnProps = _Omit<PersonalisationProductOption, 'type'> & {
  allowLineBreaks?: boolean;
  displayValidation?: boolean;
  onChange: ({ isValid, value }: InputOnChangeArgs) => void;
  required?: boolean;
};

interface ContainerProps {
  error: boolean;
  value?: string;
}

type Props = OwnProps & ContainerProps;

interface BlurHandlerProps extends Pick<OwnProps, 'onChange' | 'maxCharacters'> {
  e: ChangeEvent<HTMLTextAreaElement>;
  setErrorMessage: (value: string) => void;
}

interface ChangeHandlerProps extends Pick<OwnProps, 'allowLineBreaks'> {
  e: Parameters<EntryFieldProps['onChange']>[0];
  setStateValue: Dispatch<SetStateAction<string>>;
}

const isValidInputField = (value: string, maxCharacters: number) => {
  if (containsEmoji(value)) {
    return false;
  }

  return value.length <= maxCharacters;
};

/**
 * The majority of the input validation code happens here after blur is called
 * the onChange isValid boolean will handle preventing submit
 */
const handleBlur = ({ e, maxCharacters, onChange, setErrorMessage }: BlurHandlerProps) => {
  const { value } = e.target;
  const hasEmptyInputValue = value.length && value.trim().length === 0;
  const isValid = !hasEmptyInputValue && isValidInputField(value, maxCharacters);

  if (hasEmptyInputValue) {
    setErrorMessage(REQUIRED_INPUT_COPY);
  }

  onChange({ value, isValid });
};

const handleChange = ({ allowLineBreaks, e, setStateValue }: ChangeHandlerProps) => {
  const { value } = e.target;
  const isLineBreak = e.nativeEvent.inputType === 'insertLineBreak';

  if (!allowLineBreaks && isLineBreak) {
    return;
  }

  const hasDisallowedNewLines = !allowLineBreaks && (value.includes('\n') || value.includes('\r'));

  /*
    The Regex replacement needs to be conditionally run, instead of for every change.
    Asides from performance implications, all whitespaces get removed when no newlines are detected.
  */
  const sanitisedValue = hasDisallowedNewLines ? value.replace(/\s+/g, ' ').trim() : value;

  setStateValue(() => sanitisedValue);
};

export const Input = ({
  allowLineBreaks = true,
  allowedCase,
  error,
  id,
  maxCharacters,
  minCharacters,
  name,
  onChange,
  required = false,
  value = '',
}: Props) => {
  const [stateValue, setStateValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState(error ? REQUIRED_INPUT_COPY : '');

  useEffect(() => {
    const hasExcessiveInputCharLength = stateValue.length > maxCharacters;

    if (hasExcessiveInputCharLength) {
      setErrorMessage(MAX_CHARACTERS_COPY);
    } else if (containsEmoji(stateValue)) {
      setErrorMessage(NO_EMOJIS_ALLOWED_COPY);
    } else if (error) {
      setErrorMessage(REQUIRED_INPUT_COPY);
    } else {
      setErrorMessage('');
    }
  }, [error, maxCharacters, stateValue]);

  // Only used if value prop gets updated to refresh input stateValue
  useEffect(() => {
    if (value !== stateValue) {
      setStateValue(value);
      onChange({ value, isValid: isValidInputField(value, maxCharacters) });
    }
  }, [value]);

  return (
    <EntryField
      description={allowedCase ? getTextCaseNotice(allowedCase) : ''}
      error={errorMessage}
      feedback={{
        name: errorMessage || '',
        value: maxCharacters - stateValue.length,
      }}
      id={id.toString()}
      minLength={minCharacters}
      name={name}
      onBlur={(e) => handleBlur({ e, maxCharacters, onChange, setErrorMessage })}
      onChange={(e) => handleChange({ e, setStateValue, allowLineBreaks })}
      placeholder={`Max ${maxCharacters} characters`}
      required={required}
      value={stateValue}
    />
  );
};
