import { minBreakpoints } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

export const stickySection = css`
  padding: ${pxToRem(16)} ${pxToRem(16)} 0 ${pxToRem(16)};
  background: var(--neutral-white-base);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  transition: transform 0.25s;
  box-shadow: 0 ${pxToRem(-4)} ${pxToRem(8)} 0 rgba(0, 0, 0, 0.2);

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      display: none;
    `,
  )}
`;
