import { minBreakpoints } from '@noths/polaris-client-ribbons-base';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getFieldPosition,
  scrollToInvalidField,
} from 'src/components/organisms/Personalisation/utils';
import { addItemToBasket, updateItemInBasket } from 'src/redux/basket';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectInvalidCustomPersonalisations,
  selectIsPersonalisationInEditMode,
  selectOutstandingPersonalisations,
  selectTrackedEvents,
} from 'src/redux/personalisation/selectors';
import { actions } from 'src/redux/personalisation/slice';
import type { TrackedEvents } from 'src/redux/personalisation/types';
import { actions as ProductInfoActions } from 'src/redux/productInfo/slice';
import { navigateToBasket } from 'src/redux/productInfo/thunks';
import { selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

const trackOutstandingSelections = (
  outstandingSelections: CatServiceAPIProductOption[],
  productOptions: CatServiceAPIProductOption[],
  trackedEvents: TrackedEvents,
  dispatch: AppThunkDispatch,
) => {
  outstandingSelections.forEach((opt) => {
    if (!trackedEvents.error['Add to Cart Error'][opt.name]) {
      const isMultipleOptionSelection = (opt.values ?? []).length > 3;
      const selectionLabel = isMultipleOptionSelection
        ? '3 and more values selection'
        : 'single & binary selection';

      dispatch(
        actions.trackInteractionError({
          field_action: 'Add to Cart Error',
          field_name: opt.name,
          field_position: getFieldPosition(productOptions, opt),
          field_type: opt.type === 'selection' ? selectionLabel : 'input field',
          required_field: opt.required,
        }),
      );
    }
  });
};

interface ThunkConfig {
  dispatch: AppThunkDispatch;
  state: ReduxApplicationState;
}

export const submitPersonalisationForm = createAsyncThunk<void, void, ThunkConfig>(
  'personalisation/submitPersonalisationForm',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const outstandingSelections = selectOutstandingPersonalisations(state);
    const invalidCustomSelections = selectInvalidCustomPersonalisations(state);
    const isPersonalisationInEditMode = !!selectIsPersonalisationInEditMode(state);
    const product = selectProduct(state);
    const trackedEvents = selectTrackedEvents(state);

    dispatch(actions.showPersonalisationValidationErrors());

    if (
      outstandingSelections.length > 0 ||
      invalidCustomSelections.length > 0 ||
      !product.isQuantityValid
    ) {
      dispatch(actions.setPersonalisationExpanded({ expanded: true }));

      trackOutstandingSelections(outstandingSelections, product.options, trackedEvents, dispatch);

      scrollToInvalidField();
      return;
    }

    const result = await dispatch(
      isPersonalisationInEditMode ? updateItemInBasket() : addItemToBasket(),
    );

    if (!result.payload) {
      return;
    }

    const isTabletOrSmaller = window.innerWidth < minBreakpoints.L;

    if (!isTabletOrSmaller) {
      dispatch(ProductInfoActions.openBasketModal());
      return;
    }

    dispatch(navigateToBasket());
  },
);
