import { connect } from 'react-redux';
import { deliveryByEventNames } from '@noths/polaris-dev-ts-types';

import type { DeliveryEventProps } from 'src/components/molecules/DeliveryEvent/components/DeliveryEvent';
import { DeliveryEvent } from 'src/components/molecules/DeliveryEvent/components/DeliveryEvent';
import { selectCheapestDeliveryOptionPrice } from 'src/components/molecules/DeliveryEvent/selectors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProductDeliveryByEvent } from 'src/redux/selectors';
import { getDeliveryByEventLabel } from '../utils';

const mapStateToProps = (state: ReduxApplicationState): DeliveryEventProps => {
  const deliveryEvent = selectProductDeliveryByEvent(state);
  const valid = deliveryEvent && deliveryByEventNames.includes(deliveryEvent.eventName);

  return {
    cheapestDeliveryPrice: valid ? selectCheapestDeliveryOptionPrice(state) : null,
    currency: state.userConfiguration.currencyCode || state.product.price.currency,
    eventName: valid ? getDeliveryByEventLabel(deliveryEvent.eventName) : undefined,
    loading: state.deliveryZoneOptions.isLoading,
  };
};

export const DeliveryEventContainer = connect(mapStateToProps)(DeliveryEvent);
