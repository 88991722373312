import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type {
  ProductDescriptionDispatchProps,
  ProductDescriptionStateProps,
} from 'src/components/organisms/ProductDescription/components/ProductDescription';
import { ProductDescription } from 'src/components/organisms/ProductDescription/components/ProductDescription';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProduct } from 'src/redux/selectors';

const mapStateToProps = (state: ReduxApplicationState): ProductDescriptionStateProps => ({
  product: selectProduct(state),
});

const mapDispatchToProps = (): ProductDescriptionDispatchProps => ({
  onVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Product long description',
      event_action: 'Impression',
      event_label: 'not set',
    });
  },
});

export const ProductDescriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductDescription);
