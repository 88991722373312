import { selectFavouritesSummary, selectIsFavourite } from '@noths/polaris-client-user-favourites';
import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from './combinedReducer';

export const selectProduct = (state: ReduxApplicationState) => state.product;

export const selectProductDeliveryByEvent = (state: ReduxApplicationState) =>
  state.product.deliveryByEvent;

export const selectProductDeliveryOptions = (state: ReduxApplicationState) =>
  state.product.deliveryOptions;

export const selectProductPrice = (state: ReduxApplicationState) => state.product.price;

export const selectProductReviews = (state: ReduxApplicationState) => state.reviews;

export const selectProductPartner = (state: ReduxApplicationState) => state.product.partner;

export const selectPersonalisationOptions = createSelector(
  [selectProduct],
  (product) => product.options,
);

export const selectFavouriteProduct = createSelector(
  [selectFavouritesSummary, selectProduct],
  (favouritesSummary, product) => {
    const isFavourited = selectIsFavourite(favouritesSummary, product.code);

    return { ...product, isFavourited };
  },
);
