import React from 'react';
import { MembershipBanner } from '@noths/polaris-client-ribbons-design-system';

import { trackCTAClick } from 'src/tracking/trackCTAClick';
import { trackImpression } from 'src/tracking/trackImpression';
import * as styles from './MembershipBannerSection.styles';

const commonTrackingArgs = {
  category: 'Membership banner',
  contentType: 'membership banner|pdp-membership-banner-noths&more',
  label: 'pdp-membership-banner-noths&more',
};

export const MembershipBannerSection = () => {
  return (
    <div css={styles.wrapper}>
      <MembershipBanner
        buttonHref="/membership"
        buttonText="Tell me more"
        heading="Save 10% on this item with a NOTHS & More membership"
        onButtonClick={(e) => trackCTAClick(e, commonTrackingArgs)}
        onVisible={() => trackImpression(commonTrackingArgs)}
      />
    </div>
  );
};
