import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const container = css`
  padding: ${pxToRem(spacing.xl)} 0;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding: ${stack(spacing.xxl)} 0;
    `,
  )}
`;
