import type { MutableRefObject } from 'react';
import React, { useEffect, useState } from 'react';
import { Drawer, SolidButton } from '@noths/polaris-client-ribbons-design-system';
import { priceFormatter } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { ToggleButton } from 'src/components/organisms/Personalisation/components/ToggleButton';
import * as copy from 'src/components/organisms/Personalisation/constants/copy';
import * as styles from 'src/components/organisms/Personalisation/styles/Personalisation.styles';
import {
  getFieldPosition,
  getProgressMilestone,
} from 'src/components/organisms/Personalisation/utils';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
  TrackedEvents,
} from 'src/redux/personalisation';
import type {
  CatServiceAPIProductOption,
  CatServiceAPIProductOptionPriceModifier,
  CatServiceAPIProductOptionValue,
} from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

export const SELECTION_MULTIPLE = 'selection-multiple';
export const CTA_APPLY_CLASSNAME = 'cta-apply-button';

export const DRAWER_DESCRIPTOR_ID = 'drawer-description';
const TOGGLE_BUTTON_GROUP_DESCRIPTOR_ID = 'personalisation-toggle-button-group-description';

const getAmount = (
  priceModifier: CatServiceAPIProductOptionPriceModifier | null | undefined,
  currencyCode: CurrencyCode,
) => {
  return priceModifier?.prices.find(({ currency }) => currency === currencyCode)?.amount ?? 0;
};

export interface SelectionOptionValueProps {
  appliedValue: CatServiceAPIProductOptionValue | undefined;
  currency: CurrencyCode;
  elementId: string;
  isExpanded: boolean;
  onClose: () => void;
  onConfirm: (selectedValue: CatServiceAPIProductOptionValue | null) => void;
  option: CatServiceAPIProductOption;
  options: CatServiceAPIProductOption[];
  target: MutableRefObject<HTMLUListElement>;
  totalOutstandingSelections: number;
  totalPrice: number;
  totalRequiredPersonalisations: number;
  trackedEvents: TrackedEvents;
}

export interface DispatchProps {
  trackInteraction: (payload: InteractionTrackingEvent) => void;
  trackInteractionError: (payload: InteractionErrorTrackingEvent) => void;
  trackProgressMilestone: (payload: ProgressTrackingEvent) => void;
}

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

export const MultipleOptionSelectionDrawer = ({
  appliedValue,
  currency,
  elementId,
  isExpanded,
  onClose,
  onConfirm,
  option,
  options,
  target,
  totalOutstandingSelections,
  totalPrice,
  totalRequiredPersonalisations,
  trackInteraction,
  trackProgressMilestone,
  trackedEvents,
}: SelectionOptionValueProps & DispatchProps) => {
  const [width, setWidth] = useState(0);
  const [selectedValue, setSelectedValue] = useState<CatServiceAPIProductOptionValue | null>(
    appliedValue || null,
  );
  const selectedAmount = getAmount(selectedValue?.priceModifier, currency);
  const appliedAmount = getAmount(appliedValue?.priceModifier, currency);
  const appliedPrice = totalPrice - appliedAmount + selectedAmount;

  useEffect(() => {
    const updateWidth = () => {
      const leftPos = target.current.getBoundingClientRect().left;
      const currentWidth = isMobileDevice()
        ? window.screen.width - leftPos!
        : window.innerWidth - leftPos!;
      setWidth(currentWidth);
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only used if value prop gets updated to refresh selectedValue
  useEffect(() => {
    if (appliedValue && appliedValue !== selectedValue) {
      setSelectedValue(appliedValue);
    }
  }, [appliedValue]);

  const handleOnChange = (
    newValue: CatServiceAPIProductOptionValue | null,
    currentValueName: string,
  ) => {
    const fieldName = option.name;
    const fieldPosition = getFieldPosition(options, option);

    trackInteraction({
      field_position: fieldPosition,
      field_name: fieldName,
      field_action: 'Field Input',
      field_type: '3 and more values selection',
      field_value: newValue ? currentValueName : null,
      required_field: option.required,
    });

    const milestone = getProgressMilestone({
      totalRequiredPersonalisations,
      totalOutstandingSelections,
      trackedEvents,
      fieldName,
      isRequiredPersonalisation: option.required,
    });

    if (milestone) {
      trackProgressMilestone({
        field_position: fieldPosition,
        field_name: fieldName,
        field_action: milestone,
        field_type: '3 and more values selection',
        field_value: currentValueName,
        required_field: true,
      });
    }

    setSelectedValue(newValue);
  };

  const handleFocusTracking = (valueName: string) => {
    if (trackedEvents.focus[option.name]) {
      return;
    }

    trackInteraction({
      field_position: getFieldPosition(options, option),
      field_name: option.name,
      field_action: 'Field Focus',
      field_type: '3 and more values selection',
      field_value: valueName,
      required_field: option.required,
    });
  };

  const handleOnClose = () => {
    setSelectedValue(appliedValue || null);
    onClose();
  };

  const handleOnConfirm = () => {
    onConfirm(selectedValue);
  };

  return (
    <Drawer
      contentLabel={copy.CONTENT_LABEL}
      describedBy={DRAWER_DESCRIPTOR_ID}
      isOpen={isExpanded}
      onOverlayClick={handleOnConfirm}
      onRequestClose={handleOnClose}
    >
      <section css={styles.selectionMultipleDrawer} id={elementId} style={{ width }}>
        <div css={styles.selectionMultipleInner}>
          <div css={styles.topModalSection}>
            <button
              aria-controls={elementId}
              aria-expanded={isExpanded}
              aria-label="Close"
              css={[styles.inputSelect, styles.inputClose]}
              onClick={handleOnClose}
            />
            {option.required && <p css={styles.requiredLabel}>{copy.REQUIRED_COPY}</p>}
          </div>
          <p
            css={[styles.legend, styles.legendLarge]}
            dangerouslySetInnerHTML={{ __html: option.name }}
            id={DRAWER_DESCRIPTOR_ID}
          />
          <div
            aria-describedby={TOGGLE_BUTTON_GROUP_DESCRIPTOR_ID}
            aria-labelledby={DRAWER_DESCRIPTOR_ID}
            css={styles.innerFieldset}
            role="group"
          >
            <p css={styles.toggleButtonGroupDescription} id={TOGGLE_BUTTON_GROUP_DESCRIPTOR_ID}>
              {copy.TOGGLE_BUTTON_GROUP_DESCRIPTION}
            </p>
            {option.values?.map(({ id, name, priceModifier }) => (
              <ToggleButton
                currency={currency}
                isSelected={id === selectedValue?.id}
                key={id}
                onClick={(newValue) => handleOnChange(newValue, name)}
                onFocus={() => handleFocusTracking(name)}
                value={{ id, name, priceModifier }}
              />
            ))}
          </div>
          <div css={styles.ctaApplyButtonWrapper}>
            <p css={styles.priceApplyText}>
              {copy.AFTER_APPLYING_LABEL} {priceFormatter(currency, appliedPrice)}
            </p>
            <SolidButton
              className={CTA_APPLY_CLASSNAME}
              css={styles.ctaApplyButton}
              fullWidth
              onClick={handleOnConfirm}
            >
              {copy.APPLY_COPY}
            </SolidButton>
          </div>
        </div>
      </section>
    </Drawer>
  );
};
