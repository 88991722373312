import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const prices = css`
  margin: 0 0 ${spacing.lg}px;
  display: flex;
  column-gap: ${spacing.xs}px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const from = css`
  ${responsiveText.BODY.MD}
`;

export const currentPrice = css`
  display: inline-block;
  margin: 0;
  ${responsiveText.HEADING.LG}
`;

export const previousPrice = css`
  display: inline-block;
  margin: 0 0 0 ${spacing.sm}px;
  ${responsiveText.HEADING.LG}
`;
