import getConfig from 'next/config';

import type { NextConfig } from 'src/types/nextConfig';

const {
  publicRuntimeConfig: { STATIC_ASSETS_CDN },
} = getConfig() as NextConfig;

export const getIconPath = (svg: string) => `${STATIC_ASSETS_CDN}/svgs/${svg}`;

export const getImagePath = (image: string) => `${STATIC_ASSETS_CDN}/images/${image}`;
