import React from 'react';
import { NotificationInfo, NotificationWarning } from '@noths/polaris-client-ribbons-design-system';

import type { ProductState } from 'src/redux/product';
import { isMadeToOrder, isOutOfStock } from 'src/utils/inventoryStatus';
import { NO_LONGER_AVAILABLE_LABEL, OUT_OF_STOCK_LABEL } from './copy';
import { MadeToOrder } from './MadeToOrder';

interface InventoryStatusLabelProps {
  inventoryStatus: ProductState['inventoryStatus'];
  suspended: boolean;
}

export const InventoryStatusLabel = ({ inventoryStatus, suspended }: InventoryStatusLabelProps) => {
  switch (true) {
    case isMadeToOrder(inventoryStatus):
      return <MadeToOrder />;
    case suspended:
      return <NotificationWarning>{NO_LONGER_AVAILABLE_LABEL}</NotificationWarning>;
    case isOutOfStock(inventoryStatus):
      return <NotificationInfo>{OUT_OF_STOCK_LABEL}</NotificationInfo>;
    default:
      return null;
  }
};
