import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import { ProductReviews } from 'src/components/organisms/ProductReviews/components/ProductReviews';
import type { DispatchProps } from 'src/components/organisms/ProductReviews/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions, requestProductReviews } from 'src/redux/reviews';
import { selectProductPartner, selectProductReviews } from 'src/redux/selectors';

const mapStateToProps = (state: ReduxApplicationState) => ({
  reviews: selectProductReviews(state),
  partner: selectProductPartner(state),
  onVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Product reviews',
      event_action: 'Impression',
      event_label: 'not set',
    });
  },
});

const mapDispatchToProps: DispatchProps = {
  loadPageOfReviews: (page: number) => requestProductReviews({ query: { page } }),
  onProductReviewsExpandReadMore: actions.onProductReviewsExpandReadMore,
  onProductReviewsNextPage: actions.onProductReviewsNextPage,
  onProductReviewsPagination: actions.onProductReviewsPagination,
  onProductReviewsProductRatingSystem: actions.onProductReviewsProductRatingSystem,
};

export const ProductReviewsContainer = connect(mapStateToProps, mapDispatchToProps)(ProductReviews);
