import React from 'react';
import { Checkbox } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import * as styles from 'src/components/organisms/Personalisation/styles/SelectionOptionValue.styles';
import type { CatServiceAPIProductOptionValue } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import { getFormattedPersonalisationAmount } from 'src/utils/price';

interface SelectedCheckboxOptionProps {
  currency: CurrencyCode;
  error: boolean;
  id: string;
  isSelected: boolean;
  name: string;
  onChange: (value: CatServiceAPIProductOptionValue | null) => void;
  onFocus?: () => void;
  value: CatServiceAPIProductOptionValue;
}

export const SelectedCheckboxOption = ({
  currency,
  error,
  id,
  isSelected,
  name,
  onChange,
  onFocus,
  value,
}: SelectedCheckboxOptionProps) => {
  const { isNegative = false, value: formattedPriceValue = '' } = value.priceModifier
    ? getFormattedPersonalisationAmount(currency, value.priceModifier)
    : {};

  return (
    <div css={styles.wrapper} data-is-selected={isSelected}>
      <Checkbox
        checked={isSelected}
        id={id}
        name={name}
        onChange={() => onChange(value)}
        onFocus={onFocus}
        {...{ 'aria-invalid': error }}
      />
      <label css={styles.label} htmlFor={id}>
        <span dangerouslySetInnerHTML={{ __html: value.name }} />
        {value.priceModifier && (
          <span css={styles.priceModifier}>
            <span css={styles.priceOperator} data-is-negative={isNegative}>
              {isNegative ? <>&minus;</> : <>&#43;</>}
            </span>
            <span>{formattedPriceValue.replace('-', '')}</span>
          </span>
        )}
      </label>
    </div>
  );
};
