import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { fetchDeliveryOptions } from 'src/services/delivery-settings/fetchDeliveryOptions';
import type { DeliverySettingsAPIDeliveryZone } from 'src/services/delivery-settings/types/DeliverySettingsAPIDeliveryZone';

export const requestDeliveryZoneOptions = createAsyncThunk<DeliverySettingsAPIDeliveryZone[]>(
  'deliveryZoneOptions/requestDeliveryZoneOptions',
  (_, { getState }) => {
    const { product } = getState() as ReduxApplicationState;

    return fetchDeliveryOptions(product.code, product.price);
  },
);
