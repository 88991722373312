import type { KeyboardEvent, MouseEvent, SyntheticEvent } from 'react';
import React, { useRef, useState } from 'react';
import { useVisibilityObserver } from '@noths/polaris-client-react-hooks';
import { Image } from '@noths/polaris-client-ribbons-design-system';
import { getProductImageURL, isKeyboardTriggeredEvent } from '@noths/polaris-client-utils';

import {
  PRODUCT_IMAGES_SECTION_LABEL_PLURAL,
  PRODUCT_IMAGES_TAB_ID_PREFIX,
  PRODUCT_IMAGES_TABPANEL_ID_PREFIX,
} from 'src/components/organisms/ProductImagesCarousel/constants';
import { useThumbnailFocusAndScroll } from 'src/components/organisms/ProductImagesCarousel/hooks/useThumbnailFocusAndScroll';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/Thumbnails.styles';
import type {
  ImagesLocation,
  NextOrPreviousPayload,
  ProductImagesState,
} from 'src/components/organisms/ProductImagesCarousel/types';
import { ThumbnailOrientation } from 'src/components/organisms/ProductImagesCarousel/types';

interface ThumbnailImage {
  href: string;
  title: string;
}

export interface OnVisiblePayload {
  totalImages: number;
}

interface ThumbnailsProps {
  currentImage: ProductImagesState['currentImage'];
  idPrefix?: string;
  images: ThumbnailImage[];
  location: ImagesLocation;
  onNextImage: (payload: NextOrPreviousPayload) => void;
  onPrevImage: (payload: NextOrPreviousPayload) => void;
  onThumbnailClick: (imageIndex: number) => void;
  onVisible: (payload: OnVisiblePayload) => void;
  orientation: ThumbnailOrientation;
}

export const Thumbnails = ({
  currentImage,
  idPrefix = '',
  images,
  location,
  onNextImage,
  onPrevImage,
  onThumbnailClick,
  onVisible,
  orientation,
}: ThumbnailsProps) => {
  const totalImages = images.length;
  const { observedElementRef: productImagesCarouselRef } = useVisibilityObserver(() => {
    onVisible({ totalImages });
  });
  const thumbnailRefs = useRef<HTMLAnchorElement[] | null[]>([]);
  const [thumbnailsHaveKeyboardFocus, setThumbnailsHaveKeyboardFocus] = useState(false);
  const prevImageKey = orientation === ThumbnailOrientation.HORIZONTAL ? 'ArrowLeft' : 'ArrowUp';
  const nextImageKey = orientation === ThumbnailOrientation.HORIZONTAL ? 'ArrowRight' : 'ArrowDown';
  const orientationStyles = styles[orientation];
  const visibilityTrackingEnabled = location !== 'Expanded image';

  useThumbnailFocusAndScroll({
    thumbnailsHaveKeyboardFocus,
    thumbnailRefs,
    currentImage,
    shouldScrollThumbnailIntoView: location !== 'Carousel',
  });

  const handleKeyDown = (event: KeyboardEvent<HTMLAnchorElement>) => {
    switch (event.key) {
      case prevImageKey:
        onPrevImage({ location, totalImages });
        event.preventDefault();
        break;
      case nextImageKey:
        onNextImage({ location, totalImages });
        event.preventDefault();
        break;
    }
  };

  const handleThumbnailFocus = (event: SyntheticEvent<HTMLElement>) =>
    setThumbnailsHaveKeyboardFocus(isKeyboardTriggeredEvent(event));

  const handleClick = (event: MouseEvent<HTMLAnchorElement>, imageIndex: number) => {
    event.preventDefault();
    onThumbnailClick(imageIndex);
  };

  return (
    <div
      aria-label={PRODUCT_IMAGES_SECTION_LABEL_PLURAL}
      aria-orientation={orientation}
      css={[
        orientationStyles.thumbnails,
        thumbnailsHaveKeyboardFocus ? styles.thumbnailsWithKeyboardFocus : null,
      ]}
      onBlur={() => setThumbnailsHaveKeyboardFocus(false)}
      onFocus={handleThumbnailFocus}
      ref={visibilityTrackingEnabled ? productImagesCarouselRef : null}
      role="tablist"
    >
      <ul css={orientationStyles.thumbnailList}>
        {images.map(({ href: src, title }, i) => (
          <li css={orientationStyles.thumbnailListItem} key={src}>
            <a
              aria-controls={`${idPrefix}${PRODUCT_IMAGES_TABPANEL_ID_PREFIX}${i}`}
              aria-label={`${title}, ${i + 1} of ${images.length}`}
              aria-selected={i === currentImage}
              css={styles.thumbnailImageLink}
              href={getProductImageURL(src, 'original')}
              id={`${idPrefix}${PRODUCT_IMAGES_TAB_ID_PREFIX}${i}`}
              onClick={(event) => handleClick(event, i)}
              onKeyDown={handleKeyDown}
              ref={(elem) => {
                thumbnailRefs.current[i] = elem;
              }}
              role="tab"
              tabIndex={i === currentImage ? undefined : -1}
            >
              <Image
                alt={`${title}, thumbnail ${i + 1} of ${images.length}`}
                css={orientationStyles.thumbnailImage}
                src={getProductImageURL(src, 'mini')}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
