import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { DeliveryBlockContextID } from 'src/components/molecules/DeliveryBlock/components/DeliveryBlock';
import { selectDeliveryByEventCost } from 'src/components/molecules/DeliveryEvent/selectors';
import { getDeliveryByEventLabel } from 'src/components/molecules/DeliveryEvent/utils';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  actions,
  selectDecoratedDeliveryOptions,
  selectMinMaxDeliveryEstimates,
} from 'src/redux/deliveryZoneOptions';
import { selectProductDeliveryByEvent } from 'src/redux/selectors';
import type { DeliveryAreaLocationSelection } from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [actions.setSelectedCountry.type]: (state, nextState, action) => {
    const { payload } = action as ReturnType<typeof actions.setSelectedCountry>;

    if (typeof payload === 'object') {
      const { previouslySelectedZone, selectedCountry } = state.deliveryZoneOptions;
      const fromLocation = `${selectedCountry} - ${previouslySelectedZone}`;
      const toLocation = `${payload.selectedCountry} - ${payload.selectedZone}`;
      const deliveryByEventCheapestPrice = selectDeliveryByEventCost(nextState);
      const productDeliveryByEvent = selectProductDeliveryByEvent(nextState);

      const deliveryEventIsAvailableForZone = Boolean(deliveryByEventCheapestPrice !== 'not set');
      const deliveryByEvent =
        deliveryEventIsAvailableForZone && productDeliveryByEvent?.arriveByEvent
          ? getDeliveryByEventLabel(productDeliveryByEvent.eventName).replace(' for', '')
          : 'not set';

      const countryDeliveryOptionCodes = selectDecoratedDeliveryOptions(nextState)
        .map((opt) => opt.optionCode)
        .join(', ');

      const { max: deliveryOptionMax, min: deliveryOptionMin } =
        selectMinMaxDeliveryEstimates(nextState);

      sendGAEvent<DeliveryAreaLocationSelection>({
        event: 'custom_event',
        event_action: 'Change delivery location',
        event_category:
          payload.contextID === DeliveryBlockContextID.DeliveryArea
            ? 'Delivery & Returns'
            : 'Delivery options',
        event_label: `FROM: ${fromLocation} TO: ${toLocation}`,
        delivery: {
          options: countryDeliveryOptionCodes,
          min_delivery_time: deliveryOptionMin,
          max_delivery_time: deliveryOptionMax,
          delivery_by_event: deliveryByEvent,
          delivery_by_event_cost: String(deliveryByEventCheapestPrice),
        },
      });
    }
  },
};
