import { sendGAEvent } from '@noths/polaris-client-google-analytics';

interface TrackImpressionArgs {
  category: string;
  contentType: string;
  label: string;
}

export const trackImpression = ({ category, contentType, label }: TrackImpressionArgs) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: category,
    event_label: label,
    event_name: 'view_content',
    content_type: contentType,
    membership_banner_location: 'product',
  });
};
