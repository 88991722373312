import { primarySemiBoldFont, theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const skeletonWrapper = css`
  display: grid;
  grid-template-columns: 205px 94px;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing.sm}px;
  margin: 0 0 ${spacing.sm}px 0;
`;

export const deliveryEventWrapper = css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0 0 ${spacing.sm}px 0;
`;

export const labelWrapper = css`
  height: fit-content;
  padding: 0 ${spacing.xs + 2}px;
  border-radius: 6px;
  background-color: var(--neutral-black-10);
`;

export const label = css`
  margin: 0;
  text-align: center;
  color: var(--functional-green-base);
  ${responsiveText.BODY.XS};
  ${primarySemiBoldFont};
`;
