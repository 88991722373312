export const COUNTRY_SELECT_LABEL = 'Choose delivery location';
export const UK_DELIVERY_ONLY = 'UK delivery only';
export const SELECTED_COUNTRIES_DELIVERY_ONLY = 'Delivery to selected countries only';
export const DELIVERY_NOT_AVAILABLE_TO_COUNTRY_FIELD_ERROR =
  'Delivery not available to this country';
export const DELIVERY_NOT_AVAILABLE_TO_COUNTRY_MESSAGE =
  'Sorry, delivery is not available for this country. Please try a different delivery destination';
export const FREE_DELIVERY = 'FREE';

export const UK_DROPDOWN_OPTIONS_LABEL = 'United Kingdom';
export const OTHER_COUNTRIES_DROPDOWN_OPTIONS_LABEL = 'Other countries';
export const TIMEZONE_MESSAGE = 'Times and dates shown are based on UK timezone.';
