import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type {
  DeliveryAndReturnsInfoDispatchProps,
  DeliveryAndReturnsInfoStateProps,
} from 'src/components/organisms/DeliveryAndReturnsInfo/components/DeliveryAndReturnsInfo';
import { DeliveryAndReturnsInfo } from 'src/components/organisms/DeliveryAndReturnsInfo/components/DeliveryAndReturnsInfo';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProduct } from 'src/redux/selectors';

const mapStateToProps = (state: ReduxApplicationState): DeliveryAndReturnsInfoStateProps => {
  const product = selectProduct(state);

  return {
    deliveryInfo: product.shippingInfo || undefined,
    returnsInfo: product.returnsInformation,
  };
};

const mapDispatchToProps = (): DeliveryAndReturnsInfoDispatchProps => {
  const baseEvent = {
    event: 'custom_event',
    event_category: 'Delivery & Returns',
    event_label: 'not set',
  };

  return {
    onReturnsPolicyLinkClick: () => {
      sendGAEvent({
        ...baseEvent,
        event_action: 'Returns policy click',
      });
    },
    onToggle: ({ open }) => {
      sendGAEvent({
        ...baseEvent,
        event_action: open ? 'Expand' : 'Collapse',
      });
    },
    onVisible: () => {
      sendGAEvent({
        ...baseEvent,
        event_action: 'Impression',
      });
    },
  };
};

export const DeliveryAndReturnsInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryAndReturnsInfo);
