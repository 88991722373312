import * as R from 'ramda';

const ELLIPSIS = '...';

type Pages = Array<typeof ELLIPSIS | number>;

const getExtraPage = (currentPage: number) => {
  if (currentPage < 3) {
    return 0;
  }

  return 1;
};

const getTotalPageNumbersToShow = (currentPage: number, pageNeighbours: number) => {
  if (currentPage === 3) {
    return pageNeighbours * 2 + 2;
  }
  return pageNeighbours * 2 + 3;
};

const getPagesWithStartAndEndPage = (totalPages: number) => (pages: Pages) =>
  [1, ...pages, totalPages];

export const getPages = (currentPage: number, totalPages: number) => {
  if (totalPages < 5) {
    return R.range(1, totalPages + 1);
  }

  const withStartAndEndPage = getPagesWithStartAndEndPage(totalPages);
  const extraPage = getExtraPage(currentPage);
  const totalNumbers = getTotalPageNumbersToShow(currentPage, extraPage);

  const startPage = Math.max(2, currentPage - extraPage);
  const endPage = Math.min(totalPages - 1, currentPage + extraPage);
  const initialPages: Pages = R.range(startPage, endPage + 1);

  const hasLeftSpill = startPage > 2;
  const hasRightSpill = totalPages - endPage > 1;
  const spillOffset = totalNumbers - (initialPages.length + 1);

  if (hasLeftSpill && !hasRightSpill) {
    const extraPages = R.range(startPage, startPage - 1);

    return withStartAndEndPage([ELLIPSIS, ...extraPages, ...initialPages]);
  } else if (!hasLeftSpill && hasRightSpill) {
    const extraPages = R.range(endPage + 1, endPage + spillOffset + 1);

    return withStartAndEndPage([...initialPages, ...extraPages, ELLIPSIS]);
  } else if (hasLeftSpill && hasRightSpill) {
    return withStartAndEndPage([ELLIPSIS, ...initialPages, ELLIPSIS]);
  }

  return withStartAndEndPage(initialPages);
};
