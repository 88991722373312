import type { DatePartsMap } from '@noths/polaris-client-utils';
import { formatDate, getOrdinalSuffix } from '@noths/polaris-client-utils';

export const getDateDifferenceInDays = (date1: Date, date2: Date) =>
  Math.floor(
    (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
      Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
      (1000 * 60 * 60 * 24),
  );

export const getFormattedDateString = (date: string) => {
  const { day, month, weekday } = formatDate(date, {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    formatToParts: true,
  }) as DatePartsMap;

  return `${weekday!.value} ${day.value}${getOrdinalSuffix(parseInt(day.value))} ${month.value}`;
};
