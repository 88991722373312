import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';

import { useSticky } from 'src/components/molecules/StickySection/hooks/useSticky/useSticky';
import * as styles from 'src/components/molecules/StickySection/styles';
import { LOWER_SCROLL_THRESHOLD_SELECTOR } from 'src/constants/sticky';

export interface Props {
  children: JSX.Element;
  triggerElementId: string;
}

export const STICKY_SECTION_CLASS = 'sticky-section';
export const STICKY_SECTION_HEIGHT = 80;

export const StickySection: FunctionComponent<Props> = ({ children, triggerElementId }: Props) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setElement(document.getElementById(triggerElementId));
  }, [triggerElementId]);

  const stickyNav = useSticky({
    nav: element,
    sectionHeight: STICKY_SECTION_HEIGHT,
    lowerScrollThresholdSelector: LOWER_SCROLL_THRESHOLD_SELECTOR,
  });

  return (
    <div
      className={STICKY_SECTION_CLASS}
      css={styles.stickySection}
      style={{
        height: STICKY_SECTION_HEIGHT,
        transform: `translateY(${stickyNav.translateY.toFixed(2)}px)`,
      }}
    >
      {children}
    </div>
  );
};
