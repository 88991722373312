/* istanbul ignore file */
import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

import { FADE_TRANSITION_MS } from 'src/components/organisms/ProductImagesCarousel/constants/transitions';
import type { ProductImagesState } from 'src/components/organisms/ProductImagesCarousel/types';

const UPPER_THRESHOLD = 0.9;
const LOWER_THRESHOLD = 0.6;

export type Callback = (entry: IntersectionObserverEntry) => void;
type ContainerReference = MutableRefObject<HTMLDivElement | null>;
type ItemReference = MutableRefObject<HTMLElement[]>;

// Hook to fire the given callback everytime an image comes into view on the carousel
const useSwipeIntersectionObserver = (
  callback: Callback,
  containerRef: ContainerReference,
  itemRefs: ItemReference,
  numberOfimages: number,
) => {
  const savedCallback = useRef<null | Callback>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (containerRef.current && numberOfimages === itemRefs.current.length) {
      const observer = new IntersectionObserver(
        (entries) =>
          entries.forEach((entry) => {
            if (
              entry.isIntersecting &&
              entry.intersectionRatio >= LOWER_THRESHOLD &&
              entry.intersectionRatio < UPPER_THRESHOLD
            ) {
              savedCallback.current!(entry);
            }
          }),
        {
          root: containerRef.current,
          threshold: LOWER_THRESHOLD,
        },
      );

      itemRefs.current.forEach((item) => observer.observe(item));

      return () => observer.disconnect();
    }
    return;
  }, [containerRef, itemRefs, numberOfimages]);
};

// Hook to fade & scroll in the next image if thumbnails or navigation arrows have been clicked
const useClickNavigationFadeTransition = (
  containerRef: ContainerReference,
  itemRefs: ItemReference,
  currentImage: ProductImagesState['currentImage'],
  navigatedBy: ProductImagesState['navigatedBy'],
) => {
  useEffect(() => {
    if (navigatedBy === 'click') {
      containerRef.current!.style.transition = `opacity ${FADE_TRANSITION_MS}ms`;
      containerRef.current!.style.opacity = '0';

      setTimeout(() => {
        if (itemRefs.current[currentImage]) {
          const offsetLeftCurrentImage = itemRefs.current[currentImage].offsetLeft;
          containerRef.current!.scrollLeft = offsetLeftCurrentImage;
          containerRef.current!.style.opacity = '1';
        }
      }, FADE_TRANSITION_MS);
    }
  }, [containerRef, currentImage, itemRefs, navigatedBy]);
};

export const useProductImagesCarousel = (
  callback: Callback,
  currentImage: ProductImagesState['currentImage'],
  navigatedBy: ProductImagesState['navigatedBy'],
  numberOfimages: number,
) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<HTMLElement[]>([]);

  useSwipeIntersectionObserver(callback, containerRef, itemRefs, numberOfimages);
  useClickNavigationFadeTransition(containerRef, itemRefs, currentImage, navigatedBy);

  return [containerRef, itemRefs] as const;
};
