import { createMiddlewareFromEventsMap } from '@noths/polaris-client-next-redux';

import { GAEventsMap as deliveryBlockGAEventsMap } from 'src/components/molecules/DeliveryBlock/modules/tracking/eventsMap';
import { GAEventsMap as favouritesGAEventsMap } from 'src/components/organisms/Favourites/tracking/eventsMap';
import { GAEventsMap as linkCarouselGAEventsMap } from 'src/components/organisms/LinkCarousel/tracking/eventsMap';
import { GAEventsMap as partnerStorefrontGAEventsMap } from 'src/components/organisms/PartnerStorefront/tracking/eventsMap';
import { GAEventsMap as productImagesCarouselGAEventsMap } from 'src/components/organisms/ProductImagesCarousel/tracking/eventsMap';
import { GAEventsMap as productReviewsGAEventsMap } from 'src/components/organisms/ProductReviews';
import { GAEventsMap as recommendedProductsShelfGAEventsMap } from 'src/components/organisms/RecommendedProductsShelf/tracking/eventsMap';
import { GAEventsMap as productInfoGAEventsMap } from 'src/redux/productInfo/eventsMap';
import { GAEventsMap as basketGAEventsMap } from './basket';
import type { ReduxApplicationState } from './combinedReducer';
import { GAEventsMap as deliveryZoneOptionsGAEventsMap } from './deliveryZoneOptions';
import { GAEventsMap as personalisationGAEventsMap } from './personalisation';

export const eventsMap = {
  ...basketGAEventsMap,
  ...deliveryBlockGAEventsMap,
  ...deliveryZoneOptionsGAEventsMap,
  ...favouritesGAEventsMap,
  ...linkCarouselGAEventsMap,
  ...partnerStorefrontGAEventsMap,
  ...personalisationGAEventsMap,
  ...personalisationGAEventsMap,
  ...productImagesCarouselGAEventsMap,
  ...productInfoGAEventsMap,
  ...productReviewsGAEventsMap,
  ...recommendedProductsShelfGAEventsMap,
};

export const GAMiddleware = createMiddlewareFromEventsMap<ReduxApplicationState>(eventsMap);
