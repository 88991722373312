import type { CSSProperties } from 'react';
import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

const TRANSITION = '400ms ease-in';

export interface DarkBackgroundColorCSSProperties extends CSSProperties {
  '--darkBackgroundColor': string;
}

export const darkBackgroundColor = `var(--neutral-black-10)`;

export const readMoreButtonText = css`
  z-index: 1;
  margin-left: ${pxToRem(spacing.xs)};
`;

export const expandingButton = css`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: ${stack(spacing.base)};
  border: none;
  text-decoration: underline;
  text-underline-offset: 3px;
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${fontSize(text.BODY.SM)};
  color: var(--neutral-black-base);
  background-color: var(--darkBackgroundColor, var(--neutral-white-base));

  &:before {
    position: absolute;
    content: '';
    pointer-events: none;
    top: -${pxToRem(text.BODY.MD.lineHeight)};
    left: 0;
    width: 100%;
    height: ${pxToRem(text.BODY.MD.lineHeight)};
    background-image: linear-gradient(
      to bottom,
      transparent,
      var(--darkBackgroundColor, var(--neutral-white-base)) 60%,
      var(--darkBackgroundColor, var(--neutral-white-base)) 100%
    );
    transition: height ${TRANSITION};
    transition: opacity ${TRANSITION};
  }

  &[aria-expanded='true'] {
    padding: ${pxToRem(spacing.base)} 0;

    &:before {
      opacity: 0;
      height: 0;
    }
  }
`;
