import React from 'react';
import { Breakpoint, useBreakpoint } from '@noths/polaris-client-ribbons-base';
import type { ModalChildrenProps } from '@noths/polaris-client-ribbons-design-system';
import {
  HyperButtonLink,
  IconCheckmark,
  Modal,
  OutlineButton,
  SolidButtonLink,
} from '@noths/polaris-client-ribbons-design-system';
import { visuallyHidden } from '@noths/polaris-client-styles';
import { getProductImageURL, priceFormatter } from '@noths/polaris-client-utils';

import { useAddToBasketPlacement } from 'src/components/hooks/useAddToBasketPlacement';
import { PriceBreakdownContainer } from 'src/components/molecules/PriceBreakdown/PriceBreakdownContainer';
import { RecommendedProductsShelfContainer } from 'src/components/organisms/RecommendedProductsShelf/containers/RecommendedProductsShelfContainer';
import { BASKET_PAGE_PATH } from 'src/constants/page';
import { Placement } from 'src/constants/recommendations';
import type { BasketState } from 'src/redux/basket';
import type { ProductState } from 'src/redux/product';
import {
  BASKET_MODAL_BUTTON_CONTINUE,
  BASKET_MODAL_BUTTON_CTA,
  BASKET_MODAL_CONTENT_LABEL,
  BASKET_MODAL_HEADER,
  BASKET_MODAL_HEADER_EDIT,
  BASKET_MODAL_ITEMS,
  BASKET_MODAL_QUANTITY,
  BASKET_MODAL_TOTAL,
} from '../copy';
import * as styles from './BasketModal.styles';

export interface BasketModalStateProps {
  basket: BasketState['basket'];
  editMode: boolean;
  open: boolean;
  product: ProductState;
  recommendationsSessionID?: string;
  showRecommendations: boolean;
}

export interface BasketModalDispatchProps {
  onClose: VoidFunction;
  onNavigateToBasket: VoidFunction;
}

export type BasketModalProps = BasketModalDispatchProps & BasketModalStateProps;

const BasketInfoAndCTAs = ({
  basket,
  onClose,
  onNavigateToBasket,
}: Pick<BasketModalProps, 'basket' | 'onNavigateToBasket' | 'onClose'>) => {
  return (
    <>
      <div css={styles.info}>
        {basket?.itemsTotal && (
          <p css={styles.infoTotal}>
            {`${BASKET_MODAL_TOTAL}: ${priceFormatter(
              basket.itemsTotal.currency,
              basket.itemsTotal.cents,
            )}`}
          </p>
        )}
        {Boolean(basket?.itemsCount) && (
          <p css={styles.infoItems}>
            {`${basket!.itemsCount} ${BASKET_MODAL_ITEMS}`}
            <span css={visuallyHidden}> in the basket</span>
          </p>
        )}
      </div>
      <div css={styles.buttonContainer}>
        <OutlineButton fullWidth onClick={onClose}>
          {BASKET_MODAL_BUTTON_CONTINUE}
        </OutlineButton>
        <SolidButtonLink
          css={styles.goToBasketButton}
          fullWidth
          href={BASKET_PAGE_PATH}
          onClick={(e) => {
            e.preventDefault();
            onNavigateToBasket();
          }}
          role="button"
        >
          {BASKET_MODAL_BUTTON_CTA}
        </SolidButtonLink>
      </div>
    </>
  );
};

export const BasketModal = ({
  basket,
  editMode,
  onClose,
  onNavigateToBasket,
  open,
  product: { code, images, partner, quantity, title },
  recommendationsSessionID,
  showRecommendations,
}: BasketModalProps) => {
  const isOnSmallScreen = useBreakpoint({ initializeWithLarge: true }) === Breakpoint.S;

  useAddToBasketPlacement(recommendationsSessionID, code, open);

  return (
    <>
      <Modal
        appRootSelector="#NOTHSPageWrapper"
        contentLabel={BASKET_MODAL_CONTENT_LABEL}
        isModalOpen={open}
        onModalClose={onClose}
      >
        {({ Body, Footer, Header }: ModalChildrenProps) => (
          <>
            <Header notificationStatus="success" onCloseButtonClick={onClose}>
              <div css={[styles.section, styles.headerSection]}>
                <IconCheckmark height={24} width={24} />
                <h2 css={styles.header}>
                  {editMode ? BASKET_MODAL_HEADER_EDIT : BASKET_MODAL_HEADER}
                </h2>
              </div>
            </Header>
            <Body>
              <div>
                <div css={[styles.section, styles.productSection]}>
                  <div css={styles.partnerInfo}>
                    <img
                      alt=""
                      css={styles.partnerImage}
                      height={135}
                      src={getProductImageURL(images[0].href, 'mini')}
                      width={135}
                    />
                    <div>
                      <HyperButtonLink href={partner.links?.[1]?.rel || ''}>
                        {partner.name}
                      </HyperButtonLink>
                      <p css={styles.productDescription}>{title}</p>
                      <p css={styles.productQuantity}>{`${BASKET_MODAL_QUANTITY}: ${quantity}`}</p>
                    </div>
                  </div>
                  <div css={styles.breakdownWrapper}>
                    <PriceBreakdownContainer expandablePersonalisations showTotalDivider />
                  </div>
                </div>
                <div css={[styles.section, styles.buttonSection]}>
                  {!isOnSmallScreen && (
                    <BasketInfoAndCTAs
                      basket={basket}
                      onClose={onClose}
                      onNavigateToBasket={onNavigateToBasket}
                    />
                  )}
                </div>
                {showRecommendations && (
                  <div css={styles.carouselContainer}>
                    <div css={styles.carouselSection}>
                      <RecommendedProductsShelfContainer
                        enableFreeDomesticDeliveryLabels={true}
                        hideLabelIcons={true}
                        placementType={Placement.AddToBasket}
                        size="small"
                      />
                    </div>
                  </div>
                )}
              </div>
            </Body>
            {isOnSmallScreen && (
              <Footer>
                <div css={styles.footerItems}>
                  <BasketInfoAndCTAs
                    basket={basket}
                    onClose={onClose}
                    onNavigateToBasket={onNavigateToBasket}
                  />
                </div>
              </Footer>
            )}
          </>
        )}
      </Modal>
      <styles.BasketModalGlobalStyles />
    </>
  );
};
