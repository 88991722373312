import React from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';

import { FileLink } from 'src/components/atoms/FileLink/FileLink';
import * as copy from 'src/components/organisms/ProductDescription/constants/copy';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { removeHtmlElementsFromString } from 'src/utils/removeHtmlElementFromString';
import * as styles from './ProductDescription.style';

export interface ProductDescriptionStateProps {
  product: CatServiceAPIProduct;
}

export interface ProductDescriptionDispatchProps {
  onVisible: () => void;
}

type ProductDescriptionProps = ProductDescriptionStateProps & ProductDescriptionDispatchProps;

interface SectionProps {
  heading: string;
  html: string;
}

const Section = ({ heading, html }: SectionProps) => {
  return (
    <>
      <h2 css={styles.heading}>{heading}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: removeHtmlElementsFromString(html, ['b', 'strong']),
        }}
      />
    </>
  );
};

export const ProductDescription = ({
  onVisible,
  product: {
    code,
    description,
    dimensions,
    ingredients,
    materials,
    otherInformation,
    variationsAvailable,
  },
}: ProductDescriptionProps) => {
  const rootRef = useImpressionRef<HTMLDivElement>(() => onVisible());

  return (
    <div css={styles.wrapper} ref={rootRef}>
      <div css={styles.content}>
        <Section heading={copy.PRODUCT_DESCRIPTION_HEADING} html={description + otherInformation} />
        {variationsAvailable && (
          <Section heading={copy.PRODUCT_VARIATIONS_HEADING} html={variationsAvailable} />
        )}
      </div>
      <div css={styles.content}>
        {materials && <Section heading={copy.MADE_FROM_HEADING} html={materials} />}
        {dimensions && <Section heading={copy.PRODUCT_DIMENTIONS_HEADING} html={dimensions} />}
        {ingredients && (
          <div css={styles.ingredientsInfo}>
            <FileLink {...ingredients} title={copy.INGREDIENTS_TITLE} />
          </div>
        )}
        <h2 css={styles.heading}>{copy.PRODUCT_CODE_HEADING}</h2>
        <p>{code}</p>
      </div>
    </div>
  );
};
