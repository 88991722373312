import React from 'react';
import { NotFound } from '@noths/polaris-client-next-components';
import { isBrowser } from '@noths/polaris-client-utils';
import type { SerializedError } from '@reduxjs/toolkit';

import { ERROR_HEADING } from './copy';
import * as styles from './styles';

interface ErrorGuardProps {
  children: React.ReactNode;
  error?: SerializedError | null;
  notFoundErrorList?: string[];
}

const ServiceError = () => (
  <>
    {isBrowser() ? (
      <h2 css={styles.heading}>{ERROR_HEADING}</h2>
    ) : (
      <h1 css={styles.heading}>{ERROR_HEADING}</h1>
    )}
  </>
);

export const ErrorGuard = ({ children, error, notFoundErrorList }: ErrorGuardProps) =>
  error ? (
    <div css={styles.errorGuard}>
      {notFoundErrorList?.includes(error!.name!) ? <NotFound /> : <ServiceError />}
    </div>
  ) : (
    <>{children}</>
  );
