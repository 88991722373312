import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

export const useErrorNotificationFocus = (
  isVisible: boolean,
  focusRef: MutableRefObject<HTMLElement | null>,
  textBoxRef: MutableRefObject<HTMLDivElement | null>,
) => {
  const [preventInitialFocus, setPreventInitialFocus] = useState(true);

  useEffect(() => {
    if (preventInitialFocus) {
      setPreventInitialFocus(false);
      return;
    }

    if (isVisible) {
      textBoxRef.current?.querySelector('button')?.focus();
    } else {
      (focusRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement)?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, focusRef, textBoxRef]);
};
