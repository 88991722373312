import type { ChangeEvent } from 'react';
import React, { useEffect, useRef } from 'react';
import type { DeliveryZoneCode } from '@noths/polaris-client-localisation';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';
import { DropDown, IconInfoCircular } from '@noths/polaris-client-ribbons-design-system';

import {
  COUNTRY_SELECT_LABEL,
  DELIVERY_NOT_AVAILABLE_TO_COUNTRY_FIELD_ERROR,
  DELIVERY_NOT_AVAILABLE_TO_COUNTRY_MESSAGE,
  TIMEZONE_MESSAGE,
} from 'src/components/molecules/DeliveryBlock/constants/copy';
import type { GroupedCountries } from 'src/components/molecules/DeliveryBlock/modules/selectors';
import * as styles from 'src/components/molecules/DeliveryBlock/styles';
import type { CatServiceAPIDeliveryOption } from 'src/services/cat-service-api/types/CatServiceAPIDeliveryOption';
import type { DeliveryOptionInfo } from 'src/types/deliveryZoneOptions';
import { DeliveryOption } from './DeliveryOption';
import { DeliveryOptionSkeleton } from './DeliveryOptionSkeleton';
import { PhotoPersonalisationMessage } from './PhotoPersonalisationMessage';

export enum DeliveryBlockContextID {
  DeliveryArea = 'delivery-area',
  DeliveryOptions = 'delivery-options',
}

export interface DeliveryBlockOnCountryChangeArgs {
  contextID: DeliveryBlockContextID;
  selectedCountry: string;
  selectedZone: DeliveryZoneCode;
}

export interface DeliveryBlockStateProps {
  countryInfoMessage: string | null;
  deliveryOptions: DeliveryOptionInfo[];
  deliveryOptionsPlaceholderItems: CatServiceAPIDeliveryOption[];
  groupedCountries: GroupedCountries;
  isLoading: boolean;
  photoUploadRequired: boolean;
  selectedCountry: string;
}

export interface DeliveryBlockDispatchProps {
  onCountryChange: (payload: DeliveryBlockOnCountryChangeArgs) => void;
  onVisible: () => void;
}

interface DeliveryBlockOwnProps {
  contextID: DeliveryBlockContextID;
}

type DeliveryBlockProps = DeliveryBlockStateProps &
  DeliveryBlockDispatchProps &
  DeliveryBlockOwnProps;

export const DeliveryBlock = ({
  contextID,
  countryInfoMessage,
  deliveryOptions,
  deliveryOptionsPlaceholderItems,
  groupedCountries,
  isLoading,
  onCountryChange,
  onVisible,
  photoUploadRequired,
  selectedCountry,
}: DeliveryBlockProps) => {
  const deliveryBlockRef = useImpressionRef<HTMLDivElement>(() => onVisible());
  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const [countryCode, deliveryZone] = e.target.value.split('|') as [string, DeliveryZoneCode];

    onCountryChange({
      contextID,
      selectedCountry: countryCode,
      selectedZone: deliveryZone,
    });
  };

  const isDeliveryAvailable = Boolean(deliveryOptionsPlaceholderItems.length);
  const showTimezoneMessage = useRef(false);
  const selectedDeliveryCountry = Object.values(groupedCountries)
    .flat()
    .find((item) => item.optionValue === selectedCountry)?.optionLabel;

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    showTimezoneMessage.current = userTimeZone !== 'Europe/London';
  }, []);

  return (
    <div css={styles.deliveryBlock} ref={deliveryBlockRef}>
      <p css={styles.deliveryInfo}>Estimates for {selectedDeliveryCountry} delivery</p>
      {isDeliveryAvailable ? (
        isLoading ? (
          <div css={styles.deliveryOptionsList}>
            {deliveryOptionsPlaceholderItems.map(({ service: { code } }) => (
              <DeliveryOptionSkeleton key={`skeleton-${code}`} />
            ))}
          </div>
        ) : (
          <ul css={[styles.deliveryOptionsList, styles.fadeIn]}>
            {deliveryOptions.map(
              ({ date, exactDate, max, min, optionCode, price, providerName }) => {
                const provider = providerName !== 'Other Provider' ? providerName : undefined;

                return (
                  <DeliveryOption
                    currency={price.currencyCode}
                    date={date && exactDate ? date : undefined}
                    key={optionCode}
                    max={max || undefined}
                    min={min || undefined}
                    optionCode={optionCode}
                    price={Number(price.amount)}
                    provider={provider}
                  />
                );
              },
            )}
          </ul>
        )
      ) : (
        <p css={styles.deliveryNotAvailableMessage}>{DELIVERY_NOT_AVAILABLE_TO_COUNTRY_MESSAGE}</p>
      )}

      <hr />
      <DropDown
        css={styles.countrySelect}
        disabled={isLoading}
        error={isDeliveryAvailable ? null : DELIVERY_NOT_AVAILABLE_TO_COUNTRY_FIELD_ERROR}
        id={`${contextID}delivery-block-countries`}
        label={COUNTRY_SELECT_LABEL}
        onChange={handleCountryChange}
        options={groupedCountries}
        value={selectedCountry}
      />
      {countryInfoMessage && (
        <div css={styles.countryInfoWrapper}>
          <IconInfoCircular height={16} width={16} />
          <p css={styles.countryInfoMessage}>
            {countryInfoMessage}
            {showTimezoneMessage.current && `. ${TIMEZONE_MESSAGE}`}
          </p>
        </div>
      )}
      <hr />

      {photoUploadRequired && <PhotoPersonalisationMessage />}
    </div>
  );
};
