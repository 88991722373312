import { connect } from 'react-redux';
import { updateFavourite } from '@noths/polaris-client-user-favourites';
import getConfig from 'next/config';

import { ProductImagesCarousel } from 'src/components/organisms/ProductImagesCarousel/components/ProductImagesCarousel';
import { actions } from 'src/components/organisms/ProductImagesCarousel/modules/slice';
import type {
  NextOrPreviousPayload,
  OpenModalPayload,
  SetCurrentImagePayload,
} from 'src/components/organisms/ProductImagesCarousel/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectFavouriteProduct, selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import type { NextConfig } from 'src/types/nextConfig';

const nextConfig = getConfig() as NextConfig;

const mapStateToProps = (state: ReduxApplicationState) => ({
  product: selectProduct(state),
  currentImage: state.productImagesCarousel.currentImage,
  navigatedBy: state.productImagesCarousel.navigatedBy,
  isModalOpen: state.productImagesCarousel.isModalOpen,
  favouriteProduct: selectFavouriteProduct(state),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  setCurrentImage: (payload: SetCurrentImagePayload) => dispatch(actions.setCurrentImage(payload)),
  onOpenModal: (payload: OpenModalPayload) => dispatch(actions.openModal(payload)),
  onCloseModal: () => dispatch(actions.closeModal()),
  onNextImage: (payload: NextOrPreviousPayload) => dispatch(actions.nextImage(payload)),
  onPrevImage: (payload: NextOrPreviousPayload) => dispatch(actions.prevImage(payload)),
  dispatchOnUpdateFavourite: (productCode: number) =>
    dispatch(updateFavourite({ productCode, nextConfig })),
});

const mergeProps = (
  propsFromState: ReturnType<typeof mapStateToProps>,
  propsFromDispatch: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...propsFromState,
  ...propsFromDispatch,
  productIsFavourited: propsFromState.favouriteProduct.isFavourited,
  onUpdateFavourite: () =>
    propsFromDispatch.dispatchOnUpdateFavourite(propsFromState.favouriteProduct.code),
});

export const ProductImagesCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ProductImagesCarousel);
