import React from 'react';
import { Image } from '@noths/polaris-client-ribbons-design-system';
import { getProductImageURL } from '@noths/polaris-client-utils';

import { IMAGES_ID_PREFIX } from '.';
import {
  PRODUCT_IMAGES_TAB_ID_PREFIX,
  PRODUCT_IMAGES_TABPANEL_ID_PREFIX,
} from 'src/components/organisms/ProductImagesCarousel/constants/elementIds';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/ProductImageModal.styles';
import { DecorateWithZoomAndPanWrapper } from './DecorateWithZoomAndPanWrapper';

interface ThumbnailImage {
  href: string;
  title: string;
}

export interface ProductImagesModalProps {
  currentImage: number;
  images: ThumbnailImage[];
  loadedImages: Record<number, boolean>;
  multipleImagesToDisplay: boolean;
  setImageLoaded: (value: React.SetStateAction<Record<number, boolean>>) => void;
}

export const ProductImagesModal = ({
  currentImage,
  images,
  loadedImages,
  multipleImagesToDisplay,
  setImageLoaded,
}: ProductImagesModalProps) => (
  <>
    {images.map(({ href: src, title }, i) => (
      <div
        aria-labelledby={`${IMAGES_ID_PREFIX}${PRODUCT_IMAGES_TAB_ID_PREFIX}${i}`}
        css={styles.tabpanel}
        id={`${IMAGES_ID_PREFIX}${PRODUCT_IMAGES_TABPANEL_ID_PREFIX}${i}`}
        key={src}
        role="tabpanel"
        style={i === currentImage ? {} : { display: 'none' }}
      >
        <DecorateWithZoomAndPanWrapper
          enabled={true}
          loadingSpinner={!loadedImages[i]}
          shouldDisplay={currentImage === i}
        >
          <Image
            alt={`${title}${multipleImagesToDisplay ? `, ${`${i + 1} of ${images.length}`}` : ''}`}
            css={styles.fullScreenImage}
            onLoad={() => setImageLoaded({ ...loadedImages, [i]: true })}
            src={getProductImageURL(src, 'original')}
          />
        </DecorateWithZoomAndPanWrapper>
      </div>
    ))}
  </>
);
