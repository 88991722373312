import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const madeToOrder = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  ${fontSize(text.BODY.XS)}
  margin: 0 0 ${spacing.sm}px;

  [data-testid='tooltip-container'] {
    position: static;
  }

  [data-testid='content-wrapper'] {
    max-width: 243px;
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      [data-testid='content-wrapper'] {
        max-width: none;
      }
    `,
  )};
`;

export const madeToOrderLabel = css`
  ${fontSize(text.BODY.XS)}
  margin: 0;
`;

export const tooltipInfo = css`
  cursor: help;
  display: grid;
  grid-template-columns: 1fr ${spacing.md}px;
  place-items: center;
  font-size: ${pxToRem(text.BODY.XS.fontSize)};
`;

export const tooltipInfoIcon = css`
  display: block;
  height: ${spacing.md}px;
  width: ${spacing.md}px;
  margin: 0 0 0 ${spacing.base}px;
`;
