import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { OnVisiblePayload } from 'src/components/organisms/ProductImagesCarousel/components/Thumbnails';
import { Thumbnails } from 'src/components/organisms/ProductImagesCarousel/components/Thumbnails';
import { actions } from 'src/components/organisms/ProductImagesCarousel/modules/slice';
import type { NextOrPreviousPayload } from 'src/components/organisms/ProductImagesCarousel/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';

const mapStateToProps = (state: ReduxApplicationState) => ({
  images: selectProduct(state).images,
  currentImage: state.productImagesCarousel.currentImage,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    onVisible: ({ totalImages }: OnVisiblePayload) => {
      sendGAEvent({
        event: 'custom_event',
        event_category: 'Image carousel',
        event_action: 'Impression',
        event_label: String(totalImages),
      });
    },
    onNextImage: (payload: NextOrPreviousPayload) => {
      dispatch(actions.nextImage(payload));
    },
    onPrevImage: (payload: NextOrPreviousPayload) => {
      dispatch(actions.prevImage(payload));
    },
  };
};

export const ThumbnailsContainer = connect(mapStateToProps, mapDispatchToProps)(Thumbnails);
