import React from 'react';
import { SkeletonLabel } from '@noths/polaris-client-ribbons-design-system';
import { priceFormatter } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import * as styles from 'src/components/molecules/DeliveryEvent/components/DeliveryEvent.styles';

export const DELIVERY_EVENT_TEST_ID = 'delivery-event-label';

export interface DeliveryEventProps {
  cheapestDeliveryPrice: number | null;
  currency: CurrencyCode;
  eventName?: string;
  loading: boolean;
}

export const DeliveryEvent = ({
  cheapestDeliveryPrice,
  currency,
  eventName,
  loading,
}: DeliveryEventProps) => {
  if (!eventName || cheapestDeliveryPrice === null) {
    return null;
  }

  if (loading) {
    return (
      <div css={styles.skeletonWrapper}>
        <SkeletonLabel />
      </div>
    );
  }

  const price =
    cheapestDeliveryPrice === 0 ? 'free' : priceFormatter(currency, cheapestDeliveryPrice, false);

  return (
    <div css={styles.deliveryEventWrapper} data-testid={DELIVERY_EVENT_TEST_ID}>
      <div css={styles.labelWrapper}>
        <p css={styles.label}>
          {eventName} {price}
        </p>
      </div>
    </div>
  );
};
