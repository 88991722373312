/* istanbul ignore file */

import React from 'react';
import { Provider } from 'react-redux';
import { setLocaleCookies } from '@noths/polaris-client-localisation';
import type { BaseAppProps } from '@noths/polaris-client-next-components';
import { BaseApp } from '@noths/polaris-client-next-components';
import { RECOMMENDATIONS_SCRIPT_URL } from '@noths/polaris-client-recommendations';
import { SkipLinks, useSmartAppBanner } from '@noths/polaris-client-site-chrome-ui';
import { FavouritesContainer } from '@noths/polaris-client-user-favourites';
import type { Merge } from '@noths/polaris-dev-ts-recipes';
import type { AppContextPlusCookies } from '@noths/polaris-dev-ts-types';
import type { Store } from '@reduxjs/toolkit';
import { ConnectedRouter } from 'connected-next-router';
import type { AppInitialProps, AppProps } from 'next/app';
import NextApp from 'next/app';
import getConfig from 'next/config';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { useStore } from 'src/redux/store';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
import { getFeatureFlagsWithMatchers } from 'src/utils/getFeatureFlagsWithMatchers';
import { CONTENT_SECTION_ID } from './[partnerId]/product/[productId]';

const nextConfig = getConfig() as NextConfig;
const AppComponent = BaseApp(nextConfig);

type CustomAppProps = Merge<
  BaseAppProps & AppProps,
  {
    mvtAssignmentsHeader?: string;
    pageProps: PageProps;
  }
>;

const App = ({ experiments, ...props }: CustomAppProps) => {
  const { externalReferrer, topBannerData, updatedReduxState } = props.pageProps || {};
  const store = useStore(props, updatedReduxState as ReduxApplicationState);

  useSmartAppBanner({ hide: externalReferrer });

  return (
    <>
      <script src={RECOMMENDATIONS_SCRIPT_URL} />
      <SkipLinks links={[{ content: 'Skip to content', sectionId: CONTENT_SECTION_ID }]} />
      <Provider store={store as Store}>
        <ConnectedRouter>
          <FavouritesContainer nextConfig={nextConfig} />
          <AppComponent
            {...props}
            experiments={experiments}
            siteChromeCompactMode={externalReferrer}
            topBannerData={topBannerData}
          />
        </ConnectedRouter>
      </Provider>
    </>
  );
};

/*
  Necessary to disable next's Automatic Static Optimization feature.
  Without it this page will be statically compiled.
*/
App.getInitialProps = async (
  appContext: AppContextPlusCookies,
): Promise<AppInitialProps & BaseAppProps> => {
  const {
    ctx: { query, req, res },
  } = appContext;
  const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};

  if (req && res) {
    setLocaleCookies({ req, res, query });
  }

  const appProps = await NextApp.getInitialProps(appContext);

  return { ...appProps, featureFlags };
};

export default App;
