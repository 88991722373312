import { connect } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';

import type {
  DispatchProps,
  OwnProps,
} from 'src/components/organisms/Personalisation/components/SelectionSingleOrBinary';
import { SelectionSingleOrBinary } from 'src/components/organisms/Personalisation/components/SelectionSingleOrBinary';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
  SelectionPayload,
} from 'src/redux/personalisation';
import {
  actions as personalisationActions,
  selectNumberOfRequiredPersonalisations,
  selectOutstandingPersonalisations,
  selectSelectedOption,
  selectTrackedEvents,
} from 'src/redux/personalisation';
import { selectPersonalisationOptions } from 'src/redux/selectors';

const mapStateToProps = (state: ReduxApplicationState, props: OwnProps) => ({
  currency: state.product.price.currency,
  error:
    Boolean(props.displayValidation) &&
    selectOutstandingPersonalisations(state).some((opt) => opt.id === props.id),
  options: selectPersonalisationOptions(state),
  outstandingSelections: selectOutstandingPersonalisations(state),
  selectedOption: selectSelectedOption(state, props.id),
  totalOutstandingSelections: selectOutstandingPersonalisations(state).length,
  totalRequiredPersonalisations: selectNumberOfRequiredPersonalisations(state),
  trackedEvents: selectTrackedEvents(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  trackInteraction: (payload: InteractionTrackingEvent) =>
    dispatch(personalisationActions.trackInteraction(payload)),
  trackProgressMilestone: (payload: ProgressTrackingEvent) =>
    dispatch(personalisationActions.trackProgressMilestone(payload)),
  trackInteractionError: (payload: InteractionErrorTrackingEvent) =>
    dispatch(personalisationActions.trackInteractionError(payload)),
  toggleSelectPersonalisation: (payload: SelectionPayload) =>
    dispatch(personalisationActions.toggleSelectPersonalisation(payload)),
});

export const SelectionSingleOrBinaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectionSingleOrBinary);
