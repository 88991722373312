import type React from 'react';
import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

export const trackPartnerLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: 'Partner storefront click',
    event_category: 'Product Page',
    event_label: 'Top link',
    destination_URL: e.currentTarget.getAttribute('href'),
  });
};
