import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface ErrorNotificationWrapper {
  children: JSX.Element | boolean;
}

export const ErrorNotificationWrapper = ({ children }: ErrorNotificationWrapper) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  const nothsPageWrapper = document.querySelector('#NOTHSPageWrapper');

  return isClient && nothsPageWrapper && createPortal(children, nothsPageWrapper);
};
