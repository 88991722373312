import React from 'react';
import { getProductIdFromProductCode } from '@noths/polaris-client-utils';

/**
 * @description The commerce team use a bookmarklet that relies on the element ID and data attribute
 * that this component renders. See the following doc for the bookmarklet code:
 * https://docs.google.com/document/d/1Y6xm-UA6n1SeDVkFQqaoa3KOM65S33rlHmPYmAI88-A/edit?tab=t.0
 */
export const SupportShowInCMSBookmarklet = ({ productCode }: { productCode: number }) => {
  const productId = getProductIdFromProductCode(productCode);

  return <span data-product-id={productId} id="product" style={{ visibility: 'hidden' }} />;
};
