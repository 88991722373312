import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { ADD_ITEM, UPDATE_ITEM } from './graphql/mutations';
import type {
  BasketAPIAddItemMutationResponseBody,
  BasketAPIUpdateItemMutationResponseBody,
} from './types/BasketAPIResponseBody';
import type { ProductOptionInputWithoutLabel } from './types/graphql';
import { graphqlRequest } from './utils/graphqlRequest';

export interface AddItemMutationVariables {
  code: string;
  currencyCode: CurrencyCode;
  options: ProductOptionInputWithoutLabel;
  quantity: number;
}

export const addItem = async (
  productCode: number,
  quantity: number,
  currencyCode: CurrencyCode = 'GBP',
  options: ProductOptionInputWithoutLabel,
) => {
  const response = await graphqlRequest<
    BasketAPIAddItemMutationResponseBody,
    AddItemMutationVariables
  >(ADD_ITEM, {
    code: String(productCode),
    currencyCode,
    quantity,
    options,
  });

  if (!response.data.addItem.success) {
    const errorMessages = response.data.addItem.errors.map((error) => error.message);

    throw new Error(`the addItem basket-api mutation failed: ${errorMessages.join(', ')}`);
  }

  return response.data.addItem;
};

interface UpdateItemMutationVariables {
  currencyCode: CurrencyCode;
  id: string;
  options: ProductOptionInputWithoutLabel;
  quantity: number;
}

export const updateItem = async (
  id: string,
  quantity: number,
  currencyCode: CurrencyCode = 'GBP',
  options: ProductOptionInputWithoutLabel,
) => {
  const response = await graphqlRequest<
    BasketAPIUpdateItemMutationResponseBody,
    UpdateItemMutationVariables
  >(UPDATE_ITEM, {
    id,
    quantity,
    currencyCode,
    options,
  });

  if (!response.data.updateItem.success) {
    const errorMessages = response.data.updateItem.errors.map((error) => error.message);

    throw new Error(`the updateItem basket-api mutation failed: ${errorMessages.join(', ')}`);
  }

  return response.data.updateItem;
};
