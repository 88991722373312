import {
  fontSize,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery, mediumElevation, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

const PARTNER_IMAGE_OFFSET = 36;

export const storefrontGrid = css`
  row-gap: ${pxToRem(48)};

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      min-height: ${pxToRem(740)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      height: ${pxToRem(516)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      height: ${pxToRem(644)};
    `,
  )}
`;

export const partner = css`
  display: grid;
  place-items: center;
  grid-column: 1 / -1;
  min-width: 0;
  width: 100%;
  margin: 0;
  text-align: center;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: 1 / span 4;
      margin: 0;
      width: 100%;

      &[data-is-multi-row='true'],
        margin-top: ${pxToRem(spacing.xxxxl + spacing.sm)};
      }
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / span 2;
    `,
  )}
`;

export const visitShopButton1 = css`
  display: none;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      display: block;
    `,
  )}
`;

export const visitShopButton2 = css`
  padding-bottom: ${pxToRem(60)};
  grid-column: 1 / -1;
  text-align: center;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      display: none;
      padding-bottom: ${pxToRem(spacing.xxl)};
    `,
  )}
`;

export const products = css`
  display: grid;
  margin-bottom: auto;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${pxToRem(spacing.sm)};
  row-gap: ${pxToRem(spacing.xxl)};
  grid-column: 1 / -1;
  place-items: flex-start center;
  justify-content: center;
  margin-top: auto;

  &[data-is-single-product='true'] {
    grid-template-columns: max-content;
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: 5 / span 8;
      column-gap: ${pxToRem(spacing.lg)};

      &[data-is-multi-row='true'] {
        margin-top: ${pxToRem(-PARTNER_IMAGE_OFFSET)};
        margin-bottom: ${pxToRem(-spacing.xxxxl * 2 + 4)};
      }
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 6 / span 6;
      column-gap: ${pxToRem(spacing.xxl)};

      &[data-is-single-product='true'] {
        grid-column: 6 / span 5;
      }

      &[data-is-multi-row='true'] {
        margin-top: ${pxToRem(-spacing.xxxxl)};
        margin-bottom: ${pxToRem(-spacing.xxxxl * 2)};
      }
    `,
  )};
`;

export const relatedProductWrapper = css`
  position: relative;
  grid-column: span 1;
  max-width: ${pxToRem(158)};

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      max-width: ${pxToRem(232)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      max-width: ${pxToRem(328)};
    `,
  )}
`;

export const productHeading = css`
  display: grid;
  margin: 0;
`;

export const imageLink = css`
  text-decoration: none;
  color: inherit;
`;

export const skeletonLoaderWrapper = css`
  ${relatedProductWrapper};

  ${mediaQuery(
    { min: minBreakpoints.XXS, max: maxBreakpoints.S },
    css`
      margin-top: ${spacing.xxxxl - 3};
    `,
  )}
`;

export const imageWrapper = css`
  height: ${pxToRem(156)};
  width: ${pxToRem(158)};
  border-radius: ${pxToRem(10)};
  position: relative;
  overflow: hidden;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      height: ${pxToRem(232)};
      width: ${pxToRem(232)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      height: ${pxToRem(328)};
      width: ${pxToRem(330)};
    `,
  )}

  ${mediumElevation}
`;

export const image = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
  position: absolute;
`;

export const productLabel = css`
  position: absolute;
  top: ${pxToRem(15)};
  left: 0;
  padding: 0 ${pxToRem(spacing.xs)};
  ${fontSize(text.BODY.XS)};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
`;

export const productTitle = css`
  grid-row: 2;
  margin-bottom: ${pxToRem(spacing.base)};
  ${fontSize(text.BODY.SM)};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
`;

export const productPrices = css`
  display: flex;
  grid-row: 3;
`;

export const productPrice = css`
  margin-top: 0;
  margin-bottom: 0;
  ${fontSize(text.BODY.SM)};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
`;

export const productPreviousPrice = css`
  ${productPrice};
  margin-left: ${pxToRem(spacing.xs)};
`;
