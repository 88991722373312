import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { addItemToBasket, updateItemInBasket } from 'src/redux/basket';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { generateProductFieldObject } from 'src/utils/googleAnalytics';
import type {
  AddToBasketErrorEvent,
  AddToBasketEvent,
  AddToBasketInitialEvent,
  UpdateBasketItemErrorEvent,
} from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [addItemToBasket.fulfilled.type]: (state) => {
    sendGAEvent<AddToBasketInitialEvent>({
      ecommerce: null,
    });

    sendGAEvent<AddToBasketEvent>({
      event: 'add_to_cart',
      event_action: 'Add to basket',
      event_category: 'Product page',
      event_label: null,
      ecommerce: {
        add: {
          products: [generateProductFieldObject(state)],
        },
      },
    });
  },
  [addItemToBasket.rejected.type]: (_, __, action) => {
    const errorMessage =
      (action as ReturnType<typeof addItemToBasket.rejected>).error.message || 'not set';

    sendGAEvent<AddToBasketErrorEvent>({
      event: 'custom_event',
      event_action: 'Add to Cart Error',
      event_category: 'Product page',
      event_label: errorMessage,
    });
  },
  [updateItemInBasket.rejected.type]: (_, __, action) => {
    const errorMessage =
      (action as ReturnType<typeof updateItemInBasket.rejected>).error.message || 'not set';

    sendGAEvent<UpdateBasketItemErrorEvent>({
      event: 'custom_event',
      event_action: 'Edit Cart Error',
      event_category: 'Product page',
      event_label: errorMessage,
    });
  },
};
