import React from 'react';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import { StarRating } from 'src/components/molecules/StarRating/components/StarRating';
import * as styles from './ProductRatingSummary.styles';

interface ProductRatingSummaryProps {
  averageRating: number;
  fragmentID: string;
  numberOfReviews: number;
}

export const ProductRatingSummary = ({
  averageRating,
  fragmentID,
  numberOfReviews,
}: ProductRatingSummaryProps) => {
  const handleLinkClick = () => {
    sendGAEvent({
      event_action: 'Read reviews click',
      event_category: 'Product reviews',
      event_label: 'not set',
      event: 'custom_event',
    });
  };

  return (
    <a css={styles.starRating} href={`#${fragmentID}`} onClick={handleLinkClick}>
      <StarRating numberOfReviews={numberOfReviews} productRating={averageRating} />
    </a>
  );
};
