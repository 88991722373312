import type { MutableRefObject } from 'react';
import React, { useState } from 'react';
import {
  Feedback,
  fieldFeedbackContainer,
  requiredLabel,
} from '@noths/polaris-client-ribbons-design-system';
import { visuallyHidden } from '@noths/polaris-client-styles';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import * as copy from 'src/components/organisms/Personalisation/constants/copy';
import { MultipleOptionSelectionDrawerContainer } from 'src/components/organisms/Personalisation/containers/MultipleOptionSelectionDrawerContainer';
import * as styles from 'src/components/organisms/Personalisation/styles/Personalisation.styles';
import type { SelectionPayload } from 'src/redux/personalisation';
import type {
  CatServiceAPIProductOption,
  CatServiceAPIProductOptionValue,
} from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import { getFormattedPersonalisationAmount } from 'src/utils/price';

export const SELECTION_MULTIPLE = 'selection-multiple';
export const CTA_APPLY_CLASSNAME = 'cta-apply-button';

export const DRAWER_DESCRIPTOR_ID = 'drawer-description';

export interface OwnProps extends CatServiceAPIProductOption {
  displayValidation: boolean | undefined;
  forwardedRef: MutableRefObject<HTMLUListElement | null>;
}

export interface ContainerProps {
  currency: CurrencyCode;
  error: boolean;
  selectedOption?: CatServiceAPIProductOptionValue[];
}

export interface DispatchProps {
  deselectPersonalisation: (selection: SelectionPayload) => void;
  selectPersonalisation: (selection: SelectionPayload) => void;
}

type Props = ContainerProps & DispatchProps & OwnProps;

export const MultipleOptionSelection = ({
  currency,
  deselectPersonalisation,
  error,
  selectPersonalisation,
  ...props
}: Props) => {
  const appliedValue = props.selectedOption?.[0];
  const [isExpanded, setExpanded] = useState(false);
  const elementId = `selection-multiple-${props.id}`;
  const isValidSelection = !!appliedValue && !error;

  const applySelection = (selectedValue: CatServiceAPIProductOptionValue | null) => {
    setExpanded(!isExpanded);

    if (!selectedValue && !appliedValue) {
      return;
    }

    if (selectedValue) {
      selectPersonalisation({
        optionId: props.id,
        optionValue: selectedValue!,
        type: 'single-selection',
      });
      return;
    }

    deselectPersonalisation({
      optionId: props.id,
      optionValue: appliedValue!,
      type: 'single-selection',
    });
  };

  const discardSelection = () => {
    setExpanded(!isExpanded);
  };

  const { isNegative = false, value: formattedPriceValue = '' } = appliedValue?.priceModifier
    ? getFormattedPersonalisationAmount(currency, appliedValue.priceModifier)
    : {};

  return (
    <section
      css={[styles.selectionContainer, styles.multipleSelectionContainer]}
      data-has-error={!!error}
      data-is-valid={isValidSelection}
    >
      {props.required && (
        <p
          aria-hidden="true"
          css={requiredLabel}
          data-has-error={error}
          data-is-valid={isValidSelection}
        >
          Required
        </p>
      )}
      <button
        aria-controls={elementId}
        aria-expanded={isExpanded}
        css={styles.wrapperButton}
        onClick={() => setExpanded(!isExpanded)}
        type="button"
      >
        <p css={styles.legend} dangerouslySetInnerHTML={{ __html: props.name }} />
        <div className={SELECTION_MULTIPLE}>
          <div css={[styles.inputSelect, styles.inputOpen]}>
            <span css={visuallyHidden}>,</span>
            <span dangerouslySetInnerHTML={{ __html: appliedValue?.name || copy.SELECT_COPY }} />

            {appliedValue?.priceModifier && (
              <>
                <span css={visuallyHidden}>,</span>
                <span css={styles.priceModifier}>
                  <span css={styles.priceOperator} data-is-negative={isNegative}>
                    {isNegative ? <>&minus;</> : <>&#43;</>}
                  </span>
                  <span>{formattedPriceValue.replace('-', '')}</span>
                </span>
              </>
            )}
          </div>
          <div css={[fieldFeedbackContainer, styles.feedbackContainer]}>
            {error && <Feedback error iconAlign="left" name={copy.REQUIRED_SELECTION_COPY} />}
          </div>
        </div>
      </button>
      <MultipleOptionSelectionDrawerContainer
        appliedValue={appliedValue}
        currency={currency}
        elementId={elementId}
        isExpanded={isExpanded}
        onClose={discardSelection}
        onConfirm={applySelection}
        option={props}
        target={props.forwardedRef as MutableRefObject<HTMLUListElement>}
      />
    </section>
  );
};
