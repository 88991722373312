import { connect } from 'react-redux';
import { sendGAEvent, sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import {
  selectFeatureFlags,
  selectUserCurrencyCode,
} from '@noths/polaris-client-user-configuration';

import { PartnerStorefront } from 'src/components/organisms/PartnerStorefront/components';
import type {
  ContactPartnerSubmitPayload,
  RelatedProductClickPayload,
} from 'src/components/organisms/PartnerStorefront/modules';
import {
  actions,
  contactPartnerSubmit,
  selectContactFormServerErrors,
} from 'src/components/organisms/PartnerStorefront/modules';
import type {
  FieldValidationErrorPayload,
  PartnerProductCardsVisiblePayload,
} from 'src/components/organisms/PartnerStorefront/tracking/events';
import { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProduct, selectProductPartner } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import { getPartnerHref } from 'src/utils/getPartnerHref';
import type {
  PartnerLinkClickEventLabel,
  PartnerStorefrontDispatchProps,
  PartnerStorefrontStateProps,
} from '../types';

const mapStateToProps = (state: ReduxApplicationState): PartnerStorefrontStateProps => {
  const featureFlags = selectFeatureFlags(state) || {};

  return {
    contactFormServerErrors: selectContactFormServerErrors(state),
    currency: selectUserCurrencyCode(state) ?? 'GBP',
    isContactPartnerModalOpen: state.partnerStorefront.isContactPartnerModalOpen,
    messageSentSuccessfully: state.partnerStorefront.messageSent,
    partner: selectProductPartner(state),
    partnerHref: getPartnerHref(state.product.partner.shortcode, featureFlags),
    product: selectProduct(state),
    recommendedProducts: state.richRelevance[Placement.SameSeller].products,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): PartnerStorefrontDispatchProps => {
  return {
    onContactPartnerSubmit: (payload: ContactPartnerSubmitPayload) =>
      dispatch(contactPartnerSubmit(payload)),
    onContactPartnerSubmitError: (payload: FieldValidationErrorPayload) =>
      dispatch(actions.contactSellerFieldValidationError(payload)),
    onPartnerProductCardVisible: (payload: PartnerProductCardsVisiblePayload) =>
      dispatch(actions.partnerProductCardsVisible(payload)),
    openContactPartnerModal: () => dispatch(actions.openContactPartnerModal()),
    closeContactPartnerModal: () => dispatch(actions.closeContactPartnerModal()),
    onPartnerLinkClick: (
      ev: React.MouseEvent<HTMLAnchorElement>,
      eventLabel: PartnerLinkClickEventLabel,
    ) => {
      sendGALinkClickEvent(ev, {
        event: 'custom_event',
        event_action: 'Partner storefront click',
        event_category: 'Seller shelf',
        event_label: eventLabel,
      });
    },
    onRelatedProductClick: (payload: RelatedProductClickPayload) =>
      dispatch(actions.onRelatedProductClick(payload)),
    onContactPartnerVisible: () => {
      sendGAEvent({
        event: 'custom_event',
        event_category: 'Marketing preferences',
        event_action: 'Impression',
        event_label: 'Contact seller',
      });
    },
    onVisible: () => {
      sendGAEvent({
        event: 'custom_event',
        event_category: 'Seller shelf',
        event_action: 'Impression',
        event_label: 'not set',
      });
    },
  };
};

export const PartnerStorefrontContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerStorefront);
