import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectDecoratedDeliveryOptions } from 'src/redux/deliveryZoneOptions/selectors';
import { selectProductDeliveryByEvent } from 'src/redux/selectors';
import { getMaxDeliveryDate, hasExactDeliveryDate, isLateDelivery } from './utils';

const selectSelectedDecoratedDeliveryOptions = (state: ReduxApplicationState) =>
  selectDecoratedDeliveryOptions(state);

export const selectCheapestDeliveryOptionPrice = createSelector(
  [selectSelectedDecoratedDeliveryOptions, selectProductDeliveryByEvent],
  (deliveryOptions, lastDeliveryByEvent): number | null =>
    deliveryOptions.reduce((cheapestDeliveryPrice, option) => {
      if (!lastDeliveryByEvent?.arriveByEvent) {
        return null;
      }

      const { lastDeliveryBy } = lastDeliveryByEvent;
      const {
        date,
        max,
        price: { amount },
      } = option;

      const numberAmount = Number(amount);
      const hasExactDeliveryDateOption = hasExactDeliveryDate(option);

      const isTimelyExactDeliveryDateOption =
        hasExactDeliveryDateOption && !isLateDelivery(date!, lastDeliveryBy);

      if (hasExactDeliveryDateOption && !isTimelyExactDeliveryDateOption) {
        return cheapestDeliveryPrice;
      }

      const isCheaperDeliveryOption =
        cheapestDeliveryPrice == null || numberAmount < cheapestDeliveryPrice;

      if (isTimelyExactDeliveryDateOption && isCheaperDeliveryOption) {
        return numberAmount;
      }

      const maxDeliveryDate = getMaxDeliveryDate(max!);

      if (isLateDelivery(maxDeliveryDate, lastDeliveryBy)) {
        return cheapestDeliveryPrice;
      }

      return isCheaperDeliveryOption ? numberAmount : cheapestDeliveryPrice;
    }, null as null | number),
);

export const selectDeliveryByEventCost = createSelector(
  [selectCheapestDeliveryOptionPrice, selectProductDeliveryByEvent],
  (cost, deliveryByEvent) => {
    if (!deliveryByEvent?.arriveByEvent || cost == null) {
      return 'not set';
    }

    return cost || 'free';
  },
);
