/* istanbul ignore file */

import { animatedScrollTo } from '@noths/polaris-client-utils';

import { waitForElement } from 'src/utils/delay';

export const scrollToInvalidField = async () => {
  const element = await waitForElement('[data-has-error="true"]');

  if (!element) {
    return;
  }

  animatedScrollTo((element.parentNode! as Element).getBoundingClientRect().top);
  (element.querySelector('textarea, input') as HTMLInputElement | null)?.focus();
};
