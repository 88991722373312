import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPersonalisationOptions, selectProductPrice } from 'src/redux/selectors';
import type { CatServiceAPIProductOptionValue } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import { getPersonalisationBreakdown } from './helpers/selectorHelpers';

export const selectIsSubmitPersonalisationFormPending = (state: ReduxApplicationState) =>
  state.personalisation.isPending;

export const selectSelectedOption = (state: ReduxApplicationState, optionId: number) =>
  state.personalisation.selectedPersonalisations[optionId];

export const selectSelectedPersonalisations = (state: ReduxApplicationState) =>
  state.personalisation.selectedPersonalisations;

export const selectTrackedFocusEvents = (state: ReduxApplicationState) =>
  state.personalisation.trackedEvents.focus;

export const selectTrackedEvents = (state: ReduxApplicationState) =>
  state.personalisation.trackedEvents;

export const selectIsPersonalisationOptionsExpanded = (state: ReduxApplicationState) =>
  state.personalisation.isPersonalisationOptionsExpanded;

export const selectEditItem = (state: ReduxApplicationState) => state.personalisation.editedItem;

export const selectPersonalisationFromBasket = (state: ReduxApplicationState) => {
  const { basket, personalisation } = state;

  return (basket.basket.items || []).find(({ id }) => id === personalisation.editedItem);
};

export const selectIsPersonalisationInEditMode = createSelector(
  [selectPersonalisationFromBasket, selectEditItem],
  (basketItem, editItem) => basketItem && editItem,
);

export const selectPersonalisationOptionsBreakdown = createSelector(
  [selectSelectedPersonalisations, selectProductPrice],
  (selections, { currency }) => getPersonalisationBreakdown(selections, currency),
);

export const selectSelectedPersonalisationsTotal = createSelector(
  [selectPersonalisationOptionsBreakdown],
  ({ amountAdded, amountRemoved }) => amountAdded + amountRemoved,
);

export const selectTotalPrice = createSelector(
  [selectSelectedPersonalisationsTotal, selectProductPrice],
  (personalisationsTotalAmount, productPrice) => personalisationsTotalAmount + productPrice.amount,
);

export const selectInvalidCustomPersonalisations = createSelector(
  [selectSelectedPersonalisations],
  R.pipe(
    R.values,
    R.flatten,
    R.filter(
      (value?: CatServiceAPIProductOptionValue) => !!value?.isCustomInput && !value?.isValid,
    ),
  ),
);

export const selectOutstandingPersonalisations = createSelector(
  [selectSelectedPersonalisations, selectPersonalisationOptions],
  (selections, personalisationOptions) =>
    personalisationOptions.filter(({ id, required }) => required && !selections[id]),
);

export const selectNumberOfRequiredPersonalisations = createSelector(
  [selectPersonalisationOptions],
  (options) => options.reduce((count, opt) => (opt.required ? count + 1 : count), 0),
);
