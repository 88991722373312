import React from 'react';
import { Accordion } from '@noths/polaris-client-ribbons-design-system';
import { priceFormatter } from '@noths/polaris-client-utils';
import type { Price } from '@noths/polaris-dev-ts-types';
import { CurrencySymbol } from '@noths/polaris-dev-ts-types';

import {
  ITEM_PRICE_COPY,
  PERSONALISATION_ADDED_COPY,
  PERSONALISATION_COPY,
  PERSONALISATION_REMOVED_COPY,
  TOTAL_COPY,
} from 'src/components/molecules/PriceBreakdown/constants/copy';
import {
  PRICE_BREAKDOWN_ADDED_TESTID,
  PRICE_BREAKDOWN_REMOVED_TESTID,
  PRICE_BREAKDOWN_TESTID,
} from 'src/components/molecules/PriceBreakdown/constants/testIds';
import { getFormattedPersonalisationAmount } from 'src/utils/price';
import * as styles from './PriceBreakdown.styles';

export interface PersonalisationBreakdown {
  amountAdded: number;
  amountRemoved: number;
}

export interface PriceBreakdownStateProps {
  breakdown: PersonalisationBreakdown;
  personalisationsTotalAmount: number;
  productPrice: Price;
  totalPrice: number;
}

export interface PriceBreakdownOwnProps {
  expandablePersonalisations?: boolean;
  showTotalDivider?: boolean;
}

type PriceBreakdownProps = PriceBreakdownOwnProps & PriceBreakdownStateProps;

const AddedAndRemovedAmounts = ({
  breakdown,
  priceDigitLength,
  productPrice,
}: Pick<PriceBreakdownProps, 'breakdown' | 'productPrice'> & {
  priceDigitLength: number;
}) => (
  <>
    {breakdown.amountAdded > 0 && (
      <>
        <p css={styles.priceLabel}>{PERSONALISATION_ADDED_COPY}</p>
        <p css={styles.priceAmount} data-testid={PRICE_BREAKDOWN_ADDED_TESTID}>
          <span css={styles.priceAmountPlusSymbol}>+</span>
          {priceFormatter(productPrice.currency, breakdown.amountAdded)}
        </p>
      </>
    )}
    {breakdown.amountRemoved < 0 && (
      <>
        <p css={styles.priceLabel}>{PERSONALISATION_REMOVED_COPY}</p>{' '}
        <p css={styles.priceAmount} data-testid={PRICE_BREAKDOWN_REMOVED_TESTID}>
          <span css={styles.priceAmountMinusSymbol} data-is-multi-digit={priceDigitLength > 1}>
            &minus;
          </span>
          {priceFormatter(productPrice.currency, breakdown.amountRemoved).replace('-', '')}
        </p>
      </>
    )}
  </>
);

export const PriceBreakdown = ({
  breakdown,
  expandablePersonalisations,
  personalisationsTotalAmount,
  productPrice,
  showTotalDivider,
  totalPrice,
}: PriceBreakdownProps) => {
  const { value: formattedPersonalisationsTotalAmount } = getFormattedPersonalisationAmount(
    productPrice.currency,
    personalisationsTotalAmount,
  );

  const priceDigitLength = formattedPersonalisationsTotalAmount
    .replace(CurrencySymbol[productPrice.currency], '')
    .replace('-', '')
    .split('.')[0].length;

  const shouldShowTotalDivider = showTotalDivider && personalisationsTotalAmount !== 0;

  return (
    <div css={styles.priceBreakdown} data-testid={PRICE_BREAKDOWN_TESTID}>
      {personalisationsTotalAmount !== 0 && (
        <>
          <p css={styles.priceLabel}>{ITEM_PRICE_COPY}</p>
          <p css={styles.priceAmount}>
            {priceFormatter(productPrice.currency, productPrice.amount)}
          </p>

          {expandablePersonalisations ? (
            <div css={styles.accordionStyles}>
              <Accordion
                id="personalisation-breakdown-accordion"
                title={
                  <div css={styles.accordionTitle}>
                    <span>{PERSONALISATION_COPY}</span>
                    <span css={styles.accordionTotalAmount}>
                      {personalisationsTotalAmount < 0 && <>&minus; </>}
                      {priceFormatter(productPrice.currency, personalisationsTotalAmount).replace(
                        '-',
                        '',
                      )}
                    </span>
                  </div>
                }
              >
                <div css={styles.expandablePersonalisationBreakdown}>
                  <AddedAndRemovedAmounts
                    breakdown={breakdown}
                    priceDigitLength={priceDigitLength}
                    productPrice={productPrice}
                  />
                </div>
              </Accordion>
            </div>
          ) : (
            <div css={styles.personalisationBreakdown}>
              <p css={styles.personalisationBreakdownCopy}>{PERSONALISATION_COPY}</p>
              <AddedAndRemovedAmounts
                breakdown={breakdown}
                priceDigitLength={priceDigitLength}
                productPrice={productPrice}
              />
            </div>
          )}
        </>
      )}

      <p css={[styles.totalLabel, shouldShowTotalDivider && styles.totalDivider]}>{TOTAL_COPY}</p>
      <p css={[styles.totalPrice, shouldShowTotalDivider && styles.totalDivider]}>
        {priceFormatter(productPrice.currency, totalPrice)}
      </p>
    </div>
  );
};
