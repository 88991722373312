import { connect } from 'react-redux';
import { sendGAEvent, sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectIsSubmitPersonalisationFormPending,
  selectPersonalisationFromBasket,
  submitPersonalisationForm,
} from 'src/redux/personalisation';
import { selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import type { BasketCTAButtonDispatchProps, BasketCTAButtonStateProps } from './BasketCTAButton';
import { BasketCTAButton } from './BasketCTAButton';
import { MAIN_PAGE_CTA_ID } from './elementIDs';

const mapStateToProps = (state: ReduxApplicationState): BasketCTAButtonStateProps => {
  return {
    editMode: Boolean(selectPersonalisationFromBasket(state)),
    loading: selectIsSubmitPersonalisationFormPending(state),
    purchasable: selectProduct(state).purchasable,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): BasketCTAButtonDispatchProps => {
  return {
    onAddEditClick: () => dispatch(submitPersonalisationForm()),
    onBuyGiftCard: (ev: React.MouseEvent) => {
      sendGALinkClickEvent(ev, {
        event: 'custom_event',
        event_action: 'Buy giftcard',
        event_category: 'Product page',
        event_label: 'not set',
      });
    },
    onVisible: () => {
      const ctaButton: HTMLElement | null = document.getElementById(MAIN_PAGE_CTA_ID);

      sendGAEvent({
        event: 'custom_event',
        event_category: 'Product page CTA',
        event_action: 'Impression',
        event_label: ctaButton?.textContent ?? '',
      });
    },
  };
};

export const BasketCTAButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasketCTAButton);
