import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPersonalisationFromBasket } from 'src/redux/personalisation/selectors';
import type { BasketAPIItem } from 'src/services/basket-api/types/BasketAPIResponseBody';

export const updatePersonalisationOptionsFromBasketItem = createAsyncThunk<
  BasketAPIItem | undefined
>('personalisation/updatePersonalisationOptionsFromBasketItem', async (_, { getState }) => {
  return selectPersonalisationFromBasket(getState() as ReduxApplicationState);
});
