import type { PageEvent } from '@noths/polaris-client-next-components';
import type { _Omit } from '@noths/polaris-dev-ts-recipes';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { ProductFieldObject } from 'src/types/googleAnalytics';
import { generateProductFieldObject } from './googleAnalytics';

export type PageEcommerceObject = _Omit<ProductFieldObject, 'quantity'>;

export interface PageEventObject {
  ecommerce: {
    detail: { products: PageEcommerceObject[] };
  };
  page?: PageEvent['page'] & {
    external_referrer: boolean;
    layout_type: string;
  };
}

export const getServerSidePageEventObject = (
  version: string,
  appVersion: string,
  externalReferrer: boolean,
  layoutType: string,
  updatedReduxState?: ReduxApplicationState,
) => {
  if (!updatedReduxState || Object.keys(updatedReduxState).length === 0) {
    return {} as PageEventObject;
  }

  const { deliveryZoneOptions, personalisation, product } = updatedReduxState;

  const { quantity, ...productEcommerce }: ProductFieldObject = {
    ...generateProductFieldObject({
      product,
      personalisation,
      deliveryZoneOptions,
    }),
    dimension92: String(product.options.length),
  };

  const pageEventObject: PageEventObject = {
    page: {
      category: 'Product',
      environment: process.env.NODE_ENV,
      external_referrer: externalReferrer,
      layout_type: layoutType,
      react: 'true',
      app_version: appVersion,
      release_version: version,
    },
    ecommerce: {
      detail: {
        products: [productEcommerce],
      },
    },
  };

  return pageEventObject;
};
