// basket-api schema and docs: https://basket-api-shared.qa.noths.com/graphql

export const BASKET = `
query basketQuery {
  basket {
    itemsCount # TODO: This will change to promoMessage once the schema is stable
  }
}
`;

export const BASKET_WITH_OPTIONS = `
query basketWithOptionsQuery {
  basket {
    # TODO: add promoMessage to this query once the schema is stable
    itemsCount
    itemsTotal { currency, cents }
    items {
      id
      quantity
      options {
        optionId
        name
        type
        valueLabel
        value
        valueId
        prices {
          listPrice {
            currency
            cents
          }
          purchasePrice {
            currency
            cents
          }
          discountValue {
            currency
            cents
          }
          discountType
          discountPercent
        }
      }
    }
  }
}  
`;
