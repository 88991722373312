import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const wrapper = css`
  display: grid;
  grid-template-columns: ${pxToRem(32)} 1fr;
  margin: ${stack(spacing.xs)};
  padding: ${pxToRem(8)};
  border-radius: ${pxToRem(10)};
  border: solid 1px var(--neutral-black-70);
  transition: border-color 0.15s ease-in, background-color 0.15s ease-in;

  &:hover {
    border-color: var(--neutral-black-70);
  }

  &[data-is-selected='true'] {
    box-shadow: 0 0 ${pxToRem(8)} 0 rgba(0, 0, 0, 0.2);
    border-color: var(--brand-purple-base);
    transition: border-color 0.15s ease-in;
  }
`;

export const label = css`
  display: grid;
  grid-template-columns: 2fr 1fr;
  ${fontSize(text.BODY.XS)};
  margin-left: ${pxToRem(spacing.xs)};
  padding: ${pxToRem(6)} ${pxToRem(4)} ${pxToRem(6)} 0;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: ${pxToRem(-8)};
    width: calc(100% + ${pxToRem(10)});
    height: calc(100% + ${pxToRem(spacing.md)});
  }
`;

export const priceModifier = css`
  justify-self: flex-end;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const priceOperator = css`
  font-size: ${pxToRem(text.BODY.SM.fontSize)};
  justify-self: flex-end;
  margin-right: ${pxToRem(2)};

  &[data-is-negative='false'] {
    font-size: ${pxToRem(text.HEADING.XS.fontSize)};
  }
`;
