import { createSlice } from '@reduxjs/toolkit';

import { submitPersonalisationForm } from 'src/redux/personalisation/thunks';
import type { RequestProductReviewsOptions } from 'src/redux/reviews/thunks';
import { requestProductReviews } from 'src/redux/reviews/thunks';

export interface AccessibilityState {
  statusMessage: string;
}

export const initialState: AccessibilityState = {
  statusMessage: '',
};

const isReviewRequestFromUser = (reviewRequestOptions: RequestProductReviewsOptions) =>
  Boolean(reviewRequestOptions.query?.page);

export const { actions, reducer } = createSlice({
  name: 'accessibility',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestProductReviews.fulfilled, (state, { meta, payload }) => {
        if (isReviewRequestFromUser(meta.arg)) {
          state.statusMessage = `Page ${payload.pagination.currentPage} of reviews loaded`;
        }
      })
      .addCase(requestProductReviews.pending, (state, { meta }) => {
        if (isReviewRequestFromUser(meta.arg)) {
          state.statusMessage = 'Loading reviews';
        }
      })
      .addCase(requestProductReviews.rejected, (state, { meta }) => {
        if (isReviewRequestFromUser(meta.arg)) {
          state.statusMessage = 'There was a problem loading reviews';
        }
      })
      .addCase(submitPersonalisationForm.pending, (state) => {
        state.statusMessage = 'Updating basket';
      })
      .addCase(submitPersonalisationForm.fulfilled, (state) => {
        state.statusMessage = 'Basket has been updated';
      })
      .addCase(submitPersonalisationForm.rejected, (state) => {
        state.statusMessage = 'There was a problem updating the basket';
      });
  },
});
