import React from 'react';
import { Price } from '@noths/polaris-client-ribbons-design-system';
import { priceFormatter } from '@noths/polaris-client-utils';

import type { ProductState } from 'src/redux/product';
import { getOptionsCostRange } from 'src/redux/product/utils/getOptionsCostRange';
import { PersonalisationLabel } from './PersonalisationLabel';
import * as styles from './ProductPrice.styles';

export const CURRENT_PRICE_TEST_ID = 'current-price';

interface ProductPriceProps {
  onSale: ProductState['onSale'];
  options: ProductState['options'];
  personalisable: boolean;
  preSalePrices: ProductState['preSalePrices'];
  price: ProductState['price'];
}

export const ProductPrice = ({
  onSale,
  options,
  personalisable,
  preSalePrices,
  price,
}: ProductPriceProps) => {
  const { currency } = price;
  const optionsCostRange = getOptionsCostRange(options, currency);
  const showFrom = optionsCostRange.maxOptional + optionsCostRange.maxRequired > 0;
  const showPersonalisationCostLabel =
    options.length > 0 && personalisable && optionsCostRange.minRequired > 0;
  const preSalePrice = preSalePrices?.find((preSalePrice) => preSalePrice.currency === currency);

  return (
    <div css={styles.prices}>
      <div>
        {showFrom && <b css={styles.from}>From&nbsp;</b>}
        <Price
          amount={price.amount}
          css={styles.currentPrice}
          currency={currency}
          isSalePrice={Boolean(onSale && preSalePrice)}
          testId={CURRENT_PRICE_TEST_ID}
        />
        {onSale && preSalePrice && (
          <Price
            amount={preSalePrice.amount}
            css={styles.previousPrice}
            currency={currency}
            isPreviousPrice={true}
          />
        )}
      </div>
      {showPersonalisationCostLabel && (
        <div>
          <PersonalisationLabel
            text={`Personalise it from ${priceFormatter(currency, optionsCostRange.minRequired)}`}
          />
        </div>
      )}
    </div>
  );
};
