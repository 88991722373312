import React from 'react';
import { visuallyHidden } from '@noths/polaris-client-styles';

import { StarRating } from 'src/components/molecules/StarRating/components/StarRating';
import { OVERALL_RATING_HEADER } from 'src/components/organisms/OverallRating/constants/copy';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import * as styles from './styles';

export interface OverallRatingProps {
  numberOfReviews: number;
  productRating: CatServiceAPIProduct['productRating'];
}

export const OverallRating = ({ numberOfReviews, productRating }: OverallRatingProps) => {
  if (!productRating) {
    return null;
  }

  return (
    <div css={styles.overallRatingContainer}>
      <h2 css={styles.ratingHeader}>{OVERALL_RATING_HEADER}</h2>
      <p css={visuallyHidden}>{productRating} out of 5</p>
      <p aria-hidden="true" css={styles.overallRatingLabel}>
        <span css={styles.overallRating} data-testid="rating">
          {productRating}
        </span>{' '}
        / 5
      </p>
      <StarRating
        numberOfReviews={numberOfReviews}
        numberOfReviewsFontSize="SM"
        productRating={productRating}
      />
    </div>
  );
};
