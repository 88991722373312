import { createAction, createSlice } from '@reduxjs/toolkit';

import type {
  FieldValidationErrorPayload,
  PartnerProductCardsVisiblePayload,
} from 'src/components/organisms/PartnerStorefront/tracking/events';
import type { PartnerStorefrontState } from 'src/components/organisms/PartnerStorefront/types';
import { logger } from 'src/utils/serverLogger';
import { contactPartnerSubmit, submitMarketingPreferences } from './thunks';

export const initialState: PartnerStorefrontState = {
  messageSent: false,
  isContactPartnerModalOpen: false,
  error: null,
};

const {
  actions: reducerActions,
  name,
  reducer,
} = createSlice({
  name: 'partnerStorefront',
  initialState,
  reducers: {
    openContactPartnerModal: (state) => {
      state.isContactPartnerModalOpen = true;
    },
    closeContactPartnerModal: (state) => {
      state.isContactPartnerModalOpen = false;
      state.messageSent = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactPartnerSubmit.fulfilled, (state) => {
        state.messageSent = true;
      })
      .addCase(contactPartnerSubmit.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        }
      })
      .addCase(submitMarketingPreferences.rejected, (_, { error }) => {
        logger.error('Error submitting marketing preferences: ', error);
      });
  },
});

export interface RelatedProductClickPayload {
  code: number;
  href: string;
  shouldUseEventCallback: boolean;
}

const actions = {
  ...reducerActions,
  contactSellerFieldValidationError: createAction<FieldValidationErrorPayload>(
    `${name}/invalidFieldError`,
  ),
  partnerProductCardsVisible: createAction<PartnerProductCardsVisiblePayload>(
    `${name}/partnerProductCardVisible`,
  ),
  onRelatedProductClick: createAction<RelatedProductClickPayload>(`${name}/relatedProductClick`),
};

export { actions, reducer };
