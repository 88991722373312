const removePatternFromHtmlTags = (text: string | null, pattern: string) => {
  if (!text) {
    return '';
  }

  return text
    .replace(new RegExp(`<${pattern}>`, 'g'), '')
    .replace(new RegExp(`</${pattern}>`, 'g'), '');
};

export const removeHtmlElementFromString = (
  text: string | (string | null),
  element: keyof JSX.IntrinsicElements,
) => removePatternFromHtmlTags(text, element);

export const removeHtmlElementsFromString = (
  text: string | (string | null),
  elements: Array<keyof JSX.IntrinsicElements>,
) => {
  const regex = `(${elements.join('|')})`;
  return removePatternFromHtmlTags(text, regex);
};
