import React from 'react';
import { BlocksHeader } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { CatServiceAPIProductOptionValue } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import { getPersonalisationAmount } from 'src/utils/price';

interface ToggleButtonProps {
  currency: CurrencyCode;
  isSelected: boolean;
  onClick: (value: CatServiceAPIProductOptionValue | null) => void;
  onFocus?: VoidFunction;
  value: CatServiceAPIProductOptionValue;
}

export const ToggleButton = ({
  currency,
  isSelected,
  onClick,
  onFocus,
  value,
}: ToggleButtonProps) => {
  const { value: price } = getPersonalisationAmount(currency, value.priceModifier);

  const handleOnClick = () => {
    const newValue = isSelected ? null : value;

    onClick(newValue);
  };

  return (
    <BlocksHeader
      currency={currency}
      isSelected={isSelected}
      label={value.name}
      onClick={handleOnClick}
      onFocus={onFocus}
      price={price}
      variant="check"
    />
  );
};
