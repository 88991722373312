import {
  fontSize,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { inset, mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const card = css`
  height: 100%;
  position: relative;
`;

export const heading = css`
  margin: 0 0 ${spacing.base}px;
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  ${fontSize(text.HEADING.XS)}

  a {
    text-decoration: none;
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      ${fontSize(text.BODY.MD)}
    `,
  )}
`;

export const imageWrapper = css`
  display: block;
  margin: ${spacing.xxxxl}px auto ${spacing.md}px auto;
  border-radius: ${pxToRem(10)};
  overflow: hidden;
  width: ${pxToRem(212)};
  height: ${pxToRem(204)};

  ${mediaQuery(
    { min: minBreakpoints.M, max: maxBreakpoints.M },
    css`
      padding-bottom: 70%;
      height: ${pxToRem(148)};
      width: ${pxToRem(148)};
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding-bottom: 100%;
      height: ${pxToRem(212)};
    `,
  )};
`;

export const image = css`
  display: block;
  object-fit: cover;
  object-position: 50% 0;
  width: 100%;
  border-radius: ${pxToRem(10)};
`;

export const title = css`
  display: block;
  margin: ${stack(spacing.base)};
`;

export const copyWrapper = css`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: ${inset(spacing.md, 0)};
`;

export const location = css`
  margin: ${stack(spacing.base)};
  ${fontSize(text.BODY.SM)};
`;

const CONTACT_SELLER__ICON_WIDTH = 16;

export const contactSeller = css`
  --email-icon-color: var(--neutral-black-base);
  display: inline-flex;
  justify-self: center;
  margin: 0;
  padding: ${inset(spacing.xs, spacing.base)};
  padding-left: ${pxToRem(spacing.xs * 2 + CONTACT_SELLER__ICON_WIDTH)};
  color: var(--neutral-black-base);
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${fontSize(text.BODY.SM)};

  &:active {
    --email-icon-color: var(--brand-purple-base);
  }

  @media (hover: hover) {
    &:hover {
      --email-icon-color: var(--brand-purple-base);
    }
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin: ${stack(spacing.xxl)};
    `,
  )}
`;

export const contactSellerIcon = css`
  position: absolute;
  left: -${spacing.xs + CONTACT_SELLER__ICON_WIDTH}px;
  top: 50%;
  transform: translateY(-50%);
`;
