import { useEffect, useState } from 'react';
import { minBreakpoints } from '@noths/polaris-client-ribbons-base';

interface StickyConfiguration {
  lowerScrollThresholdSelector: string;
  nav: HTMLElement | null;
  sectionHeight: number;
}

export const useSticky = ({
  lowerScrollThresholdSelector,
  nav,
  sectionHeight,
}: StickyConfiguration) => {
  const [scroll, setScroll] = useState({ last: 0, ref: 0 });
  const [translateY, setTranslateY] = useState<number>(sectionHeight);
  const [bottomMode, setBottomMode] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= minBreakpoints.M) {
        return;
      }

      // Default y is NaN so it does not pick 0 on load.
      const { y } = nav?.getBoundingClientRect() || { y: NaN };

      const refHeight =
        document.querySelector<HTMLElement>(lowerScrollThresholdSelector)?.offsetHeight || 0;
      const scrollingUp = window.scrollY < scroll.last;
      const scrollingDown = window.scrollY > scroll.last;
      const isAtTheBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - refHeight;

      if (scrollingUp) {
        if (y <= 0 && !bottomMode) {
          setTranslateY(0);
        }

        if (y >= 0) {
          setTranslateY(sectionHeight);
        }

        setScroll({ last: window.scrollY, ref: window.scrollY });
        if (!isAtTheBottom) {
          setBottomMode(false);
        }
      }

      if (scrollingDown) {
        if (y <= 0) {
          setScroll({ last: window.scrollY, ref: window.scrollY });
          if (!bottomMode) {
            setTranslateY(0);
          }
        }

        if (!isAtTheBottom) {
          setBottomMode(false);
        }
      }

      if (isAtTheBottom) {
        setTimeout(() => setTranslateY(sectionHeight), 200);
        setBottomMode(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [bottomMode, lowerScrollThresholdSelector, nav, scroll.last, sectionHeight]);

  return {
    translateY,
  };
};
