/* istanbul ignore file */

import {
  createGrid,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { LOADING_SPINNER_CLASS } from '@noths/polaris-client-ribbons-design-system';
import { hideScrollbar, mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

const largeScreenGrid = createGrid({ numberOfColumns: 7 });

export const loadingSpinner = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .${LOADING_SPINNER_CLASS} {
    width: ${pxToRem(spacing.xxxl)};
    height: ${pxToRem(spacing.xxxl)};
  }
`;

export const productLabel = css`
  position: absolute;
  left: 0;
  top: ${pxToRem(15)};
`;

export const fullScreen = css`
  height: 100%;
  margin: 0;
`;

export const imagesWrapper = css`
  overflow-y: hidden;
  margin: 0 -${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      overflow-y: visible;
      margin: 0;
      position: sticky;
      top: ${pxToRem(spacing.lg)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      top: ${pxToRem(spacing.xl)};

      ${largeScreenGrid}
    `,
  )}
`;

export const mainImageWrapperWithThumbnails = css`
  position: relative;
  display: flex;
  overflow-x: auto;
  scroll-behavior: auto;
  scroll-snap-type: x mandatory;

  ${hideScrollbar}

  &[data-image-focus='true'] {
    box-shadow: 0 0 0 ${pxToRem(3)} var(--neutral-white-base),
      0 0 0 ${pxToRem(5)} var(--brand-purple-base);
  }

  ${mediaQuery(
    { min: minBreakpoints.M, max: maxBreakpoints.M },
    css`
      margin: 0 0 ${pxToRem(spacing.md)};
      border-radius: ${pxToRem(10)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      border-radius: ${pxToRem(20)};
    `,
  )}
`;

export const carouselSlide = css`
  scroll-snap-align: center;
  scroll-snap-stop: always;
  min-width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--neutral-black-10);
`;

export const carouselContainer = css`
  position: relative;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -1;
    `,
  )}
`;

const mainImageBorderRadius = css`
  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      border-radius: ${pxToRem(10)};
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      border-radius: ${pxToRem(20)};
    `,
  )}
`;

export const mainImageWrapperNoThumbnails = css`
  align-items: center;
  background-color: var(--neutral-black-10);
  display: flex;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  ${mainImageBorderRadius}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 1 / -1;
    `,
  )}
`;

export const mainImageLink = css`
  display: block;
  flex-basis: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.focus-visible:focus {
    outline: none !important;
    box-shadow: none;
  }

  ${mainImageBorderRadius}
`;

export const mainImage = css`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const imageThumbnails = css`
  display: none;
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: block;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    `,
  )}
`;

export const carouselNavigationButton = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  background-color: var(--neutral-white-base);
  border: none;
  border-radius: 50%;
  padding: 10px;

  &.focus-visible {
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 50%;
      border: var(--brand-purple-base) 2px solid;
    }

    &:after {
      content: '';
      position: absolute;
      top: -6px;
      bottom: -6px;
      left: -6px;
      right: -6px;
      border-radius: 50%;
      border: var(--neutral-black-10) 2px solid;
    }
  }
`;

export const prevNavigationButton = css`
  left: ${pxToRem(spacing.md)};

  img {
    transform: rotate(180deg);
  }
`;

export const nextNavigationButton = css`
  right: ${pxToRem(spacing.md)};
`;

const progressIndicator = css`
  position: absolute;
  background-color: var(--neutral-white-base);
  border-radius: ${pxToRem(10)};
  padding: 0 ${pxToRem(8)};
  margin: 0;
  font-size: ${pxToRem(text.BODY.XS.fontSize)};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
`;

export const bottomCenterProgressIndicator = css`
  ${progressIndicator}
  left: 50%;
  transform: translateX(-50%);
  bottom: ${pxToRem(spacing.md)};
`;

export const bottomRightProgressIndicator = css`
  ${progressIndicator}
  right: ${pxToRem(spacing.md)};
  bottom: ${pxToRem(spacing.md)};
`;

export const favouriteButtonWrapper = css`
  position: absolute;
  right: ${pxToRem(spacing.md)};
  bottom: ${pxToRem(spacing.md)};
`;

export const favouriteButton = css`
  position: absolute;
  right: 0;
  bottom: 0;
`;
