import React from 'react';
import { IconDescription } from '@noths/polaris-client-ribbons-design-system';
import type { Link } from '@noths/polaris-dev-ts-types';

import * as styles from './styles';

export type FileLinkProps = Link;

export const FileLink = ({ href, title }: FileLinkProps) => (
  <div css={[styles.fileLinkWrapper]}>
    <IconDescription css={styles.fileIcon} height={16} width={16} />
    <a css={styles.fileLink} href={href!} rel="noreferrer" target="_blank">
      {title}
    </a>
  </div>
);
