import { createAsyncThunk } from '@reduxjs/toolkit';

import { BASKET_PAGE_PATH } from 'src/constants/page';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

export const navigateToBasket = createAsyncThunk(
  'productInfo/navigateToBasket',
  async (_, { getState }) => {
    const state = getState() as ReduxApplicationState;

    if (state.basket.error) {
      return;
    }

    window.location.assign(BASKET_PAGE_PATH);
  },
);
