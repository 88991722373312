import { getProductIdFromProductCode } from '@noths/polaris-client-utils';
import type { RequireKeys } from '@noths/polaris-dev-ts-recipes';
import type {
  BaseFieldObject,
  PlacementProductFieldObject,
  PlacementProductListFieldObject,
  Price,
  RecommendedProduct,
} from '@noths/polaris-dev-ts-types';
import { deliveryByEventNames } from '@noths/polaris-dev-ts-types';
import * as R from 'ramda';

import { getDeliveryByEventLabel } from 'src/components/molecules/DeliveryEvent/utils';
import type { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getPersonalisationBreakdown } from 'src/redux/personalisation/helpers/selectorHelpers';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import type { ProductFieldObject } from 'src/types/googleAnalytics';
import { formatIntegerPriceToDecimal } from './price';

const getPriceInGBP = (prices: Price[], personalisationTotal?: number) => {
  const priceInGBP = prices.find(({ currency }) => currency === 'GBP');
  const personalisationCost = personalisationTotal || 0;

  const price = priceInGBP?.amount
    ? formatIntegerPriceToDecimal(priceInGBP.amount + personalisationCost)
    : '';

  return price;
};

interface StateProduct extends CatServiceAPIProduct {
  quantity?: number;
}

interface State extends Pick<ReduxApplicationState, 'personalisation' | 'deliveryZoneOptions'> {
  product: StateProduct;
}

export const generatePlacementProductFieldObject = (
  product: RecommendedProduct,
  omitKeys?: Array<keyof PlacementProductFieldObject>,
): PlacementProductFieldObject => {
  const {
    code,
    free_domestic_delivery: freeDomesticDelivery,
    inventory_status: inventoryStatus,
    new: isNew,
    on_sale: onSale,
    partner,
    prices,
    purchasable,
    sale_percentage: salePercentage,
    title,
  } = product;

  const dimension22List = [
    freeDomesticDelivery && 'free uk delivery',
    isNew && 'new',
    onSale && !!salePercentage && `-${salePercentage}% off`,
    !purchasable && 'out of stock',
  ]
    .filter(Boolean)
    .join(', ');

  const productPrice = getPriceInGBP(prices);

  const fieldObject = {
    brand: partner.name,
    dimension16: 'not set',
    dimension22: dimension22List,
    dimension30: String(code),
    dimension32: productPrice,
    dimension33: onSale ? `${salePercentage}.0%` : 'none',
    dimension34: partner.shortcode,
    dimension89: inventoryStatus!,
    dimension92: '0',
    id: String(getProductIdFromProductCode(code)),
    name: title,
    price: productPrice,
    quantity: 1,
  } as const;

  return omitKeys ? (R.omit(omitKeys, fieldObject) as PlacementProductFieldObject) : fieldObject;
};

interface GeneratePlacementProductListFieldObjectParams {
  placementName: string;
  /** Provide position to return a specific, single product list. Useful for events when the list should only contain 1 product. */
  placementPosition?: number;
  placementStrategy: string;
  products: RecommendedProduct[];
}

export const generatePlacementProductListFieldObjects = (
  {
    placementName,
    placementPosition,
    placementStrategy,
    products,
  }: GeneratePlacementProductListFieldObjectParams,
  omitKeys?: Array<keyof PlacementProductFieldObject>,
): PlacementProductListFieldObject[] => {
  const list: PlacementProductListFieldObject['list'] = placementStrategy
    ? `product - rich relevance - ${placementName} - ${placementStrategy}`
    : `product - rich relevance - ${placementName}`;
  const isSinglePlacementProductList = placementPosition && products.length === 1;

  return products.map((prod, index) => ({
    ...generatePlacementProductFieldObject(prod, omitKeys),
    list,
    position: isSinglePlacementProductList ? placementPosition : index + 1,
  }));
};

export const generateProductFieldObject = ({
  personalisation,
  product,
}: RequireKeys<Partial<State>, 'product' | 'personalisation'>): ProductFieldObject => {
  const {
    code,
    deliveryByEvent,
    freeDomesticDelivery,
    inventoryStatus,
    isNew,
    numberOfReviews,
    onSale,
    partner,
    prices,
    productRating,
    purchasable,
    quantity = 1,
    salePercentage,
    title,
  } = product;

  const dimension22List = [
    freeDomesticDelivery && 'free uk delivery',
    isNew && 'new',
    onSale && !!salePercentage && `-${salePercentage}% off`,
    !purchasable && 'out of stock',
    deliveryByEvent?.arriveByEvent &&
      deliveryByEventNames.includes(deliveryByEvent?.eventName) &&
      getDeliveryByEventLabel(deliveryByEvent.eventName).toLowerCase().replace(' for', ''),
  ]
    .filter(Boolean)
    .join(', ');

  const dimension92 = Object.values(personalisation.selectedPersonalisations)
    .reduce((personalisationCount, item = []) => personalisationCount + item.length, 0)
    .toString();

  const { amountAdded: addedPersonalisationCost, amountRemoved: removedPersonalisationCost } =
    getPersonalisationBreakdown(personalisation.selectedPersonalisations, 'GBP');

  const productPrice = getPriceInGBP(prices);
  const totalPrice = getPriceInGBP(prices, addedPersonalisationCost + removedPersonalisationCost);

  return {
    brand: partner.name,
    dimension22: dimension22List,
    dimension30: String(code),
    dimension32: productPrice,
    dimension33: onSale ? `${salePercentage}.0%` : 'none',
    dimension34: partner.shortcode,
    dimension89: inventoryStatus,
    dimension90: String(numberOfReviews),
    dimension91: String(productRating || ''),
    dimension92: dimension92,
    id: String(getProductIdFromProductCode(code)),
    name: title,
    price: totalPrice,
    quantity,
  };
};

interface GenerateProductsEventParams {
  personalisationsTotalAmount?: number;
  placementName: Placement | string;
  placementStrategy: string;
  positionInList?: number;
  products: RecommendedProduct[];
}

export const generateProductsForEvent = (
  {
    personalisationsTotalAmount = 0,
    placementName,
    placementStrategy,
    positionInList,
    products,
  }: GenerateProductsEventParams,
  omitKeys: Array<keyof PlacementProductListFieldObject> = [],
): BaseFieldObject[] =>
  products.map(
    (
      {
        code,
        free_domestic_delivery,
        new: isNew,
        on_sale,
        partner,
        prices,
        purchasable,
        sale_percentage,
        title,
      },
      index,
    ) => {
      const dimension22List = [
        free_domestic_delivery && 'free uk delivery',
        isNew && 'new',
        on_sale && !!sale_percentage && `-${sale_percentage}% off`,
        !purchasable && 'out of stock',
      ]
        .filter(Boolean)
        .join(', ');

      const productPrice = getPriceInGBP(prices);
      const totalPrice = getPriceInGBP(prices, personalisationsTotalAmount);
      const position = positionInList || index + 1;
      const list = placementStrategy
        ? `product - rich relevance - ${placementName} - ${placementStrategy}`
        : `product - rich relevance - ${placementName}`;

      const baseFieldObject: BaseFieldObject = {
        name: title,
        id: String(getProductIdFromProductCode(code)),
        dimension22: dimension22List,
        dimension25: position,
        dimension30: String(code),
        dimension31: productPrice,
        price: totalPrice,
        dimension32: productPrice,
        dimension33: on_sale ? `${sale_percentage}.0%` : 'none',
        brand: partner.name,
        dimension34: partner.shortcode,
        position: position,
        list,
        dimension16: 'not set',
      };

      return omitKeys.length
        ? (R.omit(omitKeys, baseFieldObject) as BaseFieldObject)
        : baseFieldObject;
    },
  );
