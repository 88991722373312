import { selectRouterQueryParameters } from '@noths/polaris-client-next-redux';
import getConfig from 'next/config';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { NextConfig } from 'src/types/nextConfig';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const loop54Endpoints = {
  plp: publicRuntimeConfig.endpoints.loop54Categories,
  search: publicRuntimeConfig.endpoints.loop54Search,
};

export const selectLoop54EndpointFromReferrer = (
  state: ReduxApplicationState,
): string | undefined => {
  const { referredBy } = selectRouterQueryParameters(state);
  const referrer = (Array.isArray(referredBy) ? referredBy[0] : referredBy) ?? '';

  return loop54Endpoints[referrer];
};
