import React from 'react';

import { Star } from 'src/components/atoms/Star/Star';
import * as styles from 'src/components/molecules/StarRating/styles/starRating.styles';
import { roundProductRating } from 'src/utils/rating';

const starRatings = [1, 2, 3, 4, 5] as const;

export interface StarRatingProps {
  numberOfReviews?: number;
  numberOfReviewsFontSize?: 'XS' | 'SM';
  productRating: number | null;
}

export const StarRating = ({
  numberOfReviews,
  numberOfReviewsFontSize = 'XS',
  productRating,
}: StarRatingProps) => {
  const reviewCount = numberOfReviews || 0;
  const numberOfReviewsText = `${reviewCount} Product review${reviewCount !== 1 ? 's' : ''}`;

  if (!productRating) {
    return null;
  }

  return (
    <div css={styles.starRatingContainer}>
      <div aria-label={`${productRating} out of 5`} role="img">
        {starRatings.map((rating: number) => (
          <span css={styles.starRating} key={`star-${rating}`}>
            <Star isEmpty={rating > roundProductRating(productRating)} />
          </span>
        ))}
      </div>
      {numberOfReviews && (
        <span css={styles.reviewsText(numberOfReviewsFontSize)}>
          <span css={styles.largeNumber}>{productRating}</span>/5, {numberOfReviewsText}
        </span>
      )}
    </div>
  );
};
