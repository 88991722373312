import React from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';
import { grid } from '@noths/polaris-client-ribbons-base';
import { Accordion } from '@noths/polaris-client-ribbons-design-system';
import { getAnchorElementFromEvent } from '@noths/polaris-client-utils';

import * as copy from './copy';
import * as styles from './DeliveryAndReturnsInfo.styles';

export interface DeliveryAndReturnsInfoStateProps {
  deliveryInfo?: string;
  returnsInfo?: string;
}

export interface DeliveryAndReturnsInfoDispatchProps {
  onReturnsPolicyLinkClick: () => void;
  onToggle: ({ open }: { open: boolean }) => void;
  onVisible: () => void;
}

const DELIVERY_AREA_VISIBILITY_THRESHOLD = 1;

export const DeliveryAndReturnsInfo = ({
  deliveryInfo,
  onReturnsPolicyLinkClick,
  onToggle,
  onVisible,
  returnsInfo,
}: DeliveryAndReturnsInfoStateProps & DeliveryAndReturnsInfoDispatchProps) => {
  const deliveryAreaRef = useImpressionRef<HTMLDivElement>(() => onVisible(), {
    threshold: DELIVERY_AREA_VISIBILITY_THRESHOLD,
  });

  const handleReturnsClick = (e: React.MouseEvent) => {
    if (getAnchorElementFromEvent(e)) {
      onReturnsPolicyLinkClick();
    }
  };

  if (!deliveryInfo && !returnsInfo) {
    return null;
  }

  const twoColumnLayout = Boolean(deliveryInfo && returnsInfo);

  return (
    <div css={styles.root} ref={deliveryAreaRef}>
      <Accordion
        id="delivery-returns-info"
        onToggle={onToggle}
        open={false}
        title={<h4 css={styles.title}>{copy.DELIVERY_AND_RETURNS}</h4>}
      >
        <div css={grid}>
          {deliveryInfo && (
            <section css={styles.section(twoColumnLayout)}>
              <h3 css={styles.heading}>{copy.DELIVERY_INFO}</h3>
              <div dangerouslySetInnerHTML={{ __html: deliveryInfo }} />
            </section>
          )}
          {returnsInfo && (
            <section css={styles.section(twoColumnLayout)}>
              <h3 css={styles.heading}>{copy.RETURNS}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: returnsInfo }}
                onClick={handleReturnsClick}
                role="presentation"
              />
            </section>
          )}
        </div>
      </Accordion>
    </div>
  );
};
