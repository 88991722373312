import React, { useEffect, useRef } from 'react';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import * as recommendations from '@noths/polaris-client-recommendations';
import {
  extraMaxWidthContainer,
  grid,
  maxWidthContainer,
} from '@noths/polaris-client-ribbons-base';
import { visuallyHidden } from '@noths/polaris-client-styles';
import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import { addRUMTiming } from '@noths/polaris-client-utils';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { ErrorNotification } from 'src/components/atoms/ErrorNotification/ErrorNotification';
import { ProductPrice } from 'src/components/atoms/ProductPrice/ProductPrice';
import { ProductRatingSummary } from 'src/components/atoms/ProductRatingSummary/ProductRatingSummary';
import { ProductTitleAndPartner } from 'src/components/atoms/ProductTitleAndPartner/ProductTitleAndPartner';
import { SupportShowInCMSBookmarklet } from 'src/components/atoms/SupportShowInCMSBookmarklet/SupportShowInCMSBookmarklet';
import { ApiErrorGuardContainer } from 'src/components/molecules/ApiErrorGuardContainer/ApiErrorGuardContainer';
import { BasketCTAButtonContainer } from 'src/components/molecules/BasketCTAButton/BasketCTAButtonContainer';
import { MAIN_PAGE_CTA_ID } from 'src/components/molecules/BasketCTAButton/elementIDs';
import { BreadcrumbContainer } from 'src/components/molecules/Breadcrumbs/containers/BreadcrumbsContainer';
import { DeliveryBlockContextID } from 'src/components/molecules/DeliveryBlock/components/DeliveryBlock';
import { DeliveryBlockContainer } from 'src/components/molecules/DeliveryBlock/containers/DeliveryBlockContainer';
import { DeliveryEventContainer } from 'src/components/molecules/DeliveryEvent/containers/DeliveryEventContainer';
import { ExpandablePanel } from 'src/components/molecules/ExpandablePanel/ExpandablePanel';
import { InventoryStatusLabel } from 'src/components/molecules/InventoryStatusLabel/InventoryStatusLabel';
import { MembershipBannerSection } from 'src/components/molecules/MembershipBannerSection/MembershipBannerSection';
import { PreventSwipeNavigation } from 'src/components/molecules/PreventSwipeNavigation/PreventSwipeNavigation';
import { StickySection } from 'src/components/molecules/StickySection/components/StickySection';
import { BasketModalContainer } from 'src/components/organisms/BasketModal/containers/BasketModalContainer';
import { DeliveryAndReturnsInfoContainer } from 'src/components/organisms/DeliveryAndReturnsInfo/containers/DeliveryAndReturnsInfoContainer';
import { LinkCarouselContainer } from 'src/components/organisms/LinkCarousel/containers/LinkCarouselContainer';
import { OverallRatingContainer } from 'src/components/organisms/OverallRating/containers/OverallRatingContainer';
import { PartnerStorefrontContainer } from 'src/components/organisms/PartnerStorefront';
import { ProductDescriptionContainer } from 'src/components/organisms/ProductDescription/containers/ProductDescriptionContainer';
import { ProductImagesCarouselContainer } from 'src/components/organisms/ProductImagesCarousel';
import { ProductOptionsContainer } from 'src/components/organisms/ProductOptions/ProductOptionsContainer';
import { ProductReviewsContainer } from 'src/components/organisms/ProductReviews';
import { RecommendedProductsShelfContainer } from 'src/components/organisms/RecommendedProductsShelf/containers/RecommendedProductsShelfContainer';
import { Placement } from 'src/constants/recommendations';
import { isProdLike } from 'src/environment';
import { CONTENT_SECTION_ID } from 'src/pages/[partnerId]/product/[productId]';
import { requestDeliveryZoneOptions } from 'src/redux/deliveryZoneOptions';
import { trackLoop54Event } from 'src/redux/loop54';
import { requestProductReviews } from 'src/redux/reviews';
import { requestRecommendedProducts } from 'src/redux/richRelevance';
import { recommendedProductsRequestComplete } from 'src/redux/richRelevance/actions';
import { type AppThunkDispatch, getStore } from 'src/redux/store';
import { trackPartnerLinkClick } from 'src/tracking/trackPartnerLinkClick';
import type { NextConfig } from 'src/types/nextConfig';
import { isOutOfStock, isSuspended } from 'src/utils/inventoryStatus';
import { getDisplayPageLoadPlacements } from 'src/utils/recommendations';
import {
  DELIVERY_HEADING,
  NEW_PRODUCT_LABEL,
  PRODUCT_DETAILS_HEADING,
  REVIEWS_HEADING,
} from './copy';
import { PRODUCT_REVIEWS_ID } from './elementIds';
import type { LegacyLayoutProps } from './types';
import * as styles from './LegacyLayout.styles';

const { publicRuntimeConfig } = getConfig() as NextConfig;

export const LegacyLayout = ({
  activeProductsPlacementHasData,
  basketMutationError,
  externalReferrer,
  getBasketForEditing,
  getBasketForViewing,
  outOfStockPlacementHasData,
  pageLiveRegionText,
  partnerHref,
  product,
  recentlyViewedPlacementHasData,
  richRelevanceSessionId,
  similarProductsPlacementHasData,
  updateEditedItem,
  updatePersonalisationOptionsFromBasketItem,
}: LegacyLayoutProps) => {
  const { dispatch } = getStore();
  const { query } = useRouter();
  const formRef = useRef(null);
  const suspended = isSuspended(product.availability, product.available);

  useEffect(() => {
    getUserAttributes()
      .then((attributes) =>
        recommendations.initRecommendations({
          apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
          env: isProdLike() ? 'production' : 'qa',
          sessionId: richRelevanceSessionId,
          userId: attributes?.sub,
        }),
      )
      .then(async () => {
        recommendations.setProductDetailPage(product.code, product.title);
        recommendations.setProductContextCategory(String(product.breadcrumbCategoryId!));

        const placements = isOutOfStock(product.inventoryStatus)
          ? [Placement.OutOfStock, Placement.SameSeller, Placement.RecentlyViewed]
          : [
              ...(externalReferrer ? [Placement.SimilarProducts] : []),
              Placement.RecentlyViewed,
              Placement.ActiveProducts,
              Placement.SameSeller,
            ];

        const placementData = await recommendations.getRecommendations(placements);

        const recommendationsRequests = placementData.map((placement) =>
          dispatch(requestRecommendedProducts(placement)),
        );

        await Promise.allSettled(recommendationsRequests);
        addRUMTiming('recommendationsLoaded');
      })
      .catch((e) => {
        console.warn(`Could not load recommendations on product details page: ${e.message}`);
      })
      .finally(() => {
        dispatch(recommendedProductsRequestComplete());
      });
  });

  useEffect(() => {
    if (query.editItem) {
      retrieveEditItem();
    } else {
      getBasketForViewing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.editItem]);

  useEffect(() => {
    if (product.code) {
      (dispatch as AppThunkDispatch)(requestDeliveryZoneOptions());
      (dispatch as AppThunkDispatch)(requestProductReviews({}));
    }
    (dispatch as AppThunkDispatch)(trackLoop54Event({ eventType: 'click' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveEditItem = async () => {
    updateEditedItem(query.editItem as string);
    await getBasketForEditing();
    updatePersonalisationOptionsFromBasketItem();
  };

  const {
    displayActiveProductsPlacement,
    displayOutOfStockPlacement,
    displayRecentlyViewedPlacement,
    displaySimilarProductsPlacement,
  } = getDisplayPageLoadPlacements({
    inventoryStatus: product.inventoryStatus,
    available: !suspended,
    showSimilarProducts: !externalReferrer,
    similarProductsPlacementHasData,
    recentlyViewedPlacementHasData,
    activeProductsPlacementHasData,
    outOfStockPlacementHasData,
  });

  return (
    <ApiErrorGuardContainer>
      <SupportShowInCMSBookmarklet productCode={product.code} />
      <div css={visuallyHidden} role="status">
        {pageLiveRegionText}
      </div>
      <div css={styles.wrapper}>
        <div css={[grid, maxWidthContainer]}>
          <div css={styles.breadcrumbContainer}>
            <BreadcrumbContainer />
          </div>
        </div>
        <PreventSwipeNavigation data-hide-placement={!displaySimilarProductsPlacement}>
          {displaySimilarProductsPlacement && (
            <div css={styles.similarProductInnerContainer}>
              <div css={[grid, extraMaxWidthContainer]}>
                <div css={[styles.similarProductShelf]}>
                  <RecommendedProductsShelfContainer
                    hideProductTitles
                    placementType={Placement.SimilarProducts}
                    titleAlignment="left"
                  />
                </div>
              </div>
            </div>
          )}
        </PreventSwipeNavigation>
        <PreventSwipeNavigation
          css={[grid, extraMaxWidthContainer]}
          data-hide-placement={!displayOutOfStockPlacement}
        >
          {displayOutOfStockPlacement && (
            <div css={styles.recommendationCarousel}>
              <RecommendedProductsShelfContainer placementType={Placement.OutOfStock} />
            </div>
          )}
        </PreventSwipeNavigation>
        <div css={[maxWidthContainer, styles.primaryInfoGrid]} id={CONTENT_SECTION_ID}>
          <div css={styles.primaryInfoItemTitle}>
            <ProductTitleAndPartner
              onPartnerLinkClick={trackPartnerLinkClick}
              partnerHref={partnerHref}
              partnerLocation={product.partner.location}
              partnerName={product.partner.name}
              title={product.title}
            />
          </div>
          <div css={styles.primaryInfoItemImages}>
            <ProductImagesCarouselContainer />
          </div>
          <div css={styles.primaryInfoItemInfo}>
            <ErrorNotification returnFocusToRef={formRef} shouldShow={basketMutationError} />
            {product.productRating && (
              <ProductRatingSummary
                averageRating={product.productRating}
                fragmentID={PRODUCT_REVIEWS_ID}
                numberOfReviews={product.numberOfReviews}
              />
            )}
            <ProductPrice
              onSale={product.onSale}
              options={product.options}
              personalisable={product.specialFeatures.personalisable}
              preSalePrices={product.preSalePrices}
              price={product.price}
            />
            <InventoryStatusLabel inventoryStatus={product.inventoryStatus} suspended={suspended} />
            <DeliveryEventContainer />
            {product.isNew && <p css={visuallyHidden}>{NEW_PRODUCT_LABEL}</p>}
            <ProductOptionsContainer ref={formRef} />
            <BasketCTAButtonContainer />
            <BasketModalContainer />
            {!isOutOfStock(product.inventoryStatus) && (
              <div css={styles.deliveryBlockWrapper}>
                <ExpandablePanel
                  collapseButtonText="Hide options"
                  expandButtonText="Show options"
                  onToggle={(expanded) =>
                    sendGAEvent({
                      event: 'custom_event',
                      event_category: DELIVERY_HEADING,
                      event_action: expanded ? 'Expand' : 'Collapse',
                      event_label: 'not set',
                    })
                  }
                >
                  <h2 css={styles.deliveryHeading}>{DELIVERY_HEADING}</h2>
                  <DeliveryBlockContainer contextID={DeliveryBlockContextID.DeliveryOptions} />
                </ExpandablePanel>
              </div>
            )}
          </div>
        </div>
        <PreventSwipeNavigation css={[grid, maxWidthContainer, styles.productDetailsGrid]}>
          <div css={styles.productDetailsWrapper}>
            <ExpandablePanel
              collapseButtonText="Read less"
              expandButtonText="Read more"
              onToggle={(expanded) =>
                sendGAEvent({
                  event: 'custom_event',
                  event_category: PRODUCT_DETAILS_HEADING,
                  event_action: expanded ? 'Expand' : 'Collapse',
                  event_label: 'not set',
                })
              }
            >
              <h2 css={styles.deliveryHeading}>{PRODUCT_DETAILS_HEADING}</h2>
              <ProductDescriptionContainer />
            </ExpandablePanel>
          </div>
        </PreventSwipeNavigation>
        <PreventSwipeNavigation
          css={[
            grid,
            extraMaxWidthContainer,
            styles.recommendedProductsContainer,
            styles.activeProductsContainer,
          ]}
          data-hide-placement={!displayActiveProductsPlacement}
        >
          {displayActiveProductsPlacement && (
            <div css={styles.recommendationCarousel}>
              <RecommendedProductsShelfContainer
                lazyImages
                placementType={Placement.ActiveProducts}
              />
            </div>
          )}
        </PreventSwipeNavigation>
        <div css={[grid, maxWidthContainer, styles.productReviewsGrid]} id={PRODUCT_REVIEWS_ID}>
          <h2 css={styles.reviewsHeading}>{REVIEWS_HEADING}</h2>
          <div css={styles.productReviewsWrapper}>
            <ProductReviewsContainer />
          </div>
          <div css={styles.overallRatingWrapper}>
            <OverallRatingContainer />
          </div>
        </div>
        <div css={[grid, maxWidthContainer]}>
          <MembershipBannerSection />
        </div>
        <div css={[grid, maxWidthContainer]}>
          <DeliveryAndReturnsInfoContainer />
        </div>
        <div css={styles.storefrontWrapper}>
          <PartnerStorefrontContainer />
        </div>
        <div css={[grid, maxWidthContainer]}>
          <div css={styles.linkCarouselWrapper}>
            <LinkCarouselContainer />
          </div>
        </div>
        <PreventSwipeNavigation
          css={[grid, extraMaxWidthContainer, styles.recommendedProductsContainer]}
          data-hide-placement={!displayRecentlyViewedPlacement}
        >
          {displayRecentlyViewedPlacement && (
            <div css={styles.recommendationCarousel}>
              <RecommendedProductsShelfContainer
                lazyImages
                placementType={Placement.RecentlyViewed}
              />
            </div>
          )}
        </PreventSwipeNavigation>
        <StickySection triggerElementId={MAIN_PAGE_CTA_ID}>
          <BasketCTAButtonContainer />
        </StickySection>
      </div>
    </ApiErrorGuardContainer>
  );
};
