import type { DatePartsMap } from '@noths/polaris-client-utils';
import { formatDate } from '@noths/polaris-client-utils';

/* 
 dateFormatHelper

 This helper prepends 0 to date digits where required & returns a string with a
 dd/mm/yyyy format

 This is because US date localization is default on a node server &
  it sets the day/ month digits as short as possible.
*/

export const dateFormatHelper = (date: string) => {
  const dateParts = formatDate(date, {
    month: 'numeric',
    locale: 'en-GB',
    formatToParts: true,
  }) as DatePartsMap;

  const day = dateParts.day.value.length < 2 ? `0${dateParts.day.value}` : `${dateParts.day.value}`;
  const month =
    dateParts.month.value.length < 2 ? `0${dateParts.month.value}` : `${dateParts.month.value}`;
  const year = dateParts.year.value;

  return `${day}/${month}/${year}`;
};
