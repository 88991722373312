import { css } from 'styled-components';

export const addToBasketAlert = css`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  &[data-visible='true'] {
    display: block;
  }
`;

export const addToBasketAlertTextBox = css`
  &[data-has-focus='true'] {
    box-shadow: 0 0 0 2px var(--neutral-white-base), 0 0 0 4px var(--brand-purple-base);
  }
`;
