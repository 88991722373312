import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const root = css`
  padding: 0 0 ${spacing.sm}px 0;

  h1 {
    margin: 0 0 ${spacing.sm}px 0;
    font-weight: ${fonts.PRIMARY_REGULAR.weight};
    font-size: ${text.HEADING.MD.fontSize}px;
    line-height: 1.33;

    ${mediaQuery(
      { min: minBreakpoints.M },
      css`
        font-size: ${text.HEADING.LG.fontSize}px;
        line-height: 1.29;
      `,
    )};
  }

  a {
    margin: 0 0 ${spacing.sm}px 0;
  }
`;

export const nowrapContainer = css`
  display: inline-block;
`;
