import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { PersonalisationBreakdown } from 'src/components/molecules/PriceBreakdown/PriceBreakdown';
import type { SelectedPersonalisationsRecord } from 'src/redux/personalisation/types';
import { getPersonalisationAmount } from 'src/utils/price';

export const getPersonalisationBreakdown = (
  personalisations: SelectedPersonalisationsRecord,
  currency: CurrencyCode,
): PersonalisationBreakdown => {
  const defaults = { amountAdded: 0, amountRemoved: 0 };

  return Object.values(personalisations).reduce((totalBreakdown, optionSelection) => {
    const currentBreakdown = optionSelection!.reduce(({ amountAdded, amountRemoved }, opt) => {
      const { isNegative, value } = getPersonalisationAmount(currency, opt.priceModifier);

      return {
        amountAdded: isNegative ? amountAdded : amountAdded + value,
        amountRemoved: isNegative ? amountRemoved + value : amountRemoved,
      };
    }, defaults);

    return {
      amountAdded: totalBreakdown.amountAdded + currentBreakdown.amountAdded,
      amountRemoved: totalBreakdown.amountRemoved + currentBreakdown.amountRemoved,
    };
  }, defaults);
};
