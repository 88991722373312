import React from 'react';
import { IconLoyalty } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './PersonalisationLabel.styles';

interface PersonalisationLabelProps {
  text: string;
}

export const PersonalisationLabel = ({ text }: PersonalisationLabelProps) => {
  return (
    <div css={styles.root}>
      <IconLoyalty color="var(--brand-bluple-base)" height={16} width={16} />
      <b>{text}</b>
    </div>
  );
};
