import type { MutableRefObject } from 'react';
import React, { useRef } from 'react';
import * as R from 'ramda';

import { ExpandablePanel } from 'src/components/molecules/ExpandablePanel/ExpandablePanel';
import type { InputOnChangeArgs } from 'src/components/organisms/Personalisation/components/Input';
import { PERSONALISATION_OPTIONS_ID } from 'src/components/organisms/Personalisation/constants/elementIds';
import { MIN_PERSONALISATIONS_OPTIONS_FOR_EXPANDING } from 'src/components/organisms/Personalisation/constants/values';
import * as styles from 'src/components/organisms/Personalisation/styles/Personalisation.styles';
import {
  getFieldPosition,
  getProgressMilestone,
} from 'src/components/organisms/Personalisation/utils';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
  SelectionPayload,
  TrackedEvents,
} from 'src/redux/personalisation';
import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import type { FieldEventType } from 'src/types/googleAnalytics';
import { PersonalisationOption } from './PersonalisationOption';

export interface PersonalisationOptionsDispatchProps {
  deselectPersonalisation: (selection: SelectionPayload) => void;
  selectPersonalisation: (selection: SelectionPayload) => void;
  setPersonalisationExpanded: (expanded: boolean) => void;
  trackInteraction: (payload: InteractionTrackingEvent) => void;
  trackInteractionError: (payload: InteractionErrorTrackingEvent) => void;
  trackProgressMilestone: (payload: ProgressTrackingEvent) => void;
}

export interface PersonalisationOptionsStateProps {
  isExpanded?: boolean;
  options: CatServiceAPIProductOption[];
  outstandingSelections: CatServiceAPIProductOption[];
  totalRequiredPersonalisations: number;
  trackedEvents: TrackedEvents;
}

interface PersonalisationOptionsOwnProps {
  displayValidation?: boolean;
}

type PersonalisationOptionsProps = PersonalisationOptionsDispatchProps &
  PersonalisationOptionsStateProps &
  PersonalisationOptionsOwnProps;

type HandleInputSelectionFnParams = CatServiceAPIProductOption &
  Pick<
    PersonalisationOptionsProps,
    | 'deselectPersonalisation'
    | 'options'
    | 'outstandingSelections'
    | 'selectPersonalisation'
    | 'totalRequiredPersonalisations'
    | 'trackedEvents'
    | 'trackInteraction'
    | 'trackInteractionError'
    | 'trackProgressMilestone'
  > & {
    index: number;
  };

const handleInputSelection = R.curry(
  (
    {
      deselectPersonalisation,
      id,
      name,
      options,
      outstandingSelections,
      required,
      selectPersonalisation,
      totalRequiredPersonalisations,
      trackInteraction,
      trackInteractionError,
      trackProgressMilestone,
      trackedEvents,
    }: HandleInputSelectionFnParams,
    { isValid, value }: InputOnChangeArgs,
  ) => {
    const payload: SelectionPayload = {
      optionId: id,
      optionValue: {
        id,
        name: value,
        priceModifier: null,
        isCustomInput: true,
        isValid,
      },
      type: 'single-selection',
    };

    if (!value) {
      return deselectPersonalisation(payload);
    }

    selectPersonalisation(payload);

    const hasTrackedCharacterLimitError = trackedEvents.error['Character Limit Error'][name];

    if (isValid) {
      trackInteraction({
        field_position: getFieldPosition(options, { id }),
        field_name: name,
        field_action: 'Field Input',
        field_type: 'input field',
        field_value: null,
        required_field: required,
      });
    } else if (!hasTrackedCharacterLimitError) {
      trackInteractionError({
        field_position: getFieldPosition(options, { id }),
        field_name: name,
        field_action: 'Character Limit Error',
        field_type: 'input field',
        required_field: required,
      });
    }

    const milestone = getProgressMilestone({
      totalRequiredPersonalisations,
      totalOutstandingSelections: outstandingSelections.length,
      trackedEvents,
      fieldName: name,
      isRequiredPersonalisation: required,
    });

    if (milestone) {
      trackProgressMilestone({
        field_position: getFieldPosition(options, { id }),
        field_name: name,
        field_action: milestone,
        field_type: 'input field',
        field_value: null,
        required_field: true,
      });
    }
  },
);

export const PersonalisationOptions = ({
  deselectPersonalisation,
  displayValidation,
  isExpanded = false,
  options,
  outstandingSelections,
  selectPersonalisation,
  setPersonalisationExpanded,
  totalRequiredPersonalisations,
  trackInteraction,
  trackInteractionError,
  trackProgressMilestone,
  trackedEvents,
}: PersonalisationOptionsProps) => {
  const wrapperRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const expandable = options.length >= MIN_PERSONALISATIONS_OPTIONS_FOR_EXPANDING;

  const handleFocus = (option?: CatServiceAPIProductOption, fieldType?: FieldEventType) => {
    if (fieldType && option && !trackedEvents.focus[option.name]) {
      trackInteraction({
        field_position: getFieldPosition(options, option),
        field_name: option.name,
        field_action: 'Field Focus',
        field_type: fieldType,
        field_value: null,
        required_field: option.required,
      });
    }
  };

  const content = (
    <ul
      css={styles.personalisationOptions}
      data-testid={PERSONALISATION_OPTIONS_ID}
      ref={wrapperRef}
    >
      {options.map((option, index) => {
        if (option.type === 'selection') {
          return (
            <PersonalisationOption
              displayValidation={displayValidation}
              key={option.id}
              onFocus={handleFocus}
              option={option}
              wrapperRef={wrapperRef}
            />
          );
        }

        return (
          <PersonalisationOption
            displayValidation={displayValidation}
            key={option.id}
            onChange={handleInputSelection({
              deselectPersonalisation,
              options,
              outstandingSelections,
              selectPersonalisation,
              totalRequiredPersonalisations,
              trackedEvents,
              trackInteraction,
              trackInteractionError,
              trackProgressMilestone,
              ...option,
              index,
            })}
            onFocus={handleFocus}
            option={option}
            wrapperRef={wrapperRef}
          />
        );
      })}
    </ul>
  );

  return expandable ? (
    <ExpandablePanel
      collapseButtonText="Hide options"
      expandButtonText="Show options"
      onToggle={() => {
        setPersonalisationExpanded(!isExpanded);
        if (isExpanded) {
          wrapperRef.current?.scrollIntoView();
        }
      }}
      open={isExpanded}
    >
      {content}
    </ExpandablePanel>
  ) : (
    content
  );
};
