import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

const font = css`
  ${fontSize(text.BODY.MD)};
  color: var(--neutral-black-base);
`;

export const accordionStyles = css`
  grid-column: span 12;
`;

export const accordionTitle = css`
  display: flex;
  justify-content: space-between;
  padding-right: ${spacing.sm}px;
`;

export const accordionTotalAmount = css`
  margin-left: auto;
`;

export const expandablePersonalisationBreakdown = css`
  grid-column: span 12;
  row-gap: ${pxToRem(spacing.xs)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: ${pxToRem(spacing.md)};
`;

const gridLabel = css`
  grid-column: 1 / 3;
`;

const gridPrice = css`
  grid-column: 4 / -1;
`;

export const priceBreakdown = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${pxToRem(spacing.xs)};
`;

export const priceLabel = css`
  ${font};
  ${gridLabel};
  margin: 0;
`;

export const priceAmount = css`
  ${font};
  ${gridPrice};
  margin: 0;
  display: flex;
  align-items: center;
`;

export const totalDivider = css`
  border-top: 1px solid black;
  padding-top: ${pxToRem(spacing.base)};
`;

export const personalisationBreakdown = css`
  grid-column: span 12;
  row-gap: ${pxToRem(spacing.xs)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: ${pxToRem(spacing.md)};
`;

export const personalisationBreakdownCopy = css`
  margin: 0 0 0 ${pxToRem(-spacing.md)};
`;

export const personalisationWrapper = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const removedPersonalisation = css`
  grid-row: 3;
`;

export const priceAmountMinusSymbol = css`
  margin-left: 0;
  margin-right: ${pxToRem(2)};
  ${fontSize(text.BODY.SM)};

  &[data-is-multi-digit='true'] {
    margin-left: ${pxToRem(-spacing.sm)};
  }
`;

export const priceAmountPlusSymbol = css`
  ${/* Double ampersand selector to increase specificity and override existing styles */ ''}
  && {
    ${fontSize(text.HEADING.SM)};
  }
`;

export const totalLabel = css`
  ${priceLabel};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
`;

export const totalPrice = css`
  ${priceAmount};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
`;
