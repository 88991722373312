import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Reviews } from 'src/types/reviews';
import { logger } from 'src/utils/serverLogger';
import { requestProductReviews } from './thunks';

export interface ProductReviewsState extends Reviews {
  error: SerializedError | null;
  trackedPageNumberPaginations: Record<number, boolean>;
}

export const initialState: Readonly<ProductReviewsState> = {
  error: null,
  items: [],
  pagination: null,
  trackedPageNumberPaginations: {},
};

export const { actions, reducer } = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    onProductReviewsPagination: (_, _payload: PayloadAction<number>) => {},
    onProductReviewsExpandReadMore: (_, _payload: PayloadAction<boolean>) => {},
    onProductReviewsNextPage: (state) => {
      const nextPage = state.pagination!.currentPage + 1;

      if (!state.trackedPageNumberPaginations[nextPage]) {
        state.trackedPageNumberPaginations[nextPage] = true;
      }
    },
    onProductReviewsProductRatingSystem: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestProductReviews.fulfilled, (state, { payload }) => {
        const { pagination, reviews } = payload;

        return {
          items: reviews,
          pagination,
          error: null,
          trackedPageNumberPaginations: state.trackedPageNumberPaginations,
        };
      })
      .addCase(requestProductReviews.rejected, (state, action) => {
        logger.error('Error requesting product reviews', action.error);

        state.error = action.error;
      });
  },
});
