import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { carouselVisible } from 'src/components/organisms/LinkCarousel/modules/actions';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { CarouselVisibleImpression } from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [carouselVisible.type]: () => {
    sendGAEvent<CarouselVisibleImpression>({
      event: 'custom_event',
      event_category: 'Related Category',
      event_action: 'Impression',
      event_label: 'not set',
    });
  },
};
