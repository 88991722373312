import type { InventoryStatus } from '@noths/polaris-dev-ts-types';

import { isOutOfStock } from './inventoryStatus';

interface GetDisplayPageLoadPlacements {
  activeProductsPlacementHasData: boolean;
  available: boolean;
  inventoryStatus: InventoryStatus;
  outOfStockPlacementHasData: boolean;
  recentlyViewedPlacementHasData: boolean;
  showSimilarProducts: boolean | undefined;
  similarProductsPlacementHasData: boolean;
}

export const getDisplayPageLoadPlacements = ({
  activeProductsPlacementHasData,
  available,
  inventoryStatus,
  outOfStockPlacementHasData,
  recentlyViewedPlacementHasData,
  showSimilarProducts,
  similarProductsPlacementHasData,
}: GetDisplayPageLoadPlacements) => {
  const displaySimilarProductsPlacement =
    showSimilarProducts &&
    !isOutOfStock(inventoryStatus) &&
    available &&
    similarProductsPlacementHasData;

  const displayActiveProductsPlacement =
    !isOutOfStock(inventoryStatus) && activeProductsPlacementHasData;

  const displayOutOfStockPlacement = isOutOfStock(inventoryStatus) && outOfStockPlacementHasData;

  return {
    displaySimilarProductsPlacement,
    displayRecentlyViewedPlacement: recentlyViewedPlacementHasData,
    displayActiveProductsPlacement,
    displayOutOfStockPlacement,
  };
};
