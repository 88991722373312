import {
  fontSize,
  primaryRegularFont,
  primarySemiBoldFont,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { applyKeyframe, pxToRem } from '@noths/polaris-client-styles';
import { css, keyframes } from 'styled-components';

const { fonts, responsiveText, spacing, text } = theme;

export const deliveryInfo = css`
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveText.BODY.SM};
  margin: 0 0 ${pxToRem(spacing.md)} 0;
`;

export const deliveryBlock = css`
  background: var(--brand-purple-30);
  border-radius: ${pxToRem(10)};
  padding: ${pxToRem(spacing.xl)};

  hr {
    margin: ${spacing.md}px 0;
    border: 0;
    height: 1px;
    color: var(--brand-purple-70);
    background-color: var(--brand-purple-70);
  }
`;

export const countrySelect = css`
  margin-bottom: ${pxToRem(spacing.xs)};
`;

export const countryInfoWrapper = css`
  display: grid;
  grid-template-columns: auto 1fr;

  & > span {
    margin-top: 1px;
    align-self: start;
  }
`;

export const countryInfoMessage = css`
  ${primaryRegularFont}
  ${fontSize(text.BODY.XS)}

  margin: 0 0 0 ${pxToRem(spacing.base)};
`;

export const deliveryOptionsList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const deliveryOption = css`
  margin: 0 0 ${pxToRem(spacing.sm)};

  &:last-child {
    margin: 0;
  }
`;

export const deliveryNotAvailableMessage = css`
  margin: 0;
  padding: 0;
  ${primarySemiBoldFont}
`;

export const deliveryOptionSkeletonWrapper = css`
  display: grid;
  column-gap: ${pxToRem(spacing.sm)};
  grid-template-columns: auto ${pxToRem(50)};
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeIn = css`
  animation: ${applyKeyframe(fadeInAnimation)} 400ms linear;
`;
