import { getProductImageURL } from '@noths/polaris-client-utils';

import type { ProductState } from 'src/redux/product';
import type { ProductJSONLDSchema } from 'src/types/productJSONLDSchema';
import { getBreadcrumbsListItems } from './getBreadcrumbs';
import { isOutOfStock } from './inventoryStatus';
import { removeAllHtmltags } from './removeAllHtmlTags';

export const getProductJSONLDSchema = (product: ProductState): ProductJSONLDSchema => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.title,
    description: removeAllHtmltags(
      product.description +
        product.otherInformation +
        product.materials +
        product.variationsAvailable +
        product.dimensions,
    ),
    image: product.images?.[0]?.href ? getProductImageURL(product.images[0].href, 'preview') : '',
    sku: product.code,
    brand: { '@type': 'Brand', name: product.partner.name },
    offers: {
      '@type': 'Offer',
      priceCurrency: product.price.currency,
      price: String(product.price.amount / 100),
      availability: isOutOfStock(product.inventoryStatus)
        ? 'https://schema.org/OutOfStock'
        : 'https://schema.org/InStock',
      url: product.links?.[0]?.href || '',
    },
    breadcrumbs: {
      '@type': 'BreadcrumbList',
      itemListElement: getBreadcrumbsListItems(product),
    },
    ...(product.numberOfReviews > 0 && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: product.productRating as number,
        reviewCount: product.numberOfReviews,
      },
    }),
  };
};
