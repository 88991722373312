import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const starRatingContainer = css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const starRating = css`
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  margin-right: ${pxToRem(spacing.base)};

  &:nth-child(5) {
    margin-right: ${pxToRem(spacing.xs)};
  }
`;

export const reviewsText = (size: 'XS' | 'SM') => {
  return css`
    font-size: ${pxToRem(text.BODY[size].fontSize)};
    line-height: ${pxToRem(text.BODY[size].lineHeight)};
    font-weight: bold;
  `;
};

export const largeNumber = () => {
  return css`
    font-size: 1.5em;
  `;
};
