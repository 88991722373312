import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { useFirstMountState } from 'react-use';

interface UseThumbnailFocusAndScrollProps {
  currentImage: number;
  shouldScrollThumbnailIntoView: boolean;
  thumbnailRefs: MutableRefObject<HTMLAnchorElement[] | null[]>;
  thumbnailsHaveKeyboardFocus: boolean;
}

export const useThumbnailFocusAndScroll = ({
  currentImage,
  shouldScrollThumbnailIntoView,
  thumbnailRefs,
  thumbnailsHaveKeyboardFocus,
}: UseThumbnailFocusAndScrollProps) => {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    const currentThumbnail = thumbnailRefs.current[currentImage];

    if (currentThumbnail && !isFirstMount) {
      if (thumbnailsHaveKeyboardFocus) {
        currentThumbnail.focus();
      }

      if (shouldScrollThumbnailIntoView) {
        currentThumbnail.scrollIntoView({
          block: 'nearest',
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);
};
