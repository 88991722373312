import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { selectCurrentImage } from 'src/components/organisms/ProductImagesCarousel/modules/selectors';
import { actions } from 'src/components/organisms/ProductImagesCarousel/modules/slice';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type {
  ProductImagesCarouselExpand,
  ProductImagesCarouselImageClick,
  ProductImagesCarouselNavigate,
} from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [actions.openModal.type]: (_prevState, _nextState, action) => {
    const {
      payload: { location },
    } = action as ReturnType<typeof actions.openModal>;

    sendGAEvent<ProductImagesCarouselExpand>({
      event: 'custom_event',
      event_action: 'Expand image',
      event_category: 'Image carousel',
      event_label: location,
    });
  },
  [actions.setCurrentImage.type]: (prevState, nextState, action) => {
    const {
      payload: { location, navigationType },
    } = action as ReturnType<typeof actions.setCurrentImage>;

    if (location && navigationType === 'click') {
      sendGAEvent<ProductImagesCarouselImageClick>({
        event: 'custom_event',
        event_action: 'Image click',
        event_category: 'Image carousel',
        event_label: location,
      });
    }

    if (location && navigationType === 'swipe') {
      const direction: ProductImagesCarouselNavigate['event_action'] =
        selectCurrentImage(nextState) > selectCurrentImage(prevState)
          ? 'Next image'
          : 'Previous image';

      sendGAEvent(<ProductImagesCarouselNavigate>{
        event: 'custom_event',
        event_action: direction,
        event_category: 'Image carousel',
        event_label: location,
      });
    }
  },
  [actions.nextImage.type]: (_prevState, _nextState, action) => {
    const {
      payload: { location },
    } = action as ReturnType<typeof actions.nextImage>;

    sendGAEvent(<ProductImagesCarouselNavigate>{
      event: 'custom_event',
      event_action: 'Next image',
      event_category: 'Image carousel',
      event_label: location,
    });
  },
  [actions.prevImage.type]: (_prevState, _nextState, action) => {
    const {
      payload: { location },
    } = action as ReturnType<typeof actions.prevImage>;

    sendGAEvent(<ProductImagesCarouselNavigate>{
      event: 'custom_event',
      event_action: 'Previous image',
      event_category: 'Image carousel',
      event_label: location,
    });
  },
};
