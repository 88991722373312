import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  grid-column: 1 / -1;
  margin-bottom: ${spacing.xxl}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin: 36px 0;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
      margin: 40px 0;
    `,
  )}
`;
