import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { Feedback, fieldFeedbackContainer } from '@noths/polaris-client-ribbons-design-system';

import { QUANTITY_COPY, RANGE_OVERFLOW_COPY } from './copy';
import * as styles from './Quantity.styles';

interface QuantityProps {
  defaultValue?: string | number | readonly string[];
  maxValue?: number;
  minValue?: number;
  onChange: (value: number, valid: boolean) => void;
}

export const ERROR_ID = 'quantity__error';
export const QUANTITY_ID = 'quantity';

const DISALLOWED_CHARACTERS = '-.eE';

export const Quantity = ({
  defaultValue = 1,
  maxValue = 10000,
  minValue = 1,
  onChange,
}: QuantityProps) => {
  const [value, setValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setValue('1');
      onChange(1, true);
    }
  };

  const handleChange = ({ target: { validity, value } }: ChangeEvent<HTMLInputElement>) => {
    const validationError =
      parseInt(value) > maxValue ? `${RANGE_OVERFLOW_COPY} ${maxValue}` : null;

    if (validity.rangeUnderflow) {
      return;
    }

    setValue(value);
    setErrorMessage(validationError);

    if (value !== '') {
      onChange(parseInt(value), !validationError);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (DISALLOWED_CHARACTERS.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div css={styles.quantityContainer} data-has-error={!!errorMessage}>
      <label css={styles.quantityLabel} htmlFor={QUANTITY_ID}>
        {QUANTITY_COPY}
      </label>
      <input
        aria-describedby={ERROR_ID}
        aria-invalid={!!errorMessage}
        aria-required
        css={styles.quantityInput}
        id={QUANTITY_ID}
        min={minValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        step="1"
        type="number"
        value={value}
      />
      <div css={fieldFeedbackContainer} id={ERROR_ID}>
        {errorMessage && <Feedback error={!!errorMessage} iconAlign="right" value={errorMessage} />}
      </div>
    </div>
  );
};
