import { createSelector } from '@reduxjs/toolkit';

import type {
  ContactFormServerErrors,
  PartnerStorefrontState,
} from 'src/components/organisms/PartnerStorefront/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

export const selectContactFormServerErrors = createSelector(
  [(state: ReduxApplicationState) => state.partnerStorefront.error],
  (serverErrors: PartnerStorefrontState['error']): ContactFormServerErrors => ({
    email: serverErrors?.email?.join(', '),
    name: serverErrors?.name?.join(', '),
    message: serverErrors?.message?.join(', '),
    rateLimitMessage: serverErrors?.rateLimitMessage,
  }),
);
