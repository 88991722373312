import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import getConfig from 'next/config';
import type { ParsedUrlQueryInput } from 'querystring';
import qs from 'querystring';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { NextConfig } from 'src/types/nextConfig';
import type { Review, ReviewPagination } from 'src/types/reviews';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const RAR_DATA_ENDPOINT = publicRuntimeConfig.endpoints.rarData;

export interface ProductReviewsAPIResponse {
  pagination: ReviewPagination;
  reviews: Review[];
}

export interface RequestProductReviewsOptions {
  query?: ParsedUrlQueryInput;
}

export const requestProductReviews = createAsyncThunk<
  ProductReviewsAPIResponse,
  RequestProductReviewsOptions
>('productReviews/requestProductReviews', async ({ query }, { getState }) => {
  const { product } = getState() as ReduxApplicationState;
  const querystring = qs.encode({
    page: 1,
    perPage: 3,
    ...query,
  });

  const response = await fetchJSONWithTimeout(`${RAR_DATA_ENDPOINT}${product.code}?${querystring}`);
  const { pagination, reviews }: ProductReviewsAPIResponse = await response.json();

  return { pagination, reviews };
});
