import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { ERROR_PRODUCT_NOT_FOUND } from 'src/constants/errors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { ErrorGuard } from './ErrorGuard';

export const mapStateToProps = ({ product }: ReduxApplicationState) => ({
  error: product.error,
  notFoundErrorList: [ERROR_PRODUCT_NOT_FOUND],
});

const connector = connect(mapStateToProps);

export type ApiErrorGuardContainerProps = ConnectedProps<typeof connector>;

export const ApiErrorGuardContainer = connector(ErrorGuard);
