import { priceFormatter } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { CatServiceAPIProductOptionPriceModifier } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

interface StrategyProps extends CatServiceAPIProductOptionPriceModifier {
  currency: CurrencyCode;
}

const priceModifierStrategy = {
  fixed: ({ currency, prices }: StrategyProps) => prices.find((p) => p.currency === currency),
  percentage: ({ currency, prices }: StrategyProps) => prices.find((p) => p.currency === currency),
};

const getPriceModifierPrice = (
  currency: CurrencyCode,
  modifier: CatServiceAPIProductOptionPriceModifier,
) => priceModifierStrategy[modifier.type]({ ...modifier, currency });

export const getPersonalisationAmount = (
  currency: CurrencyCode,
  price: number | CatServiceAPIProductOptionPriceModifier | null,
) => {
  if (price == null) {
    return {
      isNegative: false,
      value: 0,
    };
  }

  const priceAmount =
    typeof price === 'number' ? price : getPriceModifierPrice(currency, price)?.amount ?? 0;

  return {
    isNegative: priceAmount < 0,
    value: priceAmount,
  };
};

export const getFormattedPersonalisationAmount = (
  currency: CurrencyCode,
  price: number | CatServiceAPIProductOptionPriceModifier | null,
) => {
  const { isNegative, value } = getPersonalisationAmount(currency, price);

  return {
    isNegative,
    value: priceFormatter(currency, value),
  };
};

export const formatIntegerPriceToDecimal = (amount: number) => {
  const price = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 2,
  }).format(amount / 100);

  return price.replaceAll(',', '');
};
