import {
  createGrid,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, responsiveText, spacing } = theme;

export const wrapper = css`
  margin: ${spacing.xxl}px 0 80px;

  ${mediaQuery(
    {
      max: maxBreakpoints.M,
    },
    css`
      margin-top: ${spacing.md}px;
    `,
  )}
`;

export const breadcrumbContainer = css`
  grid-column: 1 / -1;
  margin-bottom: ${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin-bottom: ${spacing.xxl}px;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const similarProductShelf = css`
  grid-column: 1 / -1;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const similarProductInnerContainer = css`
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: -${spacing.md}px;

  ${/* A pseudo-element to hide the top box-shadow. */ ''}
  &:before {
    content: '';
    height: 2px;
    top: -2px;
    width: 100%;
    position: absolute;
    background: var(--neutral-white-base);
    z-index: 1;
  }

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin-top: -${spacing.xxl}px;
      margin-bottom: 42px;
    `,
  )}
`;

export const primaryInfoGrid = css`
  // ensure correct z-index and interactivity of product image carousel
  isolation: isolate;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      ${createGrid({ numberOfColumns: 12, rowGapSmall: 0, rowGapLarge: 0 })};
      display: grid;
      grid-template-areas:
        'images images images images images images title title title title title title'
        'images images images images images images info info info info info info';
      grid-template-rows: auto 1fr;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-template-areas:
        'left images images images images images images images title title title right'
        'left images images images images images images images info info info right';
      margin-bottom: ${spacing.xxl}px;
    `,
  )}
`;

export const primaryInfoItemTitle = css`
  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      position: sticky;
      top: 0;
      z-index: -1;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-area: title;
    `,
  )}
`;

export const primaryInfoItemImages = css`
  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      position: sticky;
      top: 0;
      z-index: -1;
      isolation: isolate;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-area: images;
    `,
  )}
`;

export const primaryInfoItemInfo = css`
  z-index: 2;

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      background-color: var(--neutral-white-base);
      margin: 0 -${spacing.md}px;
      padding: ${spacing.xxl}px ${spacing.md}px 0;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-area: info;
    `,
  )}
`;

export const recommendedProductsContainer = css`
  &[data-hide-placement='true'] {
    margin-bottom: ${spacing.md}px;

    ${mediaQuery(
      { min: minBreakpoints.L },
      css`
        margin-bottom: ${spacing.xxxxl}px;
      `,
    )}
  }
`;

/*
  Active products placement needs additional margin vs the other placements due to partner storefront spacing/negative
  margins.
*/
export const activeProductsContainer = css`
  margin-bottom: ${spacing.xxxxl + spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-bottom: ${spacing.xxxxl}px;
    `,
  )}

  &[data-hide-placement='true'] {
    margin-bottom: ${spacing.xxxxl + spacing.md}px;

    ${mediaQuery(
      { min: minBreakpoints.L },
      css`
        margin-bottom: ${spacing.xxxxl * 2 + 4}px;
      `,
    )}
  }
`;

export const recommendationCarousel = css`
  grid-column: span 12;
  border-bottom: 1px solid var(--neutral-black-30);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-bottom: 80px;
      grid-column: 2 / -2;
    `,
  )}
`;

export const productReviewsGrid = css`
  grid-row-gap: ${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-row-gap: ${spacing.lg}px;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-row-gap: ${spacing.xl}px;
    `,
  )}
`;

export const linkCarouselWrapper = css`
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const productDetailsGrid = css`
  grid-template-rows: auto 1fr;
  margin-top: ${spacing.xxl}px;
  grid-row-gap: ${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-row-gap: ${spacing.lg}px;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-row-gap: ${spacing.xl}px;
    `,
  )}
`;

export const productDetailsWrapper = css`
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: 1/-1;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2/-2;
    `,
  )}
`;

export const deliveryHeading = css`
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveText.HEADING.SM};
  margin: 0 0 ${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin: 0 0 ${spacing.lg}px;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: 0 0 ${spacing.xl}px;
    `,
  )}
`;

export const deliveryBlockWrapper = css`
  grid-column: span 12;

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      margin-bottom: ${spacing.xxl}px;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span 6;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 9 / span 3;
    `,
  )}
`;

export const reviewsHeading = css`
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveText.HEADING.SM};
  margin: 0;
  grid-row: 1;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-row: 1;
      grid-column: span 12;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const productReviewsWrapper = css`
  min-width: 0;
  grid-column: 1 / -1;
  grid-row: 3;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-row: 2;
      grid-column: span 6;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / span 7;
    `,
  )}
`;

export const overallRatingWrapper = css`
  grid-row: 2;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span 6;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 9 / span 3;
    `,
  )}
`;

export const storefrontWrapper = css`
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--neutral-black-10);
  margin-bottom: 48px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin-bottom: 176px;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-bottom: 212px;
    `,
  )}
`;
