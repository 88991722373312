import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { spacing } = theme;

export const starRating = css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  margin-bottom: ${spacing.md}px;
  color: inherit;
  text-decoration: inherit;
`;
