import { batch, connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { Dispatch } from '@reduxjs/toolkit';

import type {
  DeliveryBlockDispatchProps,
  DeliveryBlockOnCountryChangeArgs,
  DeliveryBlockStateProps,
} from 'src/components/molecules/DeliveryBlock/components/DeliveryBlock';
import { DeliveryBlock } from 'src/components/molecules/DeliveryBlock/components/DeliveryBlock';
import {
  selectGroupedCountriesForDropdown,
  selectProductDeliveryOptionsForZone,
} from 'src/components/molecules/DeliveryBlock/modules/selectors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  actions as deliveryZoneOptionsActions,
  selectDecoratedDeliveryOptions,
  selectDeliveryZoneOptionsIsLoading,
  selectSelectedCountry,
  selectSelectedZone,
} from 'src/redux/deliveryZoneOptions';
import { selectHasSelectedCountriesDeliveryOnly, selectHasUKDeliveryOnly } from 'src/redux/product';
import { selectProduct } from 'src/redux/selectors';
import { SELECTED_COUNTRIES_DELIVERY_ONLY, UK_DELIVERY_ONLY } from '../constants/copy';

const getCountryInfoMessage = (state: ReduxApplicationState) => {
  if (selectHasUKDeliveryOnly(state)) {
    return UK_DELIVERY_ONLY;
  }
  if (selectHasSelectedCountriesDeliveryOnly(state)) {
    return SELECTED_COUNTRIES_DELIVERY_ONLY;
  }
  return null;
};

const mapStateToProps = (state: ReduxApplicationState): DeliveryBlockStateProps => {
  const product = selectProduct(state);
  const deliveryOptionsPlaceholderItems = selectProductDeliveryOptionsForZone(state);
  const deliveryAvailable = Boolean(deliveryOptionsPlaceholderItems.length);
  const countryInfoMessage = deliveryAvailable ? getCountryInfoMessage(state) : null;

  return {
    countryInfoMessage,
    groupedCountries: selectGroupedCountriesForDropdown(state),
    deliveryOptions: selectDecoratedDeliveryOptions(state),
    deliveryOptionsPlaceholderItems,
    selectedCountry: `${selectSelectedCountry(state)}|${selectSelectedZone(state)}`,
    isLoading: selectDeliveryZoneOptionsIsLoading(state),
    photoUploadRequired: product.specialFeatures.requiresPhotoUpload || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DeliveryBlockDispatchProps => ({
  onCountryChange: (payload: DeliveryBlockOnCountryChangeArgs) => {
    batch(() => {
      dispatch(deliveryZoneOptionsActions.setSelectedZone(payload.selectedZone));
      dispatch(deliveryZoneOptionsActions.setSelectedCountry(payload));
    });
  },
  onVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Delivery options',
      event_action: 'Impression',
      event_label: 'not set',
    });
  },
});

export const DeliveryBlockContainer = connect(mapStateToProps, mapDispatchToProps)(DeliveryBlock);
