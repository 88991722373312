import { createSlice } from '@reduxjs/toolkit';

export interface ProductInfoState {
  isBasketModalOpen: boolean;
}

export const initialState: ProductInfoState = {
  isBasketModalOpen: false,
};

export const { actions, reducer } = createSlice({
  name: 'productInfo',
  initialState,
  reducers: {
    openBasketModal: (state) => {
      state.isBasketModalOpen = true;
    },
    closeBasketModal: (state) => {
      state.isBasketModalOpen = false;
    },
  },
});
