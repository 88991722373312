import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  NextOrPreviousPayload,
  OpenModalPayload,
  ProductImagesState,
  SetCurrentImagePayload,
} from 'src/components/organisms/ProductImagesCarousel/types';

export const initialState: Readonly<ProductImagesState> = {
  navigatedBy: null,
  currentImage: 0,
  isModalOpen: false,
};

export const { actions, reducer } = createSlice({
  name: 'productImagesCarousel',
  initialState,
  reducers: {
    nextImage: (state, { payload }: PayloadAction<NextOrPreviousPayload>) => {
      state.currentImage =
        state.currentImage === payload.totalImages - 1 ? 0 : state.currentImage + 1;
      state.navigatedBy = 'click';
    },
    prevImage: (state, { payload }: PayloadAction<NextOrPreviousPayload>) => {
      state.currentImage =
        state.currentImage === 0 ? payload.totalImages - 1 : state.currentImage - 1;
      state.navigatedBy = 'click';
    },
    setCurrentImage: (state, action: PayloadAction<SetCurrentImagePayload>) => {
      state.currentImage = action.payload.imageIndex;
      state.navigatedBy = action.payload.navigationType;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    openModal: (state, _action: PayloadAction<OpenModalPayload>) => {
      state.isModalOpen = true;
    },
  },
});
