import type { Country } from '@noths/polaris-client-localisation';
import { countries, deliveryZoneCodes } from '@noths/polaris-client-localisation';
import type {
  DropDownOption,
  GroupedDropDownOptions,
} from '@noths/polaris-client-ribbons-design-system';
import { selectUserCountryCode } from '@noths/polaris-client-user-configuration';
import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectCountriesDeliveredTo } from 'src/redux/product';
import { selectProductDeliveryOptions } from 'src/redux/selectors';
import type { CatServiceAPIDeliveryOption } from 'src/services/cat-service-api/types/CatServiceAPIDeliveryOption';
import {
  OTHER_COUNTRIES_DROPDOWN_OPTIONS_LABEL,
  UK_DROPDOWN_OPTIONS_LABEL,
} from '../constants/copy';

export const selectProductDeliveryOptionsForZone = createSelector(
  [
    selectProductDeliveryOptions,
    ({ deliveryZoneOptions }: ReduxApplicationState) => deliveryZoneOptions.selectedZone,
  ],
  (productDeliveryOptions, selectedZone): CatServiceAPIDeliveryOption[] => {
    const zoneId = parseInt(
      Object.keys(deliveryZoneCodes).find((key) => deliveryZoneCodes[key] === selectedZone) || '',
    );

    return productDeliveryOptions?.filter(({ zone }) => zone.id === zoneId) ?? [];
  },
);

const createDropDownOptionFromCountry = ({
  countryCode,
  deliveryZoneCode,
  name,
}: Country): DropDownOption => ({
  optionLabel: name,
  optionValue: `${countryCode}|${deliveryZoneCode}${name === 'United Kingdom' ? `|UK` : ''}`,
});

const isUsersCountry =
  (userCountryCode?: Country['countryCode']) =>
  ({ countryCode }: Country) =>
    countryCode === userCountryCode;

const selectCountriesForDropdown = createSelector(
  [selectCountriesDeliveredTo, selectUserCountryCode],
  (countriesDeliveredTo, userCountryCode): DropDownOption[] => {
    const countryDropDownOptions = countriesDeliveredTo.map(createDropDownOptionFromCountry);

    if (countriesDeliveredTo.some(isUsersCountry(userCountryCode))) {
      return countryDropDownOptions;
    }

    const selectedCountryData = countries.find(isUsersCountry(userCountryCode));

    return (
      selectedCountryData ? [createDropDownOptionFromCountry(selectedCountryData)] : []
    ).concat(countryDropDownOptions);
  },
);

export type GroupedCountries = Partial<
  GroupedDropDownOptions<'United Kingdom' | 'Other countries'>
>;

export const selectGroupedCountriesForDropdown = createSelector(
  selectCountriesForDropdown,
  (countriesOptions): GroupedCountries => {
    const groups = R.groupBy<DropDownOption>((country): keyof GroupedCountries => {
      const [countryCode] = country.optionValue.split('|');
      const isUKCountryCode = countryCode === 'GB' || countryCode === 'UK';

      return isUKCountryCode ? UK_DROPDOWN_OPTIONS_LABEL : OTHER_COUNTRIES_DROPDOWN_OPTIONS_LABEL;
    })(countriesOptions);

    return groups as GroupedCountries;
  },
);
