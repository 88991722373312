import React from 'react';
import { SvgIcon } from '@noths/polaris-client-ribbons-design-system';

import { getIconPath } from 'src/utils/assetPaths';
import * as styles from './styles';

export interface Props {
  controls: string;
  darkBackground?: boolean;
  isExpanded: boolean;
  toggleExpanded: () => void;
}

const CHEVRON_DOWN = getIconPath('icons-16-chevron-down.svg');
const CHEVRON_UP = getIconPath('icons-16-chevron-up.svg');

export const ExpandableReadMoreButton = ({
  controls,
  darkBackground,
  isExpanded,
  toggleExpanded,
}: Props) => (
  <button
    aria-controls={controls}
    aria-expanded={isExpanded}
    css={styles.expandingButton}
    onClick={toggleExpanded}
    style={
      {
        '--darkBackgroundColor': darkBackground && styles.darkBackgroundColor,
      } as styles.DarkBackgroundColorCSSProperties
    }
  >
    <SvgIcon height={16} src={isExpanded ? CHEVRON_UP : CHEVRON_DOWN} width={16} />
    <span css={styles.readMoreButtonText}>Read {isExpanded ? 'less' : 'more'}</span>
  </button>
);
