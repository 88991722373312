import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPersonalisationFromBasket } from 'src/redux/personalisation';
import { actions as productActions } from 'src/redux/product';
import { selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import { isOutOfStock } from 'src/utils/inventoryStatus';
import type { ProductOptionsDispatchProps, ProductOptionsStateProps } from './ProductOptions';
import { ProductOptions } from './ProductOptions';

const mapDispatchToProps = (dispatch: AppThunkDispatch): ProductOptionsDispatchProps => {
  return {
    onChangeQuantity: ({ quantity, valid }) => {
      dispatch(productActions.changeQuantity({ isValid: valid, quantity }));
    },
    onVisible: () => {
      sendGAEvent({
        event: 'custom_event',
        event_category: 'Product Personalisation',
        event_action: 'Impression',
        event_label: 'Initial Impression',
      });
    },
  };
};

const mapStateToProps = (state: ReduxApplicationState): ProductOptionsStateProps => {
  const editItem = selectPersonalisationFromBasket(state);
  const product = selectProduct(state);

  return {
    inStock: !isOutOfStock(product.inventoryStatus),
    purchasable: product.purchasable,
    quantity: editItem?.quantity || product.quantity,
    showPriceBreakdown: Boolean(product.options.length),
    showValidationErrors: state.personalisation.shouldShowPersonalisationValidationErrors,
  };
};

export const ProductOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(ProductOptions);
