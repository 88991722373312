import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedSelectedOptionValues, UpdateExisting } from './helpers/sliceHelpers';
import { submitPersonalisationForm, updatePersonalisationOptionsFromBasketItem } from './thunks';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  PersonalisationState,
  ProgressTrackingEvent,
  SelectedPersonalisationsRecord,
  SelectionPayload,
  SetPersonalisationExpandedPayload,
} from './types';

export const initialState: Readonly<PersonalisationState> = {
  isPending: false,
  editedItem: null,
  error: null,
  selectedPersonalisations: {} as SelectedPersonalisationsRecord,
  isPersonalisationOptionsExpanded: false,
  shouldShowPersonalisationValidationErrors: false,
  trackedEvents: {
    focus: {},
    progress: {
      'Progress - 50%': false,
      'Progress - 100%': false,
    },
    error: {
      'Character Limit Error': {},
      'Add to Cart Error': {},
    },
  },
};

export const { actions, reducer } = createSlice({
  name: 'personalisation',
  initialState,
  reducers: {
    showPersonalisationValidationErrors: (state) => {
      state.shouldShowPersonalisationValidationErrors = true;
    },
    deselectPersonalisation: (state, { payload }: PayloadAction<SelectionPayload>) => {
      delete state.selectedPersonalisations[payload.optionId];
    },
    selectPersonalisation: (state, { payload }: PayloadAction<SelectionPayload>) => {
      state.selectedPersonalisations = getUpdatedSelectedOptionValues(
        state.selectedPersonalisations,
        payload,
        UpdateExisting.ChangeInPlace,
      );
    },
    toggleSelectPersonalisation: (state, { payload }: PayloadAction<SelectionPayload>) => {
      state.selectedPersonalisations = getUpdatedSelectedOptionValues(
        state.selectedPersonalisations,
        payload,
        UpdateExisting.Dedupe,
      );
    },
    setPersonalisationExpanded: (
      state,
      { payload }: PayloadAction<SetPersonalisationExpandedPayload>,
    ) => {
      state.isPersonalisationOptionsExpanded = payload.expanded;
    },
    trackInteraction: (state, { payload }: PayloadAction<InteractionTrackingEvent>) => {
      if (payload.field_action === 'Field Focus') {
        state.trackedEvents.focus[payload.field_name] = true;
      } else {
        state.trackedEvents.progress[payload.field_name] = true;
      }
    },
    trackProgressMilestone: (state, { payload }: PayloadAction<ProgressTrackingEvent>) => {
      state.trackedEvents.progress[payload.field_action] = true;
      state.trackedEvents.progress[payload.field_name] = true;
    },
    trackInteractionError: (state, { payload }: PayloadAction<InteractionErrorTrackingEvent>) => {
      state.trackedEvents.error[payload.field_action][payload.field_name] = true;
    },
    updateEditedItem: (state, { payload }: PayloadAction<string>) => {
      state.editedItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePersonalisationOptionsFromBasketItem.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        for (const option of payload.options) {
          if (!state.selectedPersonalisations[option.optionId]) {
            state.selectedPersonalisations[option.optionId] = [];
          }

          state.selectedPersonalisations[option.optionId]?.push({
            id: parseInt(option.valueId || option.optionId),
            name: option.value || option.valueLabel!,
            priceModifier: null,
            isCustomInput: !!option.value,
          });
        }
      })
      .addCase(submitPersonalisationForm.pending, (state) => {
        state.isPending = true;
      })
      .addCase(submitPersonalisationForm.fulfilled, (state) => {
        state.isPending = false;
      })
      .addCase(submitPersonalisationForm.rejected, (state) => {
        state.isPending = false;
      });
  },
});
