import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { useLineCount } from '@noths/polaris-client-react-hooks';
import { theme } from '@noths/polaris-client-ribbons-base';

import { ExpandableReadMoreButton } from 'src/components/molecules/ExpandableReadMoreButton/ExpandableReadMoreButton';
import * as styles from './styles';

const { text } = theme;

export const LINE_HEIGHT = text.BODY.MD.lineHeight;
export const HIDE_TEXT_ON_LINE = 3;

export interface Props {
  children: JSX.Element[] | JSX.Element;
  darkBackground?: boolean;
  hideTextOnLine?: number;
  lineHeight?: number;
  onToggle?: (isOpen: boolean) => void;
  textContainerId: string;
}

export const ExpandableTextContainer = ({
  children,
  darkBackground,
  hideTextOnLine = HIDE_TEXT_ON_LINE,
  lineHeight = LINE_HEIGHT,
  onToggle,
  textContainerId,
}: Props) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const [unexpandedHeight, setUnexpandedHeight] = useState(lineHeight);
  const { lineCount, textHeight, textWrapperRef } = useLineCount({ lineHeight });

  const handleToggleExpanded = () => {
    toggleIsExpanded(!isExpanded);
    onToggle?.(!isExpanded);
  };

  useEffect(() => {
    setUnexpandedHeight(
      lineCount < hideTextOnLine ? lineHeight * lineCount : lineHeight * hideTextOnLine,
    );
  }, [lineCount]);

  return (
    <div>
      <div
        css={styles.expandingSection}
        data-testid={textContainerId}
        style={{ height: isExpanded ? textHeight : unexpandedHeight }}
      >
        <div ref={textWrapperRef}>{children}</div>
      </div>
      {lineCount > hideTextOnLine && (
        <ExpandableReadMoreButton
          controls={textContainerId}
          darkBackground={darkBackground}
          isExpanded={isExpanded}
          toggleExpanded={handleToggleExpanded}
        />
      )}
    </div>
  );
};
