import React from 'react';

export interface StarProps {
  height?: number;
  isEmpty: boolean;
  width?: number;
}

export const Star = ({ height = 14, isEmpty, width = 14 }: StarProps) => (
  <svg className="star" height={height} viewBox="0 -5 31 41" width={width}>
    <path
      d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"
      fill="#FBD033"
      fillOpacity={isEmpty ? '0' : '1'}
      stroke="#FBD033"
      strokeWidth={2}
    ></path>
  </svg>
);
