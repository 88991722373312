import type { CSSProperties } from 'react';
import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export interface ContentCSSProperties extends CSSProperties {
  '--height'?: string;
  '--overflow': string;
}

export interface ButtonCSSProperties extends CSSProperties {
  '--gradient'?: string;
}

const gradientHeight = 100;

export const content = css`
  height: var(--height);
  overflow: var(--overflow);
  position: relative;
`;

export const button = css`
  margin-top: ${spacing.base}px;
  ${responsiveText.BODY.MD};
  color: var(--brand-purple-base);
  border: 0 none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: ${spacing.sm}px 0;
  column-gap: ${spacing.xs}px;
  font-weight: 600;
  position: relative;
  width: 100%;

  &.focus-visible:focus {
    border-radius: 4px;
  }

  &[aria-expanded='false']::before {
    content: '';
    position: absolute;
    top: -${gradientHeight + spacing.base}px;
    left: 0;
    right: 0;
    height: ${gradientHeight}px;
    background: rgb(var(--gradient));
    background: linear-gradient(180deg, rgba(var(--gradient), 0) 0%, rgba(var(--gradient), 1) 100%);
    pointer-events: none;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacing.xs}px;
  }
`;
