import type {
  CatServiceAPINavigation,
  CatServiceAPINavigationLink,
} from 'src/services/cat-service-api/types/CatServiceAPINavigation';

export const buildCatServiceAPINavigationLink = (
  overrides: Partial<CatServiceAPINavigationLink>,
): CatServiceAPINavigationLink => {
  return {
    href: '',
    rel: '',
    title: '',
    ...overrides,
  };
};

export const buildCatServiceAPINavigation = (
  overrides: Partial<CatServiceAPINavigation> = {},
): CatServiceAPINavigation => {
  return {
    title: '',
    type: '',
    options: [],
    ...overrides,
  };
};
