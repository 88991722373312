import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';
const { fonts, spacing, text } = theme;

export const ratingSection = css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;

  > div:first-of-type {
    margin: 0;
  }

  margin-bottom: ${pxToRem(spacing.sm)};
`;

const reviewBaseFont = css`
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  ${fontSize(text.BODY.MD)};
`;

export const dateText = css`
  ${reviewBaseFont};
`;

export const commentText = css`
  ${reviewBaseFont};
`;

export const partnerResponseWrapper = css`
  ${reviewBaseFont};
  padding: ${pxToRem(spacing.xl)};
  border-radius: ${pxToRem(10)};
  background-color: var(--neutral-black-10);
  margin-top: ${pxToRem(spacing.lg)};
  color: var(--neutral-black-70);
`;

export const partnerNameWrapper = css`
  ${reviewBaseFont};
`;

export const partnerIcon = css`
  margin-right: ${pxToRem(8)};
  position: relative;
  top: ${pxToRem(1)};
`;

export const partnerResponseText = css`
  ${reviewBaseFont};
  color: var(--neutral-black-70);
  padding: ${pxToRem(spacing.xs)} 0 ${pxToRem(spacing.md)};
`;
