/* istanbul ignore file */
import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import {
  cusorOverrideZoomInIcon,
  cusorOverrideZoomOutIcon,
} from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery, pxToRem, shadowRight, shadowUp } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import * as modalStyles from 'src/components/molecules/Modal/styles/ModalExternalStyles';
import {
  PRODUCT_IMAGE_TABPANEL_CLASS,
  ZOOM_PAN_CONTENT_CLASS,
  ZOOM_PAN_WRAPPER_CLASS,
} from 'src/components/organisms/ProductImagesCarousel/constants';

const { spacing } = theme;
const THUMBNAILS_HEIGHT = 128;

export const modalCloseButton = css`
  position: absolute;
  right: ${pxToRem(spacing.xxl)};
  top: ${pxToRem(spacing.xxl)};
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  background-color: var(--neutral-white-base);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &.focus-visible {
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: ${pxToRem(-4)};
      bottom: ${pxToRem(-4)};
      left: ${pxToRem(-4)};
      right: ${pxToRem(-4)};
      border-radius: 50%;
      border: var(--brand-purple-base) ${pxToRem(2)} solid;
    }

    &:after {
      content: '';
      position: absolute;
      top: ${pxToRem(-6)};
      bottom: ${pxToRem(-6)};
      left: ${pxToRem(-6)};
      right: ${pxToRem(-6)};
      border-radius: 50%;
      border: var(--neutral-black-10) ${pxToRem(2)} solid;
    }
  }
`;

export const fullScreenImage = css`
  width: 100%;
  height: 100%;
  object-fit: contain;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      width: auto;
      height: auto;
      margin: 0 auto;
    `,
  )}
`;

export const imagesContainer = css`
  width: 100%;
  flex-grow: 1;
  position: relative;
  height: calc(100% - ${THUMBNAILS_HEIGHT}px);

  .${PRODUCT_IMAGE_TABPANEL_CLASS} {
    background: none;
    height: 100%;
  }

  .${ZOOM_PAN_WRAPPER_CLASS}, .${ZOOM_PAN_CONTENT_CLASS} {
    height: 100%;
    width: 100%;
  }
`;

export const tabpanel = css`
  height: 100%;

  .${ZOOM_PAN_WRAPPER_CLASS}, .${ZOOM_PAN_CONTENT_CLASS} {
    height: 100%;
    width: 100%;
  }
`;

export const keyboardNavigationText = css`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 0.625rem;
  padding: 6px 0.5rem;
  margin: 0px;
  font-size: 0.75rem;
  border: none;

  &[data-has-focus='false'] {
    clip: rect(0, 0, 0, 0);
  }
`;

export const zoomWrapper = css`
  width: 100%;
  height: 100%;

  &[data-has-zoomed='true'] {
    ${cusorOverrideZoomOutIcon}
  }
  &[data-has-zoomed='false'] {
    ${cusorOverrideZoomInIcon}
  }
`;

export const modalContentContainer = css`
  background-color: var(--neutral-black-70);
`;

export const thumbnails = css`
  display: block;
  width: 100%;
  height: ${THUMBNAILS_HEIGHT}px;
  padding: ${pxToRem(spacing.md)};
  background-color: var(--neutral-white-base);
  z-index: 1;
  ${shadowUp};
  ${shadowRight};
`;

export const fullViewContainer = css`
  ${modalStyles.modalViewport}
  display: flex;
  flex-direction: column-reverse;
`;
