import type { CurrencyCode, Price } from '@noths/polaris-dev-ts-types';

import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import type { ProductState } from '../slice';

const getAmountForCurrency = (prices: Price[], currency: CurrencyCode) => {
  return prices.find((price) => price.currency === currency)?.amount;
};

const getHighestCostOptionValue = (option: CatServiceAPIProductOption, currency: CurrencyCode) => {
  return (option.values || []).reduce((highest, value) => {
    if (value.priceModifier === null) {
      return 0;
    }

    const amount = getAmountForCurrency(value.priceModifier.prices, currency)!;

    return Math.max(amount, highest);
  }, 0);
};

const getLowestCostOptionValue = (option: CatServiceAPIProductOption, currency: CurrencyCode) => {
  return (option.values || []).reduce((lowest, value) => {
    if (value.priceModifier === null) {
      return 0;
    }

    const amount = getAmountForCurrency(value.priceModifier.prices, currency)!;

    return Math.min(amount, lowest);
  }, Infinity);
};

interface Range {
  maxOptional: number;
  maxRequired: number;
  minOptional: number;
  minRequired: number;
}

export const getOptionsCostRange = (
  options: ProductState['options'],
  currency: CurrencyCode,
): Range => {
  const initialRange: Range = {
    maxOptional: 0,
    maxRequired: 0,
    minOptional: 0,
    minRequired: 0,
  };
  const optionsWithPriceModifiers = options.filter((option) => {
    if (option.values === null) {
      return false;
    }

    return option.values.some((value) => value.priceModifier !== null);
  });

  if (optionsWithPriceModifiers.length === 0) {
    return initialRange;
  }

  return optionsWithPriceModifiers.reduce((range, option) => {
    if (option.values === null) {
      return range;
    }

    const highestCostOptionValue = getHighestCostOptionValue(option, currency);
    const lowestCostOptionValue = getLowestCostOptionValue(option, currency);

    return option.required
      ? {
          ...range,
          maxRequired: range.maxRequired + highestCostOptionValue,
          minRequired: range.minRequired + lowestCostOptionValue,
        }
      : {
          ...range,
          maxOptional: range.maxOptional + highestCostOptionValue,
          minOptional: range.minOptional + lowestCostOptionValue,
        };
  }, initialRange);
};
