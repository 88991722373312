import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { fetchProductDetails } from 'src/services/cat-service-api/fetchProductDetails';
import type { CatServiceAPIResponseBody } from 'src/services/cat-service-api/types/CatServiceAPIResponseBody';
import type { RequestProductDetailsPayload } from './types';

interface RequestProductsOptions {
  cookie?: string;
  healthcheck?: boolean;
  previewing?: boolean;
  productURI: string;
}

export const requestProductDetails = createAsyncThunk<
  RequestProductDetailsPayload,
  RequestProductsOptions
>(
  'product/requestProductDetails',
  async ({ cookie, healthcheck, previewing, productURI }, { getState }) => {
    if (healthcheck) {
      // Dynamically import json file to prevent it being included on the client. Healthcheck request only happens server side.
      const { healthCheckProductDetailsResponseBody } = await import(
        'src/services/cat-service-api/fixtures/healthCheckProductDetailsResponseBody'
      );
      const {
        metaDescription,
        metaRobotsIndexable,
        navigation,
        product: { actualCommissionRate, ...restOfProductData },
      } = healthCheckProductDetailsResponseBody;

      return {
        data: {
          product: restOfProductData,
          navigation,
          metaDescription,
          metaRobotsIndexable,
        } as Omit<CatServiceAPIResponseBody, 'actualCommissionRate'>,
      };
    }

    const {
      userConfiguration: { currencyCode, deliveryZone },
    } = getState() as ReduxApplicationState;

    const { data, redirectUrl } = await fetchProductDetails(productURI, {
      cookie,
      currencyCode,
      deliveryZone,
      previewing,
    });

    if (redirectUrl) {
      return { redirectUrl };
    }

    const {
      metaDescription,
      metaRobotsIndexable,
      navigation,
      product: { actualCommissionRate, ...restOfProductData },
    } = data as CatServiceAPIResponseBody;

    return {
      data: {
        product: restOfProductData,
        navigation,
        metaDescription,
        metaRobotsIndexable: typeof metaRobotsIndexable === 'boolean' ? metaRobotsIndexable : true,
      } as Omit<CatServiceAPIResponseBody, 'actualCommissionRate'>,
    };
  },
);
