import { primarySemiBoldFont, theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const root = css`
  margin-bottom: ${spacing.md}px;
`;

export const content = css`
  margin: ${spacing.sm}px 0 0 0;
  ${responsiveText.BODY.SM};
  color: var(--neutral-black-base);

  &:first-of-type {
    margin-top: 0;
  }
`;

export const accentLink = css`
  ${primarySemiBoldFont};
  text-decoration: none;
  color: var(--neutral-black-base);

  &:hover,
  &:focus {
    text-decoration: underline;
    border-radius: 4px;
  }
`;
