import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { inset, mediaQuery, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { getIconPath } from 'src/utils/assetPaths';

const { fonts, spacing, text } = theme;

const CHEVRON_RIGHT = getIconPath('icons-16-chevron-right.svg');
const CLOSE = getIconPath('icons-24-close.svg');

export const personalisationOptions = css`
  margin: 0;
  list-style: none;
  padding: 0;
`;

export const personalisationOption = css`
  list-style: none;
  margin: ${stack(spacing.sm)};
  padding: ${spacing.sm}px 0 0;
`;

export const innerFieldset = css`
  padding: ${spacing.xl}px ${spacing.md}px 36px;
  max-height: 100%;
  overflow-y: auto;
  flex-grow: 1;
`;

export const selectionMultipleDrawer = css`
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: ${spacing.md}px 0 0;
  position: fixed;
  background: var(--neutral-white-base);
  top: 0;
  right: 0;
  height: 100%;
  z-index: 500;
  min-width: 100%;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      min-width: 0;
    `,
  )};
`;

export const selectionMultipleInner = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const inputSelect = css`
  padding: 0;
  ${fontSize(text.BODY.XS)};
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  line-height: ${spacing.lg}px;
  display: block;
  width: 100%;
  height: 26px;
  text-align: left;
  border: none;
  background: none;
`;

export const wrapperButton = css`
  color: var(--neutral-black-base);
  ${fontSize(text.BODY.XS)};
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  line-height: ${spacing.lg}px;
  background: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  padding: ${inset(spacing.md)};
  text-align: left;
`;

export const inputOpen = css`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${spacing.lg}px;
  background-image: url(${CHEVRON_RIGHT});
  background-repeat: no-repeat;
  background-position: right;
`;

export const inputClose = css`
  background-image: url(${CLOSE});
  background-repeat: no-repeat;
  background-position: right;
  width: 24px;
  height: 24px;
`;

export const ctaApplyButton = css`
  cursor: pointer;
`;

export const ctaApplyButtonWrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: ${spacing.md}px;
  background: var(--neutral-white-base);
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const topModalSection = css`
  display: flex;
  padding: ${spacing.md}px;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const legend = css`
  ${fontSize(text.BODY.XS)}
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  text-transform: capitalize;
  margin: 0;
  padding: 0;
`;

export const legendLarge = css`
  position: relative;
  padding: ${spacing.md}px;
  ${fontSize(text.BODY.MD)}

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 1;
    bottom: -15px;
    left: 0;
    height: 15px;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255) 30%,
      rgba(255, 255, 255) 100%
    );
  }
`;

export const groupLabel = css`
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  text-transform: capitalize;
  ${fontSize(text.BODY.XS)}
  margin: ${stack(spacing.xs)};
`;

export const requiredLabel = css`
  ${fontSize(text.BODY.XS)}
  border-radius: 6px;
  background-color: var(--neutral-black-70);
  color: var(--neutral-white-base);
  width: 70px;
  height: 20px;
  margin: 0;
  padding: 0 ${spacing.xs}px;
`;

export const radioGroup = css`
  > div:last-of-type {
    margin: 0;
  }
`;

export const priceApplyText = css`
  ${fontSize(text.BODY.XS)};
  text-align: center;
  margin: ${stack(spacing.md)};
  height: 20px;
`;

export const priceOperator = css`
  font-size: ${text.BODY.SM.fontSize}px;
  margin-right: 2px;

  &[data-is-negative='false'] {
    font-size: ${text.HEADING.XS.fontSize}px;
  }
`;

export const priceModifier = css`
  display: flex;
`;

export const feedbackContainer = css`
  margin: 0;
`;

export const selectionContainer = css`
  position: relative;
  padding: ${inset(spacing.md)};
  border-radius: 10px;
  border: solid 1px var(--neutral-black-70);

  &[data-has-error],
  &[data-is-valid],
  [data-has-error],
  &[data-is-selected] {
    transition: border-color 0.15s ease-in, background-color 0.15s ease-in;
  }

  &:hover {
    border-color: var(--neutral-black-70);
  }

  &[data-has-error='true'] {
    border-color: var(--functional-red-base);

    [data-is-selected] {
      border-color: var(--functional-red-base);
    }
  }

  &[data-is-valid='true'] {
    border-color: var(--functional-green-base);
  }
`;

export const multipleSelectionContainer = css`
  padding: 0;
`;

export const toggleButtonGroupDescription = css`
  display: none;
`;
