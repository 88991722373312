import { connect } from 'react-redux';

import type {
  PersonalisationOptionsDispatchProps,
  PersonalisationOptionsStateProps,
} from 'src/components/organisms/Personalisation/components/PersonalisationOptions/PersonalisationOptions';
import { PersonalisationOptions } from 'src/components/organisms/Personalisation/components/PersonalisationOptions/PersonalisationOptions';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
  SelectionPayload,
} from 'src/redux/personalisation';
import {
  actions as personalisationActions,
  selectNumberOfRequiredPersonalisations,
  selectOutstandingPersonalisations,
  selectTrackedEvents,
} from 'src/redux/personalisation';
import { selectPersonalisationOptions } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';

const mapStateToProps = (state: ReduxApplicationState): PersonalisationOptionsStateProps => {
  return {
    isExpanded: state.personalisation.isPersonalisationOptionsExpanded,
    options: selectPersonalisationOptions(state),
    outstandingSelections: selectOutstandingPersonalisations(state),
    totalRequiredPersonalisations: selectNumberOfRequiredPersonalisations(state),
    trackedEvents: selectTrackedEvents(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): PersonalisationOptionsDispatchProps => {
  return {
    deselectPersonalisation: (payload: SelectionPayload) =>
      dispatch(personalisationActions.deselectPersonalisation(payload)),
    selectPersonalisation: (payload: SelectionPayload) =>
      dispatch(personalisationActions.selectPersonalisation(payload)),
    setPersonalisationExpanded: (expanded: boolean) =>
      dispatch(personalisationActions.setPersonalisationExpanded({ expanded })),
    trackInteraction: (payload: InteractionTrackingEvent) =>
      dispatch(personalisationActions.trackInteraction(payload)),
    trackProgressMilestone: (payload: ProgressTrackingEvent) =>
      dispatch(personalisationActions.trackProgressMilestone(payload)),
    trackInteractionError: (payload: InteractionErrorTrackingEvent) =>
      dispatch(personalisationActions.trackInteractionError(payload)),
  };
};

export const PersonalisationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalisationOptions);
