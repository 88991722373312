import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const quantityContainer = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${stack(spacing.lg)};
  margin-top: ${pxToRem(36)};

  &[data-has-error='true'] #quantity {
    border-color: var(--functional-red-base);
    background-color: var(--tertiary-red-50);
  }
`;

export const quantityLabel = css`
  padding-right: ${pxToRem(spacing.sm)};
`;

export const errorMessage = css`
  ${fontSize(text.BODY.XS)}
  text-align: left;
  margin: 0;
  width: 100%;
`;

export const quantityInput = css`
  text-align: center;
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  border-radius: ${pxToRem(10)};
  border: solid 1px var(--neutral-black-70);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;
