/* istanbul ignore file */
export enum ThumbnailOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface ProductImagesState {
  currentImage: number;
  isModalOpen: boolean;
  navigatedBy: 'swipe' | 'click' | null;
}

export type ImagesLocation = 'Expanded image' | 'Main image' | 'Carousel';

export interface SetCurrentImagePayload {
  imageIndex: number;
  location?: ImagesLocation;
  navigationType: ProductImagesState['navigatedBy'];
}

export interface OpenModalPayload {
  location: ImagesLocation;
}

export interface NextOrPreviousPayload {
  location: ImagesLocation;
  totalImages: number;
}
