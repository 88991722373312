import React from 'react';
import {
  Feedback,
  fieldFeedbackContainer,
  requiredLabel,
} from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { SelectedCheckboxOption } from 'src/components/organisms/Personalisation/components/SelectedCheckboxOption';
import { ToggleButton } from 'src/components/organisms/Personalisation/components/ToggleButton';
import { REQUIRED_SELECTION_COPY } from 'src/components/organisms/Personalisation/constants/copy';
import * as styles from 'src/components/organisms/Personalisation/styles/Personalisation.styles';
import {
  getFieldPosition,
  getProgressMilestone,
} from 'src/components/organisms/Personalisation/utils';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
  SelectionPayload,
  TrackedEvents,
} from 'src/redux/personalisation';
import type {
  CatServiceAPIProductOption,
  CatServiceAPIProductOptionValue,
} from 'src/services/cat-service-api/types/CatServiceAPIProductOption';

const getId = (id: number) => `${id}`;

export interface OwnProps extends CatServiceAPIProductOption {
  displayValidation: boolean | undefined;
}

export interface DispatchProps {
  toggleSelectPersonalisation: (payload: SelectionPayload) => void;
  trackInteraction: (payload: InteractionTrackingEvent) => void;
  trackInteractionError: (payload: InteractionErrorTrackingEvent) => void;
  trackProgressMilestone: (payload: ProgressTrackingEvent) => void;
}

interface ContainerProps extends DispatchProps {
  currency: CurrencyCode;
  error: boolean;
  options: CatServiceAPIProductOption[];
  selectedOption?: CatServiceAPIProductOptionValue[];
  totalOutstandingSelections: number;
  totalRequiredPersonalisations: number;
  trackedEvents: TrackedEvents;
}

type Props = OwnProps & DispatchProps & ContainerProps;

interface HandleOnChangeFnParams extends Omit<ContainerProps, 'error' | 'currency'> {
  appliedValue: CatServiceAPIProductOptionValue;
  id: number;
  name: string;
  required: boolean;
  value: CatServiceAPIProductOptionValue;
}

const handleInputFocus = (
  trackedEvents: TrackedEvents,
  options: CatServiceAPIProductOption[],
  props: OwnProps,
  value: CatServiceAPIProductOptionValue,
  trackInteraction: DispatchProps['trackInteraction'],
) => {
  if (!trackedEvents.focus[props.name]) {
    trackInteraction({
      field_position: getFieldPosition(options, props),
      field_name: props.name,
      field_action: 'Field Focus',
      field_type: 'single & binary selection',
      field_value: value.name,
      required_field: props.required,
    });
  }
};

const handleChange = ({
  appliedValue,
  id,
  name,
  options,
  required,
  toggleSelectPersonalisation,
  totalOutstandingSelections,
  totalRequiredPersonalisations,
  trackInteraction,
  trackProgressMilestone,
  trackedEvents,
  value,
}: HandleOnChangeFnParams) => {
  const fieldName = name;
  const fieldPosition = getFieldPosition(options, { id });

  trackInteraction({
    field_position: fieldPosition,
    field_name: fieldName,
    field_action: 'Field Input',
    field_type: 'single & binary selection',
    field_value: value.id === appliedValue?.id ? null : value.name,
    required_field: required,
  });

  toggleSelectPersonalisation({
    optionId: id,
    optionValue: value,
    type: 'single-selection',
  });

  const milestone = getProgressMilestone({
    totalRequiredPersonalisations,
    totalOutstandingSelections,
    trackedEvents,
    fieldName,
    isRequiredPersonalisation: required,
  });

  if (milestone) {
    trackProgressMilestone({
      field_position: fieldPosition,
      field_name: fieldName,
      field_action: milestone,
      field_type: 'single & binary selection',
      field_value: name,
      required_field: true,
    });
  }
};

export const SelectionSingleOrBinary = ({
  currency,
  error,
  options,
  toggleSelectPersonalisation,
  totalOutstandingSelections,
  totalRequiredPersonalisations,
  trackInteraction,
  trackInteractionError,
  trackProgressMilestone,
  trackedEvents,
  ...props
}: Props) => {
  const [appliedValue] = props.selectedOption ?? [];
  const isValidSelection = appliedValue && !error;

  const handleOnChange = (value: CatServiceAPIProductOptionValue) => {
    handleChange({
      value,
      id: props.id,
      name: props.name,
      appliedValue,
      trackedEvents,
      options,
      required: props.required,
      totalOutstandingSelections,
      totalRequiredPersonalisations,
      toggleSelectPersonalisation,
      trackInteraction,
      trackProgressMilestone,
      trackInteractionError,
    });
  };

  const errorContainerId = `selection-error-${props.id}`;

  return (
    <div
      aria-describedby={errorContainerId}
      aria-labelledby={getId(props.id)}
      css={[styles.radioGroup, styles.selectionContainer]}
      data-has-error={error}
      data-is-valid={isValidSelection}
      role="group"
    >
      {props.required && (
        <p
          aria-hidden="true"
          css={requiredLabel}
          data-has-error={error}
          data-is-valid={isValidSelection}
        >
          Required
        </p>
      )}
      <p
        css={styles.groupLabel}
        dangerouslySetInnerHTML={{ __html: props.name }}
        id={getId(props.id)}
      />
      {props.values?.map((value) => {
        const id = `${value.id}__input`;
        const isSelected = value.id === appliedValue?.id;
        const onFocus = () =>
          handleInputFocus(trackedEvents, options, props, value, trackInteraction);

        return props.values!.length > 1 ? (
          <ToggleButton
            currency={currency}
            isSelected={isSelected}
            key={id}
            onClick={() => handleOnChange(value)}
            onFocus={onFocus}
            value={value}
          />
        ) : (
          <SelectedCheckboxOption
            currency={currency}
            error={error}
            id={id}
            isSelected={isSelected}
            key={value.id}
            name={getId(props.id)}
            onChange={() => handleOnChange(value)}
            onFocus={onFocus}
            value={value}
          />
        );
      })}
      <div css={[fieldFeedbackContainer, styles.feedbackContainer]} id={errorContainerId}>
        {error && <Feedback error iconAlign="left" name={REQUIRED_SELECTION_COPY} value="" />}
      </div>
    </div>
  );
};
