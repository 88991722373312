import type { MutableRefObject } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { AnchoredNotificationAlert } from '@noths/polaris-client-ribbons-design-system';

import { ADD_TO_BASKET_ERROR } from './copy';
import { ErrorNotificationWrapper } from './ErrorNotificationWrapper';
import { useErrorNotificationFocus } from './hooks/useErrorNotificationFocus';
import * as styles from './ErrorNotification.styles';

export interface ErrorNotificationProps {
  returnFocusToRef: MutableRefObject<HTMLElement | null>;
  shouldShow: boolean;
}
export const ERROR_NOTIFICATION_TESTID = 'error-notification';
export const ERROR_NOTIFICATION_MESSAGE_ID = 'error-notification-message';

export const ErrorNotification = ({ returnFocusToRef, shouldShow }: ErrorNotificationProps) => {
  const textBoxRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [isVisible, setIsVisible] = useState(shouldShow);

  useErrorNotificationFocus(isVisible, returnFocusToRef, textBoxRef);

  useEffect(() => {
    setIsVisible(shouldShow);
  }, [shouldShow]);

  return (
    <ErrorNotificationWrapper>
      <div
        aria-describedby={ERROR_NOTIFICATION_MESSAGE_ID}
        css={styles.addToBasketAlert}
        data-testid={ERROR_NOTIFICATION_TESTID}
        data-visible={isVisible}
        ref={textBoxRef}
        role="alertdialog"
      >
        <AnchoredNotificationAlert onClose={() => setIsVisible(false)}>
          <span css={styles.addToBasketAlertTextBox} id={ERROR_NOTIFICATION_MESSAGE_ID}>
            {ADD_TO_BASKET_ERROR}
          </span>
        </AnchoredNotificationAlert>
      </div>
    </ErrorNotificationWrapper>
  );
};
