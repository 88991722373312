import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const wrapper = css`
  display: block;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      display: flex;
      gap: ${spacing.lg}px;
    `,
  )}
`;

export const content = css`
  width: 100%;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      width: 50%;
    `,
  )}
`;

export const heading = css`
  ${responsiveText.BODY.MD};
  margin: 0 0 ${spacing.xs}px;
`;

export const ingredientsInfo = css`
  padding: ${theme.spacing.sm}px 0;
`;
