import React from 'react';
import { useVisibilityObserver } from '@noths/polaris-client-react-hooks';
import { HyperButtonLink, IconHelp, Tooltip } from '@noths/polaris-client-ribbons-design-system';

import {
  NO_REVIEWS,
  REVIEW_TOOLTIP_BUTTON,
  REVIEW_TOOLTIP_CONTENT,
} from 'src/components/organisms/ProductReviews/constants/copy';
import * as styles from 'src/components/organisms/ProductReviews/styles/ProductReviews.styles';
import type { DispatchProps } from 'src/components/organisms/ProductReviews/types';
import type { CatServiceAPIPartner } from 'src/services/cat-service-api/types/CatServiceAPIPartner';
import type { ReviewPagination, Reviews } from 'src/types/reviews';
import { getImagePath } from 'src/utils/assetPaths';
import { Pagination } from './Pagination';
import { ProductReview } from './ProductReview';

export interface ProductReviewsProps extends DispatchProps {
  loadPageOfReviews: (page: number) => void;
  onVisible: () => void;
  partner: CatServiceAPIPartner;
  reviews: Reviews;
}

const feefoLogoPath = getImagePath('feefo-logo.png');

const canShowPaginationTypeGuard = (
  pagination: ReviewPagination | null,
): pagination is ReviewPagination => (pagination?.totalPages ?? 0) > 1;

export const ProductReviews = ({
  loadPageOfReviews,
  onProductReviewsExpandReadMore,
  onProductReviewsNextPage,
  onProductReviewsPagination,
  onProductReviewsProductRatingSystem,
  onVisible,
  partner,
  reviews: { items, pagination },
}: ProductReviewsProps) => {
  const { observedElementRef: productReviewsRef } = useVisibilityObserver(() => onVisible());

  return (
    <div ref={productReviewsRef}>
      {items?.length === 0 ? (
        <p css={styles.noReviews}>{NO_REVIEWS}</p>
      ) : (
        <>
          {items?.length && (
            <ul css={styles.reviewsList}>
              {items.map((review, index) => (
                <li key={`product-review-${review.id}`}>
                  <ProductReview
                    onExpandReviewClick={onProductReviewsExpandReadMore}
                    partner={partner}
                    review={review}
                  />
                  {index !== items.length - 1 && <hr css={styles.divider} />}
                </li>
              ))}
            </ul>
          )}
          {canShowPaginationTypeGuard(pagination) && (
            <Pagination
              loadPageOfReviews={loadPageOfReviews}
              onProductReviewsNextPage={onProductReviewsNextPage}
              onProductReviewsPagination={onProductReviewsPagination}
              pagination={pagination}
            />
          )}

          <img alt="Feefo" css={styles.feefoLogo} src={feefoLogoPath} />
          <div css={styles.tooltipWrapper}>
            <Tooltip alignment="left" content={REVIEW_TOOLTIP_CONTENT}>
              {({ onClick }) => (
                <HyperButtonLink
                  href="/rating_help.html"
                  onClick={(e) => {
                    onClick(e);

                    onProductReviewsProductRatingSystem();
                  }}
                  rel="nofollow"
                >
                  <span css={styles.tooltipInfo}>
                    {REVIEW_TOOLTIP_BUTTON}
                    <IconHelp
                      color={`var(--brand-purple-base)`}
                      css={styles.tooltipInfoIcon}
                      height={16}
                      width={16}
                    />
                  </span>
                </HyperButtonLink>
              )}
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};
