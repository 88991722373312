import React from 'react';
import { connect } from 'react-redux';
import type {
  ProductCarouselProduct,
  ProductCarouselProps,
} from '@noths/polaris-client-ribbons-design-system';
import { ProductCarousel } from '@noths/polaris-client-ribbons-design-system';
import { linkClickOpensInNewTab } from '@noths/polaris-client-utils';
import type { CurrencyCode, RecommendedProduct } from '@noths/polaris-dev-ts-types';
import type { Dispatch } from '@reduxjs/toolkit';

import type { CarouselProductClickTrackingData } from 'src/components/organisms/RecommendedProductsShelf/modules/actions';
import {
  recommendedProductsShelfNavigateCarousel,
  recommendedProductsShelfProductClick,
  recommendedProductsShelfVisible,
} from 'src/components/organisms/RecommendedProductsShelf/modules/actions';
import { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import * as styles from './RecommendedProductsShelfContainer.styles';

interface RecommendedProductsShelfContainerProps
  extends Pick<
    ProductCarouselProps,
    | 'size'
    | 'hideProductTitles'
    | 'lazyImages'
    | 'forceNextItemControlEnabled'
    | 'forcePreviousItemControlEnabled'
    | 'titleAlignment'
  > {
  enableFreeDomesticDeliveryLabels?: boolean;
  hideLabelIcons?: boolean;
  placementType: Placement;
}

export const OUT_OF_STOCK_LABEL = 'Sorry, this item is currently out of stock';

const transformRecommendedProductToProductCarouselProduct = (
  {
    free_domestic_delivery: freeDomesticDelivery,
    images,
    linkURL: trackingURL,
    links,
    new: isNew,
    on_sale: onSale,
    pre_sale_prices,
    prices,
    purchasable: isPurchasable,
    sale_percentage,
    title,
  }: RecommendedProduct,
  currency: CurrencyCode,
): ProductCarouselProduct => ({
  title,
  src: images[0]?.href || '',
  href: links[0]?.href || '',
  alt: '',
  isNew,
  onSale,
  isPurchasable,
  price: prices?.find((prices) => prices.currency === currency),
  preSalePrice: pre_sale_prices?.find((price) => price.currency === currency),
  salePercentage: sale_percentage ?? undefined,
  trackingURL,
  freeDomesticDelivery,
});

const mapStateToProps = (
  { product, richRelevance }: ReduxApplicationState,
  { placementType, ...props }: RecommendedProductsShelfContainerProps,
): ProductCarouselProps => {
  const products =
    richRelevance[placementType].products?.map((data: RecommendedProduct) =>
      transformRecommendedProductToProductCarouselProduct(data, product.price.currency),
    ) ?? [];
  return {
    products,
    isLoading: products.length === 0,
    title: richRelevance[placementType].placementTitle,
    infoText: placementType === Placement.OutOfStock ? OUT_OF_STOCK_LABEL : undefined,
    ...props,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { placementType }: RecommendedProductsShelfContainerProps,
): Pick<ProductCarouselProps, 'onVisible' | 'onProductClick' | 'onNavigation'> => ({
  onNavigation: (swiped, control) =>
    dispatch(
      recommendedProductsShelfNavigateCarousel({
        navigation: {
          type: swiped ? 'Scroll' : 'Arrow Click',
          direction: control === 'next' ? 'Right' : 'Left',
        },
        placementType,
      }),
    ),
  onVisible: () => dispatch(recommendedProductsShelfVisible({ placementType })),
  onProductClick: (evt, { index }) => {
    const shouldUseEventCallback = !linkClickOpensInNewTab(evt);
    if (shouldUseEventCallback) {
      evt.preventDefault();
    }
    const payloadWithShouldUseCallback: CarouselProductClickTrackingData = {
      placementType,
      productIndex: index,
      shouldUseEventCallback,
    };

    return dispatch(recommendedProductsShelfProductClick(payloadWithShouldUseCallback));
  },
});

const RecommendedProductsShelfProductCarousel = (props: ProductCarouselProps) => (
  <div css={styles.container}>
    <ProductCarousel {...props} />
  </div>
);

export const RecommendedProductsShelfContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendedProductsShelfProductCarousel);
