import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import { BASKET_PAGE_PATH } from 'src/constants/page';
import { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPersonalisationFromBasket } from 'src/redux/personalisation';
import { actions } from 'src/redux/productInfo/slice';
import { selectProduct } from 'src/redux/selectors';
import type { AppThunkDispatch } from 'src/redux/store';
import type { BasketModalDispatchProps, BasketModalStateProps } from '../components/BasketModal';
import { BasketModal } from '../components/BasketModal';

const mapStateToProps = (state: ReduxApplicationState): BasketModalStateProps => {
  return {
    basket: state.basket.basket,
    editMode: Boolean(selectPersonalisationFromBasket(state)),
    open: state.productInfo.isBasketModalOpen,
    product: selectProduct(state),
    recommendationsSessionID: state.userConfiguration.richRelevanceSessionId,
    showRecommendations: !!state.richRelevance?.[Placement.AddToBasket]?.products,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): BasketModalDispatchProps => {
  return {
    onNavigateToBasket: async () => {
      await sendGAEvent({
        event: 'custom_event',
        event_action: 'Basket click',
        event_category: 'Added to basket modal',
        event_label: 'not set',
      });
      window.location.assign(BASKET_PAGE_PATH);
    },
    onClose: () => {
      dispatch(actions.closeBasketModal());
      sendGAEvent({
        event: 'custom_event',
        event_action: 'Continue shopping click',
        event_category: 'Added to basket modal',
        event_label: 'not set',
      });
    },
  };
};

export const BasketModalContainer = connect(mapStateToProps, mapDispatchToProps)(BasketModal);
