import type { DeliveryZoneCode } from '@noths/polaris-client-localisation';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { DeliveryBlockOnCountryChangeArgs } from 'src/components/molecules/DeliveryBlock/components/DeliveryBlock';
import type { DeliverySettingsAPIDeliveryZone } from 'src/services/delivery-settings/types/DeliverySettingsAPIDeliveryZone';
import { logger } from 'src/utils/serverLogger';
import { requestDeliveryZoneOptions } from './thunks';

export interface DeliveryZoneOptionsState {
  error: SerializedError | null;
  isLoading: boolean;
  options: DeliverySettingsAPIDeliveryZone[];
  previouslySelectedZone: DeliveryZoneCode | null;
  selectedCountry: string;
  selectedZone: DeliveryZoneCode;
}

export const initialState: Readonly<DeliveryZoneOptionsState> = {
  error: null,
  isLoading: true,
  options: [],
  previouslySelectedZone: null,
  selectedZone: 'MAINLAND_UK',
  selectedCountry: 'GB',
};

export const { actions, reducer } = createSlice({
  name: 'requestDeliveryZoneOptions',
  initialState,
  reducers: {
    setSelectedZone: (state, { payload }: PayloadAction<DeliveryZoneCode | undefined>) => {
      if (payload) {
        state.previouslySelectedZone = state.selectedZone;
        state.selectedZone = payload;
      }
    },
    setSelectedCountry: (
      state,
      { payload }: PayloadAction<DeliveryBlockOnCountryChangeArgs | string | undefined>,
    ) => {
      if (payload) {
        state.selectedCountry = typeof payload === 'object' ? payload.selectedCountry : payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestDeliveryZoneOptions.fulfilled, (state, { payload }) => {
        state.options = payload;
        state.isLoading = false;
      })
      .addCase(requestDeliveryZoneOptions.rejected, (state, action) => {
        logger.error('Error requesting delivery zone options', action.error);

        state.error = action.error;
        state.isLoading = false;
      });
  },
});
