import type { MutableRefObject } from 'react';
import React from 'react';

import type { InputOnChangeArgs } from 'src/components/organisms/Personalisation/components/Input';
import { InputContainer } from 'src/components/organisms/Personalisation/containers/InputContainer';
import { MultipleOptionSelectionContainer } from 'src/components/organisms/Personalisation/containers/MultipleOptionSelectionContainer';
import { SelectionSingleOrBinaryContainer } from 'src/components/organisms/Personalisation/containers/SelectionSingleOrBinaryContainer';
import * as styles from 'src/components/organisms/Personalisation/styles/Personalisation.styles';
import type { CatServiceAPIProductOption } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import type { FieldEventType } from 'src/types/googleAnalytics';
import type {
  PersonalisationProductOption,
  SelectionProductOption,
} from 'src/types/personalisation';

interface Props {
  displayValidation: boolean | undefined;
  onChange?: (selection: InputOnChangeArgs) => void;
  onFocus: (option?: CatServiceAPIProductOption, fieldType?: FieldEventType) => void;
  option: CatServiceAPIProductOption;
  wrapperRef: MutableRefObject<HTMLUListElement | null>;
}

export const PersonalisationOption = ({
  displayValidation,
  onChange,
  onFocus,
  option,
  wrapperRef,
}: Props) => {
  if (option.type === 'selection') {
    return option.values!.length < 3 ? (
      <li css={styles.personalisationOption} key={option.id} onFocus={() => onFocus()}>
        <SelectionSingleOrBinaryContainer
          displayValidation={displayValidation}
          {...(option as SelectionProductOption)}
        />
      </li>
    ) : (
      <li
        css={styles.personalisationOption}
        key={option.id}
        onFocus={() => onFocus(option, '3 and more values selection')}
      >
        <MultipleOptionSelectionContainer
          {...(option as SelectionProductOption)}
          displayValidation={displayValidation}
          forwardedRef={wrapperRef}
        />
      </li>
    );
  }

  return (
    <li
      css={styles.personalisationOption}
      key={option.id}
      onFocus={() => onFocus(option, 'input field')}
    >
      <InputContainer
        {...(option as PersonalisationProductOption)}
        allowLineBreaks={false}
        displayValidation={displayValidation}
        onChange={onChange!}
        required={option.required}
      />
    </li>
  );
};
