import React from 'react';
import { Image, Price, PrimaryLabel } from '@noths/polaris-client-ribbons-design-system';
import {
  getProductImageURL,
  isMiddleMouseClick,
  linkClickOpensInNewTab,
} from '@noths/polaris-client-utils';
import type { CurrencyCode, RecommendedProduct } from '@noths/polaris-dev-ts-types';

import { RELATED_PREVIOUS_PRICE, RELATED_PRICE } from '.';
import type { RelatedProductClickPayload } from 'src/components/organisms/PartnerStorefront/modules';
import * as styles from 'src/components/organisms/PartnerStorefront/styles/PartnerStorefront.styles';

export interface PartnerProductCardProps {
  currency: CurrencyCode;
  lazyImage?: boolean;
  onRelatedProductClick: (payload: RelatedProductClickPayload) => void;
  product: RecommendedProduct;
}

export const PartnerProductCard = ({
  currency,
  lazyImage = true,
  onRelatedProductClick,
  product,
}: PartnerProductCardProps) => {
  const price = product.prices?.find((x) => x.currency === currency);
  const preSalePrice = product.pre_sale_prices?.find((x) => x.currency === currency);

  const handleLinkClick = (isMousedown: boolean) => (evt: React.MouseEvent<HTMLAnchorElement>) => {
    const opensInNewTab = linkClickOpensInNewTab(evt) || isMiddleMouseClick(evt);

    if (isMousedown && !isMiddleMouseClick(evt)) {
      return;
    }

    if (!opensInNewTab) {
      evt.preventDefault();
    }

    onRelatedProductClick({
      code: product.code,
      href: product.links[0].href || '',
      shouldUseEventCallback: !opensInNewTab,
    });
  };

  return (
    <div css={styles.relatedProductWrapper} key={product.code}>
      <h3 css={styles.productHeading}>
        <a
          css={styles.imageLink}
          href={product.links[0].href || ''}
          onClick={handleLinkClick(false)}
          onMouseDown={handleLinkClick(true)}
        >
          <div css={styles.imageWrapper}>
            <Image
              css={styles.image}
              loading={lazyImage ? 'lazy' : 'eager'}
              src={getProductImageURL(product.images[0].href, 'normal')}
            />
          </div>
          <p css={styles.productTitle}>{product.title}</p>
        </a>
      </h3>
      <PrimaryLabel
        className="similar-product-image__label"
        css={styles.productLabel}
        hasStock={product.purchasable}
        isNew={product.new}
        onSale={product.on_sale}
        salePercentage={product.sale_percentage}
      />
      <div css={styles.productPrices}>
        <Price
          amount={price!.amount}
          css={styles.productPrice}
          currency={currency}
          isSalePrice={!!(product.on_sale && preSalePrice)}
          testId={RELATED_PRICE}
        />
        {product.on_sale && preSalePrice && (
          <Price
            css={styles.productPreviousPrice}
            isPreviousPrice={true}
            testId={RELATED_PREVIOUS_PRICE}
            {...preSalePrice}
          />
        )}
      </div>
    </div>
  );
};
