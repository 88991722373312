import { theme } from '@noths/polaris-client-ribbons-base';
import { LOADING_SPINNER_CLASS } from '@noths/polaris-client-ribbons-design-system';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const loadingSpinner = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .${LOADING_SPINNER_CLASS} {
    width: ${pxToRem(spacing.xxxl)};
    height: ${pxToRem(spacing.xxxl)};
  }
`;
