/* istanbul ignore file */

export const REVIEWED_ON = 'Reviewed on';
export const REVIEW_TOOLTIP_BUTTON = "What's our product rating system";
export const REVIEW_TOOLTIP_CONTENT =
  'We always make it our business to listen to our customers, and this is the reason we have chosen to work with Feefo, the most trusted independent customer feedback forum. When you shop with us, you will receive a short survey email from Feefo following the dispatch of your item(s) asking you to rate your experience with us. This gives you the chance to tell us anything at all – be it good or bad. The important thing is to be honest in order to help us, our sellers and improve your future shopping experience. You will be asked to rate the overall service you received as well as your purchased item(s), choosing to rate each one out of five stars and add a comment if you wish. Product ratings and reviews are displayed on the product page.';
export const NO_REVIEWS = 'Sorry, there are no reviews for this product yet.';

export const PAGINATION_PREVIOUS_PAGE = 'Previous page';
export const PAGINATION_NEXT_PAGE = 'Next page';
