import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import type { Price } from '@noths/polaris-dev-ts-types';
import getConfig from 'next/config';
import qs from 'querystring';

import type { NextConfig } from 'src/types/nextConfig';
import type { DeliverySettingsAPIDeliveryZone } from './types/DeliverySettingsAPIDeliveryZone';

const { publicRuntimeConfig } = getConfig() as NextConfig;

export const fetchDeliveryOptions = async (productCode: number, price: Price) => {
  const DELIVERY_OPTIONS_ENDPOINT = publicRuntimeConfig.endpoints.deliverySettings;
  const { amount, currency } = price;

  const formattedAmount = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })
    .format(amount / 100)
    .replace(',', '');

  const querystring = qs.encode({
    amount: formattedAmount,
    currency,
  });
  const url = `${DELIVERY_OPTIONS_ENDPOINT}v1/products/${productCode}/multiple-zone-delivery-options?${querystring}`;
  const response = await fetchJSONWithTimeout(url, { timeout: 2000 });
  const deliveryOptions: DeliverySettingsAPIDeliveryZone[] = await response.json();

  return deliveryOptions;
};
