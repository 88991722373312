/* istanbul ignore file */

import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';
import type { RecommendedProduct } from '@noths/polaris-dev-ts-types';

import {
  recommendedProductsShelfNavigateCarousel,
  recommendedProductsShelfProductClick,
  recommendedProductsShelfVisible,
} from 'src/components/organisms/RecommendedProductsShelf/modules/actions';
import type { PlacementEntry } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getPersonalisationBreakdown } from 'src/redux/personalisation/helpers/selectorHelpers';
import { generateProductsForEvent } from 'src/utils/googleAnalytics';
import type {
  ProductClickEvent,
  ProductImpressionsInitialEvent,
  ProductShelfImpressionEvent,
  ProductShelfNavigationImpression,
} from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [recommendedProductsShelfVisible.type]: (prevState, _nextState, action) => {
    const {
      payload: { placementType },
    } = action as ReturnType<typeof recommendedProductsShelfVisible>;
    const { placementStrategy = '', products } = prevState.richRelevance[placementType];

    sendGAEvent<ProductImpressionsInitialEvent>({
      ecommerce: null,
    });

    const eventLabel = `${placementStrategy || ''} : ${placementType}`;

    const { amountAdded: addedPersonalisationCost, amountRemoved: removedPersonalisationCost } =
      getPersonalisationBreakdown(prevState.personalisation.selectedPersonalisations, 'GBP');

    sendGAEvent<ProductShelfImpressionEvent>({
      event: 'product_impressions',
      event_category: 'Rich Relevance',
      event_action: 'Product Impressions',
      event_label: eventLabel,
      ecommerce: {
        impressions: generateProductsForEvent({
          products: products!,
          placementName: placementType,
          placementStrategy,
          personalisationsTotalAmount: addedPersonalisationCost - removedPersonalisationCost,
        }),
      },
    });
  },
  [recommendedProductsShelfProductClick.type]: (prevState, _nextState, action) => {
    const {
      payload: { placementType, productIndex, shouldUseEventCallback },
    } = action as ReturnType<typeof recommendedProductsShelfProductClick>;
    const { placementStrategy = '', products }: PlacementEntry<RecommendedProduct> =
      prevState.richRelevance[placementType];

    if (products === null) {
      /*
        This would result from an API request failing, however this function shouldn't be callable in that case.
        This guard is in place for type safety.
      */
      return;
    }

    const positionInList = productIndex + 1;
    const product = products[productIndex];

    sendGAEvent<ProductImpressionsInitialEvent>({
      ecommerce: null,
    });

    const eventLabel = `${positionInList} : ${placementStrategy || ''} : ${placementType}`;

    const { amountAdded: addedPersonalisationCost, amountRemoved: removedPersonalisationCost } =
      getPersonalisationBreakdown(prevState.personalisation.selectedPersonalisations, 'GBP');

    sendGAEvent<ProductClickEvent>({
      event: 'product_clicks',
      event_category: 'Rich Relevance',
      event_action: 'Product Click',
      event_label: eventLabel,
      algonomy_tracking_URL: product.linkURL,
      ecommerce: {
        click: {
          actionField: {
            list: `product - rich relevance - ${placementType} - ${placementStrategy}`,
          },
          products: generateProductsForEvent({
            products: [product!],
            placementName: placementType,
            placementStrategy,
            positionInList,
            personalisationsTotalAmount: addedPersonalisationCost - removedPersonalisationCost,
          }),
        },
      },
    }).then(() => {
      if (shouldUseEventCallback) {
        window.location.assign(product.links[0]?.href || '');
      }
    });
  },
  [recommendedProductsShelfNavigateCarousel.type]: (prevState, _nextState, action) => {
    const { payload } = action as ReturnType<typeof recommendedProductsShelfNavigateCarousel>;
    const {
      navigation: { direction, type },
      placementType,
    } = payload;

    const { placementStrategy = '' }: PlacementEntry<RecommendedProduct> =
      prevState.richRelevance[placementType];

    const eventLabel = `${placementStrategy} : ${placementType}`;

    const navigationType = type === 'Arrow Click' ? 'Arrow click' : 'Swipe';
    const navigationDirection = direction === 'Right' ? 'next' : 'previous';

    sendGAEvent<ProductShelfNavigationImpression>({
      event: 'custom_event',
      event_category: 'Rich Relevance',
      event_action: `${navigationType} ${navigationDirection}`,
      event_label: eventLabel,
    });
  },
};
