import { maxBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { STICKY_SECTION_CLASS } from 'src/components/molecules/StickySection/components/StickySection';

const { spacing } = theme;

export const ctaSectionWrapper = css`
  display: flex;
  align-items: center;
  margin: 0 0 ${spacing.xxl}px;

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      .${STICKY_SECTION_CLASS} & {
        margin: 0 0 ${spacing.lg}px;
      }
    `,
  )}
`;

export const ctaButton = css`
  cursor: pointer;
`;
