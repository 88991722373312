/* istanbul ignore file */

import type { MouseEvent } from 'react';
import React from 'react';
import { FavouriteButton, PrimaryLabel } from '@noths/polaris-client-ribbons-design-system';
import { getProductImageURL } from '@noths/polaris-client-utils';
import Image from 'next/image';

import { PRODUCT_IMAGES_SECTION_LABEL_SINGLE } from 'src/components/organisms/ProductImagesCarousel/constants';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/ProductImages.styles';
import type { OpenModalPayload } from 'src/components/organisms/ProductImagesCarousel/types';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';

interface SingleProductImageProps {
  onOpenModal: (payload: OpenModalPayload) => void;
  onUpdateFavourite: () => void;
  product: CatServiceAPIProduct;
  productIsFavourited: boolean;
}

const handleImageClick = (
  e: MouseEvent<HTMLAnchorElement>,
  onOpenModal: SingleProductImageProps['onOpenModal'],
) => {
  e.preventDefault();
  onOpenModal({ location: 'Main image' });
};

export const SingleProductImage = ({
  onOpenModal,
  onUpdateFavourite,
  product: { availability, available, images, isNew, onSale, purchasable, salePercentage },
  productIsFavourited,
}: SingleProductImageProps) => (
  <section aria-label={PRODUCT_IMAGES_SECTION_LABEL_SINGLE} css={styles.imagesWrapper}>
    <div css={styles.mainImageWrapperNoThumbnails}>
      <a
        css={styles.mainImageLink}
        href={getProductImageURL(images[0].href, 'original')}
        onClick={(e) => handleImageClick(e, onOpenModal)}
      >
        <Image
          alt={images[0].title}
          css={styles.mainImage}
          fill={true}
          priority={true}
          src={getProductImageURL(images[0].href, 'original')}
          unoptimized
        />
      </a>
      <PrimaryLabel
        className="product-image__label"
        css={styles.productLabel}
        hasStock={purchasable}
        isNew={isNew}
        onSale={onSale}
        salePercentage={salePercentage}
        unavailable={!available && availability === 'suspended'}
      />
    </div>
    <div css={styles.favouriteButtonWrapper}>
      <FavouriteButton
        active={productIsFavourited}
        css={styles.favouriteButton}
        onClick={onUpdateFavourite}
        size="large"
      />
    </div>
  </section>
);
