import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { trackLoop54Event } from 'src/redux/loop54';
import * as basketAPIMutations from 'src/services/basket-api/mutations';
import * as basketAPIQueries from 'src/services/basket-api/queries';
import type {
  BasketAPIBasketQuery,
  BasketAPIBasketWithItemsQuery,
  BasketAPIMutationTrackingData,
} from 'src/services/basket-api/types/BasketAPIResponseBody';
import type { ProductOptionInputWithoutLabel } from 'src/services/basket-api/types/graphql';
import type { CatServiceAPIProductOptionValue } from 'src/services/cat-service-api/types/CatServiceAPIProductOption';
import type { SelectedPersonalisationsRecord } from '../personalisation';
import { dispatchBasketUpdatedEvent } from './domEvents';

export const getBasketForViewing = createAsyncThunk<BasketAPIBasketQuery>(
  'basket/getBasketForViewing',
  async () => await basketAPIQueries.getBasket(),
);

export const getBasketForEditing = createAsyncThunk<BasketAPIBasketWithItemsQuery>(
  'basket/getBasketForEditing',
  async () => await basketAPIQueries.getBasketWithItemOptions(),
);

export const mapProductOptionsToBasketOptions = (
  selectedPersonalisations: SelectedPersonalisationsRecord,
): ProductOptionInputWithoutLabel =>
  R.chain(
    (optionId) =>
      /* istanbul ignore next */
      selectedPersonalisations?.[optionId]?.map(
        ({ id, isCustomInput, name }: CatServiceAPIProductOptionValue) =>
          isCustomInput ? { optionId, value: name } : { optionId, valueId: String(id) },
      ) ?? [],
    Object.keys(selectedPersonalisations),
  );

interface AddItemToOrUpdateItemInBasketPayload {
  basket: BasketAPIBasketWithItemsQuery;
  trackingData: BasketAPIMutationTrackingData;
}

export const addItemToBasket = createAsyncThunk<AddItemToOrUpdateItemInBasketPayload>(
  'basket/addItemToBasket',
  async (_, { dispatch, getState }) => {
    const {
      personalisation: { selectedPersonalisations },
      product: { code, quantity },
      userConfiguration: { currencyCode },
    } = getState() as ReduxApplicationState;
    const options = mapProductOptionsToBasketOptions(selectedPersonalisations);

    const { basket, trackingData } = await basketAPIMutations.addItem(
      code,
      quantity,
      currencyCode,
      options,
    );

    dispatchBasketUpdatedEvent();
    dispatch(trackLoop54Event({ eventType: 'addtocart' }));

    return {
      basket,
      trackingData,
    };
  },
);

export const updateItemInBasket = createAsyncThunk<AddItemToOrUpdateItemInBasketPayload>(
  'basket/updateItemInBasket',
  async (_, { getState }) => {
    const {
      personalisation: { editedItem, selectedPersonalisations },
      product: { quantity },
      userConfiguration: { currencyCode },
    } = getState() as ReduxApplicationState;
    const options = mapProductOptionsToBasketOptions(selectedPersonalisations);

    const { basket, trackingData } = await basketAPIMutations.updateItem(
      editedItem!,
      quantity,
      currencyCode,
      options,
    );

    dispatchBasketUpdatedEvent();

    return {
      basket,
      trackingData,
    };
  },
);
