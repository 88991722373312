export const WAIT_FOR_ELEMENT_TIMEOUT_IN_MS = 1000;

export const waitForElement = async (selector: string, maxWait = WAIT_FOR_ELEMENT_TIMEOUT_IN_MS) =>
  new Promise<Element | null>((resolve) => {
    let ms = 100;
    const interval: NodeJS.Timeout = setInterval(function step() {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
        clearInterval(interval);
      }

      if (ms >= maxWait) {
        resolve(null);
        clearInterval(interval);
      }

      ms += ms;
    }, ms);
  });
