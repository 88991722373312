import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';

export const isOutOfStock = (inventoryStatus: CatServiceAPIProduct['inventoryStatus']) =>
  inventoryStatus === 'out_of_stock';

export const isSuspended = (
  availability: CatServiceAPIProduct['availability'],
  available: boolean,
) => !available && availability === 'suspended';

export const isMadeToOrder = (inventoryStatus: CatServiceAPIProduct['inventoryStatus']) =>
  inventoryStatus === 'made_to_order';
