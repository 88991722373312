export enum Placement {
  ActiveProducts = 'item_page.rr_new_1', //
  AddToBasket = 'add_to_cart_page.new_rr1', //
  OutOfStock = 'item_page.OOS',
  RecentlyViewed = 'item_page.recently_viewed',
  SameSeller = 'item_page.same_seller',
  SimilarProducts = 'item_page.entrypoint',
}

// Note: products null when request fails or when 0 products returned
export type PlacementEntry<T> = {
  placementStrategy?: string;
  placementTitle?: string;
  products: T[] | null;
};

export type PlacementType<T> = Record<Placement, PlacementEntry<T>>;
