/* istanbul ignore file */

import { connect } from 'react-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectPersonalisationOptionsBreakdown,
  selectSelectedPersonalisationsTotal,
  selectTotalPrice,
} from 'src/redux/personalisation';
import { selectProductPrice } from 'src/redux/selectors';
import type { PriceBreakdownStateProps } from './PriceBreakdown';
import { PriceBreakdown } from './PriceBreakdown';

export const mapStateToProps = (state: ReduxApplicationState): PriceBreakdownStateProps => ({
  productPrice: selectProductPrice(state),
  totalPrice: selectTotalPrice(state),
  personalisationsTotalAmount: selectSelectedPersonalisationsTotal(state),
  breakdown: selectPersonalisationOptionsBreakdown(state),
});

export const PriceBreakdownContainer = connect(mapStateToProps)(PriceBreakdown);
