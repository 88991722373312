import type { DeliveryZoneCode } from '@noths/polaris-client-localisation';
import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';
import getConfig from 'next/config';
import qs from 'querystring';

import { ERROR_PRODUCT_NOT_FOUND } from 'src/constants/errors';
import type { NextConfig } from 'src/types/nextConfig';
import type { CatServiceAPIResponseBody } from './types/CatServiceAPIResponseBody';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const CATALOGUE_API_ENDPOINT = publicRuntimeConfig.endpoints.catalogueMesh;
const CLIENT_TIMEOUT = 4000;

interface FetchProductDetailsOptions {
  cookie?: string;
  currencyCode?: CurrencyCode;
  deliveryZone?: DeliveryZoneCode;
  previewing?: boolean;
}

export const fetchProductDetails = async (
  uri: string,
  { cookie, currencyCode, deliveryZone, previewing }: FetchProductDetailsOptions = {},
) => {
  const querystring = qs.encode({
    uri,
    ...(currencyCode ? { currencyCode } : {}),
    ...(deliveryZone ? { deliveryZone } : {}),
    ...(previewing ? { previewing } : {}),
  });
  const headers = {
    ...(previewing && cookie
      ? {
          headers: {
            Cookie: cookie,
          },
        }
      : {}),
  };
  const url = `${CATALOGUE_API_ENDPOINT}v1/product-details${querystring && `?${querystring}`}`;

  const response = (await fetchJSONWithTimeout(url, {
    timeout: CLIENT_TIMEOUT,
    redirect: 'manual',
    ...(headers && headers),
  })) as Response;

  if (response.status === 301 && response.headers.get('location')) {
    const location = new URL(response.headers.get('location') || '');

    return {
      redirectUrl: `${location.pathname}${location.search}`,
    };
  }

  if (response.status === 404) {
    const error = new Error(`404 on fetching product details: ${url} ${response.statusText}`);
    error.name = ERROR_PRODUCT_NOT_FOUND;
    throw error;
  }

  if (!response.ok) {
    throw new Error(`Error fetching product details: ${url} ${response.statusText}`);
  }

  const data: CatServiceAPIResponseBody = await response.json();

  return { data };
};
