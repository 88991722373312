import React from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';
import { SolidButton, SolidButtonLink } from '@noths/polaris-client-ribbons-design-system';

import { ADD_TO_BASKET_CTA, BUY_GIFT_CARD_CTA, EDIT_PERSONALISATION_CTA } from './copy';
import { MAIN_PAGE_CTA_ID } from './elementIDs';
import * as styles from './BasketCTAButton.styles';

export interface BasketCTAButtonDispatchProps {
  onAddEditClick: VoidFunction;
  onBuyGiftCard: (ev: React.MouseEvent) => void;
  onVisible: () => void;
}

export interface BasketCTAButtonStateProps {
  editMode: boolean;
  loading: boolean;
  purchasable: boolean;
}

type BasketCTAButtonProps = BasketCTAButtonDispatchProps & BasketCTAButtonStateProps;

export const BasketCTAButton = ({
  editMode,
  loading,
  onAddEditClick,
  onBuyGiftCard,
  onVisible,
  purchasable,
}: BasketCTAButtonProps) => {
  const rootRef = useImpressionRef<HTMLDivElement>(() => onVisible());

  return (
    <div css={[styles.ctaSectionWrapper]} ref={rootRef}>
      {purchasable ? (
        <SolidButton
          css={styles.ctaButton}
          disabled={loading}
          fullWidth
          id={MAIN_PAGE_CTA_ID}
          loading={loading}
          onClick={() => onAddEditClick()}
          type="button"
        >
          {editMode ? EDIT_PERSONALISATION_CTA : ADD_TO_BASKET_CTA}
        </SolidButton>
      ) : (
        <SolidButtonLink
          css={styles.ctaButton}
          fullWidth
          href="/gift-cards"
          id={MAIN_PAGE_CTA_ID}
          onClick={onBuyGiftCard}
        >
          {BUY_GIFT_CARD_CTA}
        </SolidButtonLink>
      )}
    </div>
  );
};
