import React from 'react';
import { IconEmail, Image, TextButton } from '@noths/polaris-client-ribbons-design-system';

import { PARTNER_CARD_TEST_ID } from 'src/components/organisms/PartnerStorefront/constants';
import * as copy from 'src/components/organisms/PartnerStorefront/constants/copy';
import * as styles from 'src/components/organisms/PartnerStorefront/styles/PartnerCard.styles';
import type { CatServiceAPIPartner } from 'src/services/cat-service-api/types/CatServiceAPIPartner';

export interface PartnerCardProps {
  onContactSellerClick: VoidFunction;
  onLogoClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onPartnerNameClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  partner: CatServiceAPIPartner;
  partnerLinkHref: string;
}

export const PartnerCard = ({
  onContactSellerClick,
  onLogoClick,
  onPartnerNameClick,
  partner,
  partnerLinkHref,
}: PartnerCardProps) => {
  const { location, logo, name } = partner;
  return (
    <section data-testid={PARTNER_CARD_TEST_ID}>
      {logo && (
        <a
          css={styles.imageWrapper}
          href={partnerLinkHref}
          onClick={onLogoClick}
          rel="nofollow"
          tabIndex={-1}
        >
          <Image css={styles.image} loading="lazy" src={logo} />
        </a>
      )}
      <h2 css={styles.heading}>
        <a href={partnerLinkHref} onClick={onPartnerNameClick} rel="nofollow">
          {name}
        </a>
      </h2>
      <div css={[styles.copyWrapper]}>
        <p css={styles.location}>{location}</p>
        <TextButton
          css={styles.contactSeller}
          onClick={(e) => {
            e.preventDefault();
            onContactSellerClick();
          }}
        >
          <span css={styles.contactSellerIcon}>
            <IconEmail color={`var(--email-icon-color)`} height={16} width={16} />
          </span>
          {copy.CONTACT_SELLER}
        </TextButton>
      </div>
    </section>
  );
};
