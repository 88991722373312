import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const fileLinkWrapper = css`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;

export const fileLink = css`
  margin-left: ${pxToRem(spacing.xs)};
`;

export const fileIcon = css`
  align-self: flex-start;
`;
