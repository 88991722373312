import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { selectDeliveryByEventCost } from 'src/components/molecules/DeliveryEvent/selectors';
import { getDeliveryByEventLabel } from 'src/components/molecules/DeliveryEvent/utils';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectDecoratedDeliveryOptions,
  selectMinMaxDeliveryEstimates,
} from 'src/redux/deliveryZoneOptions';
import { requestDeliveryZoneOptions } from 'src/redux/deliveryZoneOptions/thunks';
import { isDeliveryAvailableByEvent } from 'src/redux/deliveryZoneOptions/utils';
import { selectProductDeliveryByEvent } from 'src/redux/selectors';
import type { DeliveryInfoEvent, DeliveryInfoEventUnavailableDelivery } from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [requestDeliveryZoneOptions.fulfilled.type]: (_, nextState) => {
    const countryDeliveryOptions = selectDecoratedDeliveryOptions(nextState);

    if (!countryDeliveryOptions.length) {
      const { selectedCountry, selectedZone } = nextState.deliveryZoneOptions;

      sendGAEvent<DeliveryInfoEventUnavailableDelivery>({
        event: 'custom_event',
        event_action: 'Delivery info',
        event_category: 'Delivery & returns',
        event_label: `${selectedCountry} - ${selectedZone}`,
        delivery: {
          delivery_by_event: 'not set',
          delivery_by_event_cost: 'not set',
          max_delivery_time: 'not set',
          min_delivery_time: 'not set',
          options: 'none',
        },
      });
    } else {
      const countryDeliveryOptionCodesWithDeliveryProvider = countryDeliveryOptions
        .map((opt) => `${opt.optionCode} ${opt.providerName}`)
        .join(', ');

      const { max: deliveryOptionMax, min: deliveryOptionMin } =
        selectMinMaxDeliveryEstimates(nextState);

      const deliveryByEventCheapestPrice = selectDeliveryByEventCost(nextState);
      const productDeliveryByEvent = selectProductDeliveryByEvent(nextState);
      const deliveryEventIsAvailableForZone = Boolean(deliveryByEventCheapestPrice !== 'not set');
      const deliveryByEvent = isDeliveryAvailableByEvent(
        deliveryEventIsAvailableForZone,
        productDeliveryByEvent,
      )
        ? getDeliveryByEventLabel(productDeliveryByEvent!.eventName).replace(' for', '')
        : 'not set';

      sendGAEvent<DeliveryInfoEvent>({
        event: 'custom_event',
        event_action: 'Delivery info',
        event_category: 'Delivery & returns',
        event_label: countryDeliveryOptionCodesWithDeliveryProvider,
        delivery: {
          options: countryDeliveryOptionCodesWithDeliveryProvider,
          min_delivery_time: deliveryOptionMin,
          max_delivery_time: deliveryOptionMax,
          delivery_by_event: deliveryByEvent,
          delivery_by_event_cost: String(deliveryByEventCheapestPrice),
        },
      });
    }
  },
};
