import emojiRegex from 'emoji-regex';

const regex = emojiRegex();

export const containsEmoji = (inputText: string) => {
  for (const match of inputText.matchAll(regex)) {
    const emoji = match[0];
    if (emoji) {
      return true;
    }
  }

  return false;
};
