import { connect } from 'react-redux';

import type { OwnProps } from 'src/components/organisms/Personalisation/components/Input';
import { Input } from 'src/components/organisms/Personalisation/components/Input';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectOutstandingPersonalisations, selectSelectedOption } from 'src/redux/personalisation';

const mapStateToProps = (state: ReduxApplicationState, props: OwnProps) => ({
  error:
    Boolean(props.displayValidation) &&
    selectOutstandingPersonalisations(state).some((opt) => opt.id === props.id),
  value: selectSelectedOption(state, props.id)?.[0]?.name,
});

export const InputContainer = connect(mapStateToProps)(Input);
