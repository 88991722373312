// basket-api schema and docs: https://basket-api-shared.qa.noths.com/graphql

export const ADD_ITEM = `
mutation addItem($code: String!, $quantity: Int!, $currencyCode: Currency, $options: [ProductOptionInput]) {
  addItem(code: $code, quantity: $quantity, options: $options) {
    basket(currency: $currencyCode) {
      itemsCount,
      itemsTotal { currency, cents }, 
    }
    errors { code, path, message }
    success
    trackingData: basket(currency: GBP) {
      analyticsId, discountedItemsTotal { currency, cents },
      items { quantity, purchasePrice { currency, cents }, product { code, prices { listPrice { currency, cents } } } }
    } 
  }
}
`;

export const UPDATE_ITEM = `mutation updateItem($id: ID!, $quantity: Int!, $currencyCode: Currency, $options: [ProductOptionInput]) {
    updateItem(id: $id, options: $options, quantity: $quantity) {
      basket(currency: $currencyCode) {
        itemsCount,
        itemsTotal { currency, cents }, 
        items {
          id
          quantity
        }
      }
      errors { code, path, message }
      success
    }
  }`;
