import React from 'react';
import {
  IconArrowBack,
  IconArrowForward,
  IconButton,
  SingleNumberButton,
} from '@noths/polaris-client-ribbons-design-system';

import {
  PAGINATION_NEXT_PAGE,
  PAGINATION_PREVIOUS_PAGE,
} from 'src/components/organisms/ProductReviews/constants/copy';
import { getPages } from 'src/components/organisms/ProductReviews/helpers/paginationHelper';
import * as styles from 'src/components/organisms/ProductReviews/styles/ProductReviews.styles';
import type { ReviewPagination } from 'src/types/reviews';

interface PaginationProps {
  loadPageOfReviews: (page: number) => void;
  onProductReviewsNextPage: () => void;
  onProductReviewsPagination: (page: number) => void;
  pagination: ReviewPagination;
}

export const REVIEWS_PAGINATION_LIST_TESTID = 'REVIEWS_PAGINATION_LIST';

export const Pagination = ({
  loadPageOfReviews,
  onProductReviewsNextPage,
  onProductReviewsPagination,
  pagination: { currentPage, totalPages },
}: PaginationProps) => {
  const pages = getPages(currentPage, totalPages);

  const handlePaginateNextPage = (page: number) => {
    loadPageOfReviews(page);
    onProductReviewsNextPage();
  };

  const handlePageNumberClick = (page: number) => {
    loadPageOfReviews(page);
    onProductReviewsPagination(page);
  };

  return (
    <ul css={styles.paginationList} data-testid={REVIEWS_PAGINATION_LIST_TESTID}>
      <li css={styles.paginationListItem}>
        <IconButton
          Icon={IconArrowBack}
          aria-label={PAGINATION_PREVIOUS_PAGE}
          disabled={currentPage === 1}
          onClick={() => loadPageOfReviews(currentPage - 1)}
          size="small"
        />
      </li>
      {pages.map((page, index) =>
        page !== '...' ? (
          <li css={styles.paginationListItem} key={`pagination-${page}`}>
            <SingleNumberButton
              active={currentPage === page}
              aria-label={`Page ${page}`}
              number={page}
              onClick={() => handlePageNumberClick(page)}
              size="large"
            />
          </li>
        ) : (
          <li css={styles.paginationListItem} key={`pagination-ellipsis-${index + 1}`}>
            {page}
          </li>
        ),
      )}
      <li css={styles.paginationListItem}>
        <IconButton
          Icon={IconArrowForward}
          aria-label={PAGINATION_NEXT_PAGE}
          disabled={currentPage === totalPages}
          onClick={() => handlePaginateNextPage(currentPage + 1)}
          size="small"
        />
      </li>
    </ul>
  );
};
