import type { MouseEvent } from 'react';
import React, { useEffect } from 'react';
import { FavouriteButton, PrimaryLabel } from '@noths/polaris-client-ribbons-design-system';
import { getProductImageURL, isKeyboardTriggeredEvent } from '@noths/polaris-client-utils';
import Image from 'next/image';

import { PreventSwipeNavigation } from 'src/components/molecules/PreventSwipeNavigation/PreventSwipeNavigation';
import {
  PRODUCT_IMAGE_TABPANEL_CLASS,
  PRODUCT_IMAGES_TAB_ID_PREFIX,
  PRODUCT_IMAGES_TABPANEL_CONTAINER_ID,
  PRODUCT_IMAGES_TABPANEL_ID_PREFIX,
  PROGRESS_INDICATOR_TEST_ID,
} from 'src/components/organisms/ProductImagesCarousel/constants';
import { ThumbnailsContainer } from 'src/components/organisms/ProductImagesCarousel/containers/ThumbnailsContainer';
import { useProductImagesCarousel } from 'src/components/organisms/ProductImagesCarousel/hooks/useProductImagesCarousel';
import * as styles from 'src/components/organisms/ProductImagesCarousel/styles/ProductImages.styles';
import type {
  ImagesLocation,
  NextOrPreviousPayload,
  OpenModalPayload,
  ProductImagesState,
  SetCurrentImagePayload,
} from 'src/components/organisms/ProductImagesCarousel/types';
import { ThumbnailOrientation } from 'src/components/organisms/ProductImagesCarousel/types';
import type { CatServiceAPIProduct } from 'src/services/cat-service-api/types/CatServiceAPIProduct';
import { getIconPath } from 'src/utils/assetPaths';
import { DecorateWithZoomAndPanWrapper } from './ProductImageModal/DecorateWithZoomAndPanWrapper';

const ICON_ARROW = getIconPath('icons-24-arrow-right.svg');

const handleImageClick = (
  e: MouseEvent<HTMLAnchorElement>,
  onOpenModal: MultipleProductImagesProps['onOpenModal'],
) => {
  e.preventDefault();
  e.stopPropagation();
  onOpenModal({ location: 'Main image' });
};

const handleNavigationButtonClick = (
  e: MouseEvent<HTMLButtonElement>,
  navigationAction: VoidFunction,
) => {
  e.preventDefault();
  e.stopPropagation();
  navigationAction();
};

export interface MultipleProductImagesProps {
  currentImage: ProductImagesState['currentImage'];
  location: ImagesLocation;
  navigatedBy: ProductImagesState['navigatedBy'];
  onBeforeSwipe?: () => void;
  onNextImage: (payload: NextOrPreviousPayload) => void;
  onOpenModal: (payload: OpenModalPayload) => void;
  onPrevImage: (payload: NextOrPreviousPayload) => void;
  onUpdateFavourite: () => void;
  product: CatServiceAPIProduct;
  productIsFavourited: boolean;
  setCurrentImage: (payload: SetCurrentImagePayload) => void;
}

export const MultipleProductImages = ({
  currentImage,
  location,
  navigatedBy,
  onBeforeSwipe,
  onNextImage,
  onOpenModal,
  onPrevImage,
  onUpdateFavourite,
  product: { availability, available, images, isNew, onSale, purchasable, salePercentage },
  productIsFavourited,
  setCurrentImage,
}: MultipleProductImagesProps) => {
  const [imageFocus, setImageFocus] = React.useState(false);
  const [hasInteractedWithCarousel, setHasInteractedWithCarousel] = React.useState(false);
  const [loadedImages, setImageLoaded] = React.useState<Record<number, boolean>>({});
  const totalImages = images.length;
  const isExpandedImage = location === 'Expanded image';

  useEffect(() => {
    if (currentImage > 0) {
      // User has navigated past the first image
      setHasInteractedWithCarousel(true);
    }
  }, [currentImage]);

  const [carouselContainerRef, carouselItemRefs] = useProductImagesCarousel(
    (entry) => {
      onBeforeSwipe?.();
      const imageIndex = Number((entry.target as HTMLElement).dataset.itemIndex);
      if (currentImage != imageIndex) {
        setCurrentImage({ imageIndex, navigationType: 'swipe', location });
      }
    },
    currentImage,
    navigatedBy,
    images.length,
  );

  return (
    <PreventSwipeNavigation
      aria-live="polite"
      css={[styles.imagesWrapper, isExpandedImage && styles.fullScreen]}
    >
      <div css={styles.imageThumbnails}>
        <ThumbnailsContainer
          location={location}
          onThumbnailClick={(imageIndex) =>
            setCurrentImage({
              imageIndex: imageIndex,
              navigationType: 'click',
              location,
            })
          }
          orientation={ThumbnailOrientation.VERTICAL}
        />
      </div>
      <div css={[styles.carouselContainer, isExpandedImage && styles.fullScreen]}>
        <div
          css={[styles.mainImageWrapperWithThumbnails, isExpandedImage && styles.fullScreen]}
          data-image-focus={imageFocus}
          id={PRODUCT_IMAGES_TABPANEL_CONTAINER_ID}
          ref={carouselContainerRef}
        >
          {images.map(({ href: src, title }, i) => (
            <div
              aria-hidden={currentImage !== i}
              aria-labelledby={`${PRODUCT_IMAGES_TAB_ID_PREFIX}${i}`}
              className={PRODUCT_IMAGE_TABPANEL_CLASS}
              css={styles.carouselSlide}
              data-item-index={i}
              id={`${PRODUCT_IMAGES_TABPANEL_ID_PREFIX}${i}`}
              key={src}
              ref={(node) => {
                carouselItemRefs.current[i] = node as HTMLElement;
              }}
              role="tabpanel"
            >
              <a
                css={styles.mainImageLink}
                href={getProductImageURL(src, 'original')}
                onBlur={() => setImageFocus(false)}
                onClick={(e) => handleImageClick(e, onOpenModal)}
                onFocus={(e) => setImageFocus(isKeyboardTriggeredEvent(e))}
                tabIndex={currentImage === i ? undefined : -1}
              >
                <DecorateWithZoomAndPanWrapper
                  enabled={Boolean(isExpandedImage)}
                  loadingSpinner={!loadedImages[i]}
                  shouldDisplay={currentImage === i}
                >
                  <Image
                    alt={`${title}, ${i + 1} of ${images.length}`}
                    css={styles.mainImage}
                    fill={true}
                    onLoad={() => setImageLoaded({ ...loadedImages, [i]: true })}
                    priority={i === 0}
                    src={getProductImageURL(
                      src,
                      i === 0 || hasInteractedWithCarousel ? 'original' : 'normal',
                    )}
                    unoptimized
                  />
                </DecorateWithZoomAndPanWrapper>
              </a>
            </div>
          ))}
          <PrimaryLabel
            className="product-image__label"
            css={styles.productLabel}
            hasStock={purchasable}
            isNew={isNew}
            onSale={onSale}
            salePercentage={salePercentage}
            unavailable={!available && availability === 'suspended'}
          />
        </div>
        <button
          aria-controls={PRODUCT_IMAGES_TABPANEL_CONTAINER_ID}
          aria-label="Show previous image"
          css={[styles.carouselNavigationButton, styles.prevNavigationButton]}
          onClick={(e) =>
            handleNavigationButtonClick(e, () => onPrevImage({ location, totalImages }))
          }
        >
          <img alt="" height={24} src={ICON_ARROW} width={24} />
        </button>
        <button
          aria-controls={PRODUCT_IMAGES_TABPANEL_CONTAINER_ID}
          aria-label="Show next image"
          css={[styles.carouselNavigationButton, styles.nextNavigationButton]}
          onClick={(e) =>
            handleNavigationButtonClick(e, () => onNextImage({ location, totalImages }))
          }
        >
          <img alt="" height={24} src={ICON_ARROW} width={24} />
        </button>
        <p
          aria-hidden="true"
          css={styles.bottomCenterProgressIndicator}
          data-testid={PROGRESS_INDICATOR_TEST_ID}
        >
          {currentImage + 1} of {images.length}
        </p>
        <div css={styles.favouriteButtonWrapper}>
          <FavouriteButton
            active={productIsFavourited}
            css={styles.favouriteButton}
            onClick={onUpdateFavourite}
            size="large"
          />
        </div>
      </div>
    </PreventSwipeNavigation>
  );
};
