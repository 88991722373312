export const NAME_FIELD_LABEL = 'Full name';
export const NAME_FIELD_ERROR = 'Please enter your full name';
export const MESSAGE_SENT = 'Message sent';
export const RETURN_TO_PRODUCT_CTA = 'Return to product';
export const SUBMIT_CTA = 'Submit';
export const EMAIL_FIELD_LABEL = 'Email address';
export const EMAIL_FIELD_ERROR = 'Please enter your email address';
export const EMAIL_FIELD_VALIDATION_ERROR = 'Please enter a valid email address';
export const EMAIL_SPAM_REMINDER =
  'To ensure the seller’s response reaches your inbox please add donotreply@notonthehighstreet.com to your email address book.';
export const QUESTION_FIELD_LABEL = "What's your question?";
export const QUESTION_FIELD_ERROR = 'Please enter your question';
export const SENSITIVE_INFO_TEXT =
  'Please do not include any sensitive personal details or credit card information in your message. Your personal security is very important to us.';
export const CHECKBOX_LABEL =
  'Tick to subscribe to receive updates about new products and offers from Notonthehighstreet. You can opt out at any time. See our <a href="https://www.notonthehighstreet.com/about/privacy">privacy policy</a> for more information.';
export const CONTACT_SELLER = 'Contact seller';
export const VISIT_SHOP = 'Visit shop';
