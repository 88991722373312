import { connect } from 'react-redux';

import { LinkCarousel } from 'src/components/organisms/LinkCarousel/components/LinkCarousel';
import { carouselVisible } from 'src/components/organisms/LinkCarousel/modules/actions';
import { selectRelatedCategories } from 'src/components/organisms/LinkCarousel/modules/selectors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

export const mapStateToProps = (state: ReduxApplicationState) => ({
  links: selectRelatedCategories(state).map((relatedCategory) => {
    return {
      href: relatedCategory.rel!,
      text: relatedCategory.title!,
    };
  }),
});

export const mapDispatchToProps = {
  onCarouselVisible: carouselVisible,
};

export const LinkCarouselContainer = connect(mapStateToProps, mapDispatchToProps)(LinkCarousel);
