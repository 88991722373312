import { reducer as userConfigurationReducer } from '@noths/polaris-client-user-configuration';
import { reducer as favouritesReducer } from '@noths/polaris-client-user-favourites';
import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'connected-next-router';

import { reducer as partnerStorefrontReducer } from 'src/components/organisms/PartnerStorefront';
import { reducer as productImagesCarouselReducer } from 'src/components/organisms/ProductImagesCarousel';
import { reducer as productInfoReducer } from 'src/redux/productInfo/slice';
import { reducer as accessibilityReducer } from './accessibility';
import { reducer as basketReducer } from './basket';
import { reducer as deliveryZoneOptionsReducer } from './deliveryZoneOptions';
import { reducer as personalisationReducer } from './personalisation';
import { reducer as productReducer } from './product';
import { reducer as reviewsReducer } from './reviews';
import { reducer as richRelevanceReducer } from './richRelevance';

export const combinedReducer = combineReducers({
  accessibility: accessibilityReducer,
  basket: basketReducer,
  deliveryZoneOptions: deliveryZoneOptionsReducer,
  favourites: favouritesReducer,
  partnerStorefront: partnerStorefrontReducer,
  personalisation: personalisationReducer,
  product: productReducer,
  productImagesCarousel: productImagesCarouselReducer,
  productInfo: productInfoReducer,
  reviews: reviewsReducer,
  richRelevance: richRelevanceReducer,
  router: routerReducer,
  userConfiguration: userConfigurationReducer,
});

export type ReduxApplicationState = ReturnType<typeof combinedReducer>;
