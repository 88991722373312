/* istanbul ignore file */

import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';
import type { RecommendedProduct } from '@noths/polaris-dev-ts-types';

import {
  actions,
  contactPartnerSubmit,
  submitMarketingPreferences,
} from 'src/components/organisms/PartnerStorefront/modules';
import type { PlacementEntry } from 'src/constants/recommendations';
import { Placement } from 'src/constants/recommendations';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { generateProductsForEvent } from 'src/utils/googleAnalytics';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [actions.openContactPartnerModal.type]: ({ product }) => {
    sendGAEvent({
      event: 'custom_event',
      event_action: 'Click',
      event_category: 'Contact seller',
      event_label: product.partner.name,
    });
  },
  [actions.closeContactPartnerModal.type]: ({ product }) => {
    sendGAEvent({
      event: 'custom_event',
      event_action: 'Close',
      event_category: 'Contact seller',
      event_label: product.partner.name,
    });
  },
  [contactPartnerSubmit.fulfilled.type]: ({ product }) => {
    sendGAEvent({
      event: 'custom_event',
      event_action: 'Submit',
      event_category: 'Contact seller',
      event_label: product.partner.name,
    });
  },
  [submitMarketingPreferences.fulfilled.type]: (_, __, action) => {
    const {
      meta: {
        arg: { email },
      },
    } = action as ReturnType<typeof submitMarketingPreferences.fulfilled>;

    sendGAEvent({
      event: 'custom_event',
      event_category: 'Marketing preferences',
      event_action: 'Subscribe',
      event_label: 'Contact seller',
      user_email: email,
    });
  },
  [actions.contactSellerFieldValidationError.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof actions.contactSellerFieldValidationError>;

    sendGAEvent({
      event: 'custom_event',
      event_action: 'Error',
      event_category: 'Contact seller',
      event_label: payload,
    });
  },
  [actions.partnerProductCardsVisible.type]: (prevState, __, action) => {
    const {
      payload: { products },
    } = action as ReturnType<typeof actions.partnerProductCardsVisible>;
    const { placementStrategy = '' }: PlacementEntry<RecommendedProduct> =
      prevState.richRelevance[Placement.SameSeller];

    const eventLabel = `${placementStrategy || ''} : ${Placement.SameSeller}`;

    sendGAEvent({
      ecommerce: null,
    });

    sendGAEvent({
      event: 'product_impressions',
      event_category: 'Rich Relevance',
      event_action: 'Product Impressions',
      event_label: eventLabel,
      ecommerce: {
        impressions: generateProductsForEvent({
          products,
          placementName: Placement.SameSeller,
          placementStrategy,
        }),
      },
    });
  },
  [actions.onRelatedProductClick.type]: (prevState, _nextState, action) => {
    const { payload } = action as ReturnType<typeof actions.onRelatedProductClick>;
    const { placementStrategy = '', products }: PlacementEntry<RecommendedProduct> =
      prevState.richRelevance[Placement.SameSeller];

    if (products === null) {
      return;
    }

    const productIndex = products.findIndex(({ code }) => payload.code === code);
    const positionInList = productIndex + 1;
    const product = products[productIndex];
    const eventLabel = `${positionInList} : ${placementStrategy || ''} : ${Placement.SameSeller}`;

    sendGAEvent({
      ecommerce: null,
    });

    sendGAEvent({
      event: 'product_clicks',
      event_action: 'Product Click',
      event_category: 'Rich Relevance',
      event_label: eventLabel,
      algonomy_tracking_URL: product.linkURL,
      ecommerce: {
        click: {
          actionField: {
            list: `product - rich relevance - ${Placement.SameSeller} - ${placementStrategy}`,
          },
          products: generateProductsForEvent(
            {
              products: [product],
              placementName: Placement.SameSeller,
              placementStrategy,
              positionInList,
            },
            ['list'],
          ),
        },
      },
    }).then(() => {
      if (payload.shouldUseEventCallback) {
        window.location.assign(payload.href);
      }
    });
  },
};
