import { connect } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';

import type { DispatchProps } from 'src/components/organisms/Personalisation/components/MultipleOptionSelection/MultipleOptionSelectionDrawer';
import { MultipleOptionSelectionDrawer } from 'src/components/organisms/Personalisation/components/MultipleOptionSelection/MultipleOptionSelectionDrawer';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type {
  InteractionErrorTrackingEvent,
  InteractionTrackingEvent,
  ProgressTrackingEvent,
} from 'src/redux/personalisation';
import {
  actions as personalisationActions,
  selectNumberOfRequiredPersonalisations,
  selectOutstandingPersonalisations,
  selectTotalPrice,
  selectTrackedEvents,
} from 'src/redux/personalisation';
import { selectPersonalisationOptions } from 'src/redux/selectors';

const mapStateToProps = (state: ReduxApplicationState) => ({
  options: selectPersonalisationOptions(state),
  totalPrice: selectTotalPrice(state),
  totalOutstandingSelections: selectOutstandingPersonalisations(state).length,
  totalRequiredPersonalisations: selectNumberOfRequiredPersonalisations(state),
  trackedEvents: selectTrackedEvents(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  trackInteraction: (payload: InteractionTrackingEvent) =>
    dispatch(personalisationActions.trackInteraction(payload)),
  trackProgressMilestone: (payload: ProgressTrackingEvent) =>
    dispatch(personalisationActions.trackProgressMilestone(payload)),
  trackInteractionError: (payload: InteractionErrorTrackingEvent) =>
    dispatch(personalisationActions.trackInteractionError(payload)),
});

export const MultipleOptionSelectionDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleOptionSelectionDrawer);
