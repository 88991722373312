import { dateUtils, getDeliveryByEventName } from '@noths/polaris-client-utils';
import type { DeliveryByEventName } from '@noths/polaris-dev-ts-types';

import type { DeliveryOptionInfo } from 'src/types/deliveryZoneOptions';

const DELIVERY_EVENT_LABEL = `{PREFIX} by {EVENT} for`;

type DeliveryOption = Pick<DeliveryOptionInfo, 'exactDate' | 'date' | 'min' | 'max'>;

export const hasExactDeliveryDate = ({ date, exactDate }: DeliveryOption) =>
  exactDate && date != null;

export const isLateDelivery = (date: Date | string, constrainingDate: Date | string) =>
  dateUtils.isAfter(date, constrainingDate);

export const getMaxDeliveryDate = (max: number) => dateUtils.getWithAddedDays(new Date(), max);

export const getDeliveryByEventLabel = (name: DeliveryByEventName) => {
  const event = getDeliveryByEventName(name);

  if (event) {
    return DELIVERY_EVENT_LABEL.replace('{PREFIX}', event.prefix).replace('{EVENT}', event.name);
  }

  return '';
};
