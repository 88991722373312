import { connect } from 'react-redux';
import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';

import { Placement } from 'src/constants/recommendations';
import { getBasketForEditing, getBasketForViewing } from 'src/redux/basket';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  actions as personalisationActions,
  updatePersonalisationOptionsFromBasketItem,
} from 'src/redux/personalisation';
import type { AppThunkDispatch } from 'src/redux/store';
import { getPartnerHref } from 'src/utils/getPartnerHref';
import { LegacyLayout } from './LegacyLayout';
import type { LegacyLayoutDispatchProps, LegacyLayoutStateProps } from './types';

const mapStateToProps = (state: ReduxApplicationState): LegacyLayoutStateProps => {
  const { accessibility, basket, product, richRelevance, userConfiguration } = state;
  const featureFlags = selectFeatureFlags(state) || {};

  return {
    activeProductsPlacementHasData:
      (richRelevance[Placement.ActiveProducts].products ?? []).length > 0,
    basketMutationError: Boolean(basket.error),
    outOfStockPlacementHasData: (richRelevance[Placement.OutOfStock].products ?? []).length > 0,
    pageLiveRegionText: accessibility.statusMessage,
    partnerHref: getPartnerHref(product.partner.shortcode, featureFlags),
    product,
    recentlyViewedPlacementHasData:
      (richRelevance[Placement.RecentlyViewed].products ?? []).length > 0,
    richRelevanceSessionId: userConfiguration.richRelevanceSessionId,
    similarProductsPlacementHasData:
      (richRelevance[Placement.SimilarProducts].products ?? []).length > 0,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): LegacyLayoutDispatchProps => {
  return {
    getBasketForEditing: () => {
      dispatch(getBasketForEditing());
    },
    getBasketForViewing: () => {
      dispatch(getBasketForViewing());
    },
    updateEditedItem: (editedItem: string) => {
      dispatch(personalisationActions.updateEditedItem(editedItem));
    },
    updatePersonalisationOptionsFromBasketItem: () => {
      dispatch(updatePersonalisationOptionsFromBasketItem());
    },
  };
};

export const LegacyLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(LegacyLayout);
