/* istanbul ignore file */

import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions } from 'src/redux/personalisation/slice';
import type {
  PersonalisationErrorFieldEvent,
  PersonalisationExpanded,
  PersonalisationInteractionFieldEvent,
  PersonalisationProgressFieldEvent,
} from './events';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [actions.setPersonalisationExpanded.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof actions.setPersonalisationExpanded>;

    if (payload.expanded) {
      sendGAEvent<PersonalisationExpanded>({
        event: 'custom_event',
        event_category: 'Product Personalisation',
        event_action: 'Impression',
        event_label: 'Expand personalisation',
      });
    }
  },
  [actions.trackInteraction.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof actions.trackInteraction>;
    const { field_action, field_name, field_position, field_type, field_value, required_field } =
      payload;

    sendGAEvent<PersonalisationInteractionFieldEvent>({
      event: 'custom_event',
      event_category: 'Product Personalisation',
      event_action: field_action,
      event_label: field_type,
      field_details: {
        field_name: field_name,
        field_position: field_position,
        field_type: field_type,
        field_value: field_value,
        required_field: required_field,
      },
    });
  },
  [actions.trackProgressMilestone.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof actions.trackProgressMilestone>;
    const { field_action, field_name, field_position, field_type, field_value, required_field } =
      payload;

    sendGAEvent<PersonalisationProgressFieldEvent>({
      event: 'custom_event',
      event_category: 'Product Personalisation',
      event_action: field_action,
      event_label: field_type,
      field_details: {
        field_name: field_name,
        field_position: field_position,
        field_type: field_type,
        field_value: field_value,
        required_field: required_field,
      },
    });
  },
  [actions.trackInteractionError.type]: (_, __, action) => {
    const { payload } = action as ReturnType<typeof actions.trackInteractionError>;
    const { field_action, field_name, field_position, field_type, required_field } = payload;

    if (payload.field_action === 'Add to Cart Error') {
      const eventLabel =
        field_type === 'input field'
          ? "You haven't entered any text"
          : "You haven't made a selection";

      sendGAEvent<PersonalisationErrorFieldEvent>({
        event: 'custom_event',
        event_category: 'Product Personalisation',
        event_action: payload.field_action,
        event_label: eventLabel,
        field_details: {
          field_name: field_name,
          field_position: field_position,
          field_type: field_type,
          field_value: null,
          required_field: required_field,
        },
      });
    } else if (field_action === 'Character Limit Error') {
      sendGAEvent<PersonalisationErrorFieldEvent>({
        event: 'custom_event',
        event_category: 'Product Personalisation',
        event_action: field_action,
        event_label: "You're over the character count limit",
        field_details: {
          field_name: field_name,
          field_position: field_position,
          field_type: field_type,
          field_value: null,
          required_field: required_field,
        },
      });
    }
  },
};
