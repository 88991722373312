export const PRODUCT_DESCRIPTION_HEADING = 'Description';
export const PRODUCT_VARIATIONS_HEADING = 'Variations';
export const PRODUCT_DIMENTIONS_HEADING = 'Dimensions';
export const MADE_FROM_HEADING = 'Made from';
export const PRODUCT_CODE_HEADING = 'Product code';
export const FREE_GIFT_WRAPPING_AVAILABLE = 'Free gift wrapping available';
export const GIFT_WRAPPING_AVAILABLE = 'Gift wrapping available';
export const MADE_IN_BRITAIN = 'Made in Britain';
export const ECO_FRIENDLY = 'Eco-friendly';
export const EXCLUSIVE = 'Exclusive';
export const PERSONALISATION_AVAILABLE = 'Personalisation available';
export const INGREDIENTS_TITLE = 'Ingredients and allergens information (PDF)';
