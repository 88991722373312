import type { TopBannerProps } from '@noths/polaris-client-banners';
import type { NavigationProps } from '@noths/polaris-client-site-chrome-ui';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';

export type UpdatedReduxState = Pick<
  ReduxApplicationState,
  | 'deliveryZoneOptions'
  | 'product'
  | 'userConfiguration'
  | 'richRelevance'
  | 'reviews'
  | 'productImagesCarousel'
  | 'personalisation'
>;

type ContentstackData = {
  navData: NavigationProps | null;
  topBannerData: TopBannerProps | null;
};

export enum Layout {
  Legacy = 'legacy_layout',
  Trust = 'trust_layout',
}

export type PageProps = ContentstackData & {
  canonicalUrl: string;
  externalReferrer: boolean;
  layout?: Layout;
  updatedReduxState: UpdatedReduxState;
};
