import React from 'react';
import { visuallyHidden } from '@noths/polaris-client-styles';

import { StarRating } from 'src/components/molecules/StarRating/components/StarRating';
import { REVIEWED_ON } from 'src/components/organisms/ProductReviews/constants/copy';
import { dateFormatHelper } from 'src/components/organisms/ProductReviews/helpers/dateFormatHelper';
import * as styles from 'src/components/organisms/ProductReviews/styles/ProductReview.styles';
import type { CatServiceAPIPartner } from 'src/services/cat-service-api/types/CatServiceAPIPartner';
import type { Review } from 'src/types/reviews';
import { getIconPath } from 'src/utils/assetPaths';
import { ExpandableTextContainer } from './ExpandableTextContainer';

export interface ProductReviewProps {
  onExpandReviewClick: (isOpen: boolean) => void;
  partner: CatServiceAPIPartner;
  review: Review;
}

export const REVIEW_ITEM_ID_PREFIX = 'customer-review';

const ACCOUNT_CIRCLE = getIconPath('icons-16-account-circle.svg');

export const ProductReview = ({ onExpandReviewClick, partner, review }: ProductReviewProps) => (
  <>
    <div css={styles.ratingSection}>
      <StarRating productRating={review.rating} />
      <span css={styles.dateText}>
        {REVIEWED_ON} {dateFormatHelper(review.date)}
      </span>
    </div>
    <ExpandableTextContainer
      onToggle={onExpandReviewClick}
      textContainerId={`${REVIEW_ITEM_ID_PREFIX}-${review.id}`}
    >
      <div css={styles.commentText} id={`${REVIEW_ITEM_ID_PREFIX}${review.id}`}>
        {review.comment}
      </div>
    </ExpandableTextContainer>
    {review.rating <= 2 && review.response && (
      <div css={styles.partnerResponseWrapper} id={`${REVIEW_ITEM_ID_PREFIX}${review.id}`}>
        <div css={styles.partnerNameWrapper}>
          <img alt="" css={styles.partnerIcon} height={16} src={ACCOUNT_CIRCLE} width={16} />
          <span css={visuallyHidden}>Response from </span>
          {partner.name}
        </div>
        <ExpandableTextContainer
          darkBackground
          textContainerId={`${REVIEW_ITEM_ID_PREFIX}-partner-response-${review.id}`}
        >
          <p css={styles.partnerResponseText}>{review.response}</p>
        </ExpandableTextContainer>
      </div>
    )}
  </>
);
