import type { Country, DeliveryZoneCode } from '@noths/polaris-client-localisation';
import {
  countries,
  deliveryZoneCodes,
  deliveryZoneCodeValues,
  ukDeliveryZones,
} from '@noths/polaris-client-localisation';
import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { selectProductDeliveryOptions } from 'src/redux/selectors';

export const selectZonesDeliveredTo = createSelector(
  [selectProductDeliveryOptions],
  (productDeliveryOptions): DeliveryZoneCode[] =>
    productDeliveryOptions
      ? R.uniq(productDeliveryOptions.map(({ zone }) => deliveryZoneCodes[zone.id]))
      : [],
);

export const selectHasUKDeliveryOnly = createSelector(
  [selectZonesDeliveredTo],
  (zonesDeliveredTo) => !zonesDeliveredTo.some((zoneCode) => !ukDeliveryZones.includes(zoneCode)),
);

export const selectHasSelectedCountriesDeliveryOnly = createSelector(
  [selectHasUKDeliveryOnly, selectZonesDeliveredTo],
  (hasUKDeliveryOnly, zonesDeliveredTo): boolean =>
    !(
      hasUKDeliveryOnly ||
      deliveryZoneCodeValues.every((zoneCode) =>
        zonesDeliveredTo.includes(zoneCode as DeliveryZoneCode),
      )
    ),
);

export const selectCountriesDeliveredTo = createSelector(
  [selectZonesDeliveredTo],
  (zonesDeliveredTo): Country[] =>
    countries.filter(({ deliveryZoneCode }) =>
      zonesDeliveredTo.includes(deliveryZoneCode as DeliveryZoneCode),
    ),
);
