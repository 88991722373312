import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

interface TrackCTAClickArgs {
  category: string;
  contentType: string;
  label: string;
}

export const trackCTAClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  { category, contentType, label }: TrackCTAClickArgs,
) => {
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: 'CTA click',
    event_category: category,
    event_label: label,
    event_name: 'select_content',
    event_details: 'cta click',
    content_type: contentType,
    membership_banner_location: 'product',
  });
};
