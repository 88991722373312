import React from 'react';
import { SkeletonLabel } from '@noths/polaris-client-ribbons-design-system';

import * as styles from 'src/components/molecules/DeliveryBlock/styles';

export const DeliveryOptionSkeleton = () => {
  return (
    <div css={[styles.deliveryOptionSkeletonWrapper, styles.deliveryOption]}>
      <SkeletonLabel labelHeight="XL" />
      <SkeletonLabel labelHeight="XL" />
      <SkeletonLabel labelHeight="XL" />
    </div>
  );
};
