import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { responsiveText, spacing } = theme;

export const root = css`
  display: flex;
  border-radius: ${spacing.xs}px;
  padding: ${spacing.base}px;
  ${responsiveText.BODY.XS};
  color: var(--brand-bluple-base);
  background-color: var(--brand-purple-5);

  span {
    margin-right: ${spacing.base}px;
  }
`;
