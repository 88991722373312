/* istanbul ignore file */

import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions } from 'src/redux/productInfo/slice';
import { formatIntegerPriceToDecimal } from 'src/utils/price';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [actions.openBasketModal.type]: (state) => {
    const {
      basket: { trackingData },
    } = state;

    if (trackingData) {
      sendGAEvent({
        ecommerce: null,
      });

      sendGAEvent({
        event: 'cart_update',
        eventCategory: 'Product page',
        eventAction: 'Basket state',
        eventLabel: 'Basket contents',
        ecommerce: {
          checkout: {
            basket_id: trackingData.analyticsId,
            total_items_GBP: formatIntegerPriceToDecimal(trackingData.discountedItemsTotal.cents),
            products: trackingData.items.map(({ product, purchasePrice, quantity }) => ({
              product_code: String(product.code),
              quantity: String(quantity),
              price_GBP: formatIntegerPriceToDecimal(purchasePrice.cents / quantity),
            })),
          },
        },
      });
    }
  },
};
