import React from 'react';
import { OutlineButtonLink } from '@noths/polaris-client-ribbons-design-system';

import * as copy from 'src/components/organisms/PartnerStorefront/constants/copy';

export interface VisitShopButtonProps {
  href: string;
  onLinkClick: (ev: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const VisitShopButton = ({ href, onLinkClick }: VisitShopButtonProps) => {
  return (
    <OutlineButtonLink href={href} onClick={onLinkClick}>
      {copy.VISIT_SHOP}
    </OutlineButtonLink>
  );
};
